import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import GenericSearchPage from '../generic-search/generic-search.page'
import { UserGenderEnum } from '../users/entities/user.enums'
import { PaginationInput } from '../../common/pagination/pagination'
import { TutorApplicationStatusEnum } from './entities/tutor_prospects.enum'
import {
  findInitProspectSortState,
  useFindProspectTutorCount,
  useFindTutorProspects,
} from './hook/use-find-tutor-prospects.hook'
import ProspectTutorItem from './components/prospect-tutor-item'
import { useSearchPageState } from '../../context/search_page_context'
import SortTutorProspectCard from './components/sort-tutor-prospect-card'
import { SortTutorProspectInput } from './dto/sort-prospect.input'

const SearchProspectsPage = () => {
  const pageState = useSearchPageState()

  const { findProspectTutors, loading, data, error } = useFindTutorProspects()

  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    skip: 0,
    limit: 10,
  })
  const [currentSortState, setCurrentSortState] = useState<SortTutorProspectInput>(
    findInitProspectSortState,
  )
  const { findCount, data: countData } = useFindProspectTutorCount()

  const [searchNow, setSearchNow] = useState(false)

  const handleLoad = (sort?: SortTutorProspectInput) => {
    if (searchNow) {
      let search = { ...pageState?.searchPageState.prospectTutorsSearchState }
      if (search?.status && search?.status?.toString() === 'ALL') {
        search.status = undefined
      }
      findProspectTutors({
        currentSearchState: search,
        currentSortState: sort ? sort : currentSortState,
        paginationInput,
      }).then()
    } else {
      setSearchNow(true)
    }
  }

  useEffect(() => {
    if (searchNow) {
      findCount().then().catch()
    }
  }, [searchNow])

  const handleSortProspect = (sortState: SortTutorProspectInput) => {
    setCurrentSortState(sortState)
    handleLoad(sortState)
  }

  return (
    <Box pb={2}>
      <GenericSearchPage
        data={data?.tutorProspects}
        loading={loading}
        error={error}
        countData={countData?.tutorProspects}
        find={handleLoad}
        showSearch={true}
        pageName="prospect_tutors"
        statusArray={TutorApplicationStatusEnum}
        searchEntityName="prospectTutorsSearchState"
        currentState={pageState?.searchPageState.prospectTutorsSearchState}
        paginationInput={paginationInput}
        setPaginationInput={setPaginationInput}
        searchFields={[
          { name: 'fullName', inputType: 'TextInputField' },
          { name: 'phoneNumber', inputType: 'TextInputField', type: 'tel' },
          {
            name: 'gender',
            inputType: 'SelectInputField',
            menuItems: Object.entries(UserGenderEnum),
          },
          {
            name: 'status',
            inputType: 'SelectInputField',
            menuItems: Object.entries(TutorApplicationStatusEnum),
          },
        ]}
      >
        <div id="ignore_fields">
          {/*You can add more custom fields like date manipulation objects*/}
        </div>

        <div id="list_grid">
          <SortTutorProspectCard
            sortProspect={handleSortProspect}
            initialState={findInitProspectSortState}
          />
          <Grid container spacing={2} id="list_grid">
            {data &&
              data.tutorProspects.items.map(tutorProspects => (
                <Grid key={tutorProspects.id} item xs={12} md={6} lg={4}>
                  <ProspectTutorItem
                    tutorProspect={tutorProspects}
                    refetch={() => handleLoad()}
                  />
                  <Box my={1} />
                </Grid>
              ))}
          </Grid>
        </div>
      </GenericSearchPage>
    </Box>
  )
}

export default SearchProspectsPage
