import { gql, useLazyQuery } from '@apollo/client'
import { UserStatusEnum } from '../../app/users/entities/user.enums'

export function useUsersData() {
  const [usersDataGql, { loading, data, error }] = useLazyQuery<
    { advancedUsersAnalytics: JSON },
    { groupBy: string; statuses: UserStatusEnum[]; startDate: Date; endDate: Date }
  >(USERS_DATA)

  function usersData(
    groupBy: string,
    statuses: UserStatusEnum[],
    startDate: Date,
    endDate: Date,
  ) {
    return usersDataGql({
      variables: {
        groupBy,
        statuses,
        startDate,
        endDate,
      },
    })
  }

  return { usersData, loading, data, error }
}

const USERS_DATA = gql`
  query Query(
    $groupBy: String!
    $statuses: [UserStatusEnum!]!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    advancedUsersAnalytics(
      groupBy: $groupBy
      statuses: $statuses
      startDate: $startDate
      endDate: $endDate
    )
  }
`
