import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Navigation from './navigation/navigation'
import CreateContractPage from './create_contract/create-contract.page'
import SearchUsersPage from './users/search-users.page'
import Login from './auth/login'
import ProtectedRoute from './routes/protected-route'
import Dashboard from '../analytics/dashboard'
import UnProtectedRoute from './routes/un-protected-route'
import Logout from './auth/logout'
import ContractDetailPage from './contract_detail/contract-detail-page'
import CustomerDetailPage from './customers/customer-detail.page'
import SearchContractsPage from './search_contracts/search-contracts-page'
import TutorDetailPage from './tutor_detail/tutor-detail-page'
import SearchPaymentsPage from './search_payments/search-payments-page'
import UpcomingPaymentsPage from './upcomming_payments/upcoming-payments-page'
import PaymentRanges from './settings/payment-ranges-page'
import ErrorPage from './components/error_page/error-page'
import StaticData from './settings/static-data'
import ContractDetailAnalytics from '../analytics/contracts-detail-analytics'
import SearchProspectsPage from './prospect_tutors/search-prospects-page'
import SearchTutorsPage from './search_tutors/search-tutors-page'
import SmsPage from './sms/send-sms-page'

function App() {
  return (
    <Routes>
      <Route path="/login" element={<UnProtectedRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<ProtectedRoute />}>
        <Route
          path="*"
          element={
            <Navigation>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/contracts" element={<ContractDetailAnalytics />} />
                <Route path="/contracts" element={<SearchContractsPage />} />
                <Route path="/contract/create" element={<CreateContractPage />} />
                <Route path="/contract/:id" element={<ContractDetailPage />} />
                <Route path="/customer/:id" element={<CustomerDetailPage />} />
                <Route path="/users" element={<SearchUsersPage />} />
                <Route path="/payments" element={<SearchPaymentsPage />} />
                <Route path="/payments/upcoming" element={<UpcomingPaymentsPage />} />
                <Route path="/prospect_tutors" element={<SearchProspectsPage />} />
                <Route path="/tutors" element={<SearchTutorsPage />} />
                <Route path="/sendsms" element={<SmsPage />} />
                <Route path="/tutor/:id" element={<TutorDetailPage />} />
                <Route path="/settings/payment-ranges" element={<PaymentRanges />} />
                <Route path="/settings/static-data" element={<StaticData />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Navigation>
          }
        />
      </Route>
    </Routes>
  )
}

export default App
