import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'

const DegreeTypesSection = () => {
  const DEGREE_TYPES = gql`
    query {
      degreeTypes {
        id
        name
      }
    }
  `

  const CREATE_DEGREE_TYPES = gql`
    mutation ($degreeTypes: [String!]!) {
      createDegreeTypes(degreeTypes: $degreeTypes)
    }
  `

  const DELETE_MAJORS = gql`
    mutation ($degreeTypeId: String!) {
      deleteDegreeType(degreeTypeId: $degreeTypeId) {
        id
      }
    }
  `
  const [get, { loading, data, error }] = useLazyQuery(DEGREE_TYPES)
  const [create, { loading: creating, data: creatingData, error: creatingError }] =
    useMutation(CREATE_DEGREE_TYPES)
  const [deleteData, { loading: deleting, data: deletingData, error: deletingError }] =
    useMutation(DELETE_MAJORS)

  useEffect(() => {
    get().then()
  }, [creatingData, deletingData])

  const [expanded, setExpanded] = useState<string | false>(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [name, setName] = useState('')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box mb={3}>
      {(loading || creating || deleting) && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {creatingError && <Alert severity="error">{creatingError.message}</Alert>}
      {deletingError && <Alert severity="error">{deletingError.message}</Alert>}

      <Dialog open={openAdd}>
        <Box p={2}>
          <form
            onSubmit={e => {
              e.preventDefault()
              setOpenAdd(false)
              create({
                variables: {
                  degreeTypes: name
                    .split(',')
                    .map(m => m.trim().replace(/"/gi, '').replace(/'/gi, '')),
                },
              }).then()
            }}
          >
            <Box mb={2}>
              <TextField
                id="name"
                name="name"
                value={name}
                required
                onChange={e => setName(e.target.value)}
              />
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button color="secondary" type="button" onClick={() => setOpenAdd(false)}>
                Cancel
              </Button>
              <Button type="submit">Add</Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5">Degree Types</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2} display="flex" justifyContent="right">
            <Button onClick={() => setOpenAdd(true)}>Add Majors</Button>
          </Box>
          {data && (
            <Box>
              <Grid container spacing={2}>
                {data.degreeTypes.map((s: any) => (
                  <Grid key={s.id} item xs={6} md={4} lg={3}>
                    <Card>
                      <CardContent>
                        <Box display="flex" justifyContent="space-between">
                          <Typography>{s.name}</Typography>
                          <Box display="flex">
                            <IconButton
                              onClick={() => {
                                deleteData({
                                  variables: {
                                    degreeTypesId: s.id,
                                  },
                                }).then()
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default DegreeTypesSection
