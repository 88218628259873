import { gql, useMutation } from '@apollo/client'
import { User } from '../entities/user.entity'
import { CreateUserInput } from '../dto/user-create-inputs'

const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
    }
  }
`

export function useCreateUser() {
  const [createUserGql, { loading, data, error }] = useMutation<
    { createUser: User },
    { createUserInput: CreateUserInput }
  >(CREATE_USER)

  function createUser(createUserInput: CreateUserInput) {
    return createUserGql({
      variables: { createUserInput },
    })
  }

  return { createUser, loading, data, error }
}
