import * as React from 'react'
import { useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
// @ts-ignore
import throttle from 'lodash/throttle'
import { UserLocationInput } from '../../../common/location/location.entity'

const GOOGLE_MAPS_API_KEY = 'AIzaSyCJ7MWlUjQijThp4gPeELezKLwpo2laHvw'

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}

interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[]
}

export interface PlaceType {
  description: string
  place_id: string
  structured_formatting?: StructuredFormatting
}

interface GooglePlacesProps {
  required?: boolean
  userLocation: UserLocationInput | null | undefined

  setUserLocation(val: UserLocationInput | null | ((val: UserLocationInput) => void)): void
}

const GooglePlaces: React.FC<GooglePlacesProps> = ({
  userLocation,
  setUserLocation,
  required,
}) => {
  const [inputValue, setInputValue] = React.useState(
    userLocation?.description ? userLocation?.description : '',
  )
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const loaded = React.useRef(false)
  const placesServiceRef = React.useRef(null)

  const [value, setValue] = React.useState<PlaceType | null>({
    description: userLocation?.description ? userLocation?.description : '',
    place_id: userLocation?.placeId ? userLocation?.placeId : '',
  })

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&region=ET&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      )
    }
    loaded.current = true
  }

  const fetchPlaceDetails = (placeId: string) => {
    const mapDiv = document.createElement('div')
    mapDiv.id = 'map'
    mapDiv.className = 'hidden'
    document.body.appendChild(mapDiv)
    const map = new (window as any).google.maps.Map(document.getElementById('map'), {
      center: { lat: -33.8666, lng: 151.1958 },
      zoom: 15,
    })
    placesServiceRef.current = new (window as any).google.maps.places.PlacesService(map)

    if (!placesServiceRef.current) {
      console.error('Places Service not initialized')
      return
    }

    const request = {
      placeId: placeId,
      fields: ['name', 'formatted_address', 'place_id', 'geometry'], // Add other fields as needed
    }

    ;(placesServiceRef.current as any).getDetails(request, (place: any, status: any) => {
      if (status === (window as any).google.maps.places.PlacesServiceStatus.OK && place) {
        setUserLocation(prev => ({
          ...prev,
          country: prev?.country ? prev?.country?.toLowerCase() : 'ethiopia',
          city: (
            place.formatted_address.match(/[A-Za-z\s]+/g)?.join('') ||
            (prev?.city ? prev?.city?.toLowerCase() : 'addis ababa')
          ).toLowerCase(),
          description: place.name,
          streetName: place.name,
          coordinates: [place.geometry.location.lat(), place.geometry.location.lng()],
          placeId: placeId,
          __typename: undefined,
        }))
      } else {
        console.error('Error fetching place details:', status)
      }
    })
  }

  const fetch = useMemo(
    () =>
      throttle(
        (request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
          ;(autocompleteService.current as any).getPlacePredictions(request, callback)
        },
        1000,
      ),
    [],
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      fullWidth
      value={value}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        if (newValue?.place_id) {
          fetchPlaceDetails(newValue.place_id) // Fetch and log place details
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue === '') {
          setUserLocation({
            city: '',
            coordinates: [],
            country: '',
            description: '',
            district: '',
            id: '',
            placeId: '',
            streetName: '',
            subCity: '',
            zipCode: '',
          })
        } else {
          setInputValue(newInputValue)
        }
      }}
      renderInput={params => (
        <TextField
          required={required == null}
          {...params}
          label="Add a location"
          fullWidth
          variant="standard"
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting?.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting?.main_text
            ? option.structured_formatting?.main_text
            : '',
          matches
            ? matches?.map((match: any) => [match.offset, match.offset + match.length])
            : [],
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map(part => (
                  <span
                    key={part.text}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default GooglePlaces
