import { gql, useLazyQuery } from '@apollo/client'
import { PaymentStatusEnum } from '../../app/payments/entities/payment.enums'

export function usePaymentsData() {
  const [paymentsDataGql, { loading, data, error }] = useLazyQuery<
    { advancedPaymentsAnalytics: JSON },
    { groupBy: string; statuses: PaymentStatusEnum[]; startDate: Date; endDate: Date }
  >(PAYMENTS_DATA)

  function paymentsData(
    groupBy: string,
    statuses: PaymentStatusEnum[],
    startDate: Date,
    endDate: Date,
  ) {
    return paymentsDataGql({
      variables: {
        groupBy,
        statuses,
        startDate,
        endDate,
      },
    })
  }

  return { paymentsData, loading, data, error }
}

const PAYMENTS_DATA = gql`
  query Query(
    $groupBy: String!
    $statuses: [PaymentStatusEnum!]!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    advancedPaymentsAnalytics(
      groupBy: $groupBy
      statuses: $statuses
      startDate: $startDate
      endDate: $endDate
    )
  }
`
