import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import theme from './theme/theme-provider'
import { client } from './apollo/client'
import App from './app/app'

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import { AuthProvider } from './context/auth_context'
import { SearchPageProvider } from './context/search_page_context'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

TimeAgo.addDefaultLocale(en)

root.render(
  <AuthProvider>
    <SearchPageProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </SearchPageProvider>
  </AuthProvider>,
)
