import StatusToggleButton from '../../components/inputs/status-toggle-button'
import React, { useState } from 'react'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Alert, Box, Button, Typography } from '@mui/material'
import { useUpdatePaymentStatus } from '../hook/use-update-payment-status.hook'
import { PaymentStatusEnum, PaymentStatusUpdateEnum } from '../entities/payment.enums'
import { Payment } from '../entities/payment.entity'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import Dialog from '@mui/material/Dialog'
import TextInputField from '../../components/inputs/text-input-field'
import moment from 'moment/moment'

const Reasons = [
  'customer not paid',
  'contract cancelled',
  'contract finished',
  'tutor not paid',
  'below the accepted commission amount',
  'test',
]

interface UpdatePaymentStatusProps {
  payment: Payment

  refetch(): void
}

const UpdatePaymentStatus: React.FC<UpdatePaymentStatusProps> = ({ payment, refetch }) => {
  const { updatePaymentStatus, loading, error } = useUpdatePaymentStatus()

  const [open, setOpen] = useState(false)
  const [verifiedDate, setVerifiedDate] = useState(undefined)
  const [selectedStatus, setSelectedStatus] = useState<PaymentStatusEnum>(payment.status)
  const [cancellationReason, setCancellationReason] = useState('')

  return (
    <Box>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}

      <Dialog open={open}>
        <Box px={3} py={2}>
          <Typography>
            Are you sure you want to change the status to "{selectedStatus.toString()}"?
          </Typography>
          {error && <Alert severity="error">{error.message}</Alert>}
          <form
            onSubmit={e => {
              e.preventDefault()
              updatePaymentStatus({
                paymentId: payment.id,
                status: selectedStatus,
                cancellationReasons:
                  cancellationReason !== ''
                    ? [
                        {
                          description: cancellationReason,
                        },
                      ]
                    : [],
                verifiedDate,
              })
                .then(() => {
                  refetch()
                  setOpen(false)
                })
                .catch(e => e)
            }}
          >
            <Box px={3} py={2}>
              {selectedStatus === PaymentStatusEnum.CANCELED && (
                <SelectInputField
                  id="cancellationReasons"
                  value={cancellationReason}
                  label="Cancellation Reasons"
                  name="cancellationReasons"
                  required={true}
                  onChange={e => setCancellationReason(e.target.value)}
                  menuItems={
                    Reasons.map(value => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              )}
              {selectedStatus === PaymentStatusEnum.VERIFIED && (
                <TextInputField
                  name="verifiedDate"
                  id="verified-date"
                  value={moment(verifiedDate).format('yyyy-MM-DD')}
                  label="Verified Date"
                  type="date"
                  onChange={e => setVerifiedDate(e.target.value)}
                  required={false}
                />
              )}
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                type="button"
                onClick={() => {
                  setOpen(false)
                  setSelectedStatus(payment.status)
                  setVerifiedDate(undefined)
                }}
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <StatusToggleButton
        options={Object.entries(PaymentStatusUpdateEnum).map(([_, value]) => value)}
        clicked={s => {
          setOpen(true)
          setSelectedStatus(s as PaymentStatusEnum)
        }}
        selected={selectedStatus}
      />
    </Box>
  )
}

export default UpdatePaymentStatus
