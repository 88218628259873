import { gql, useMutation } from '@apollo/client'
import { UpdateContractStatusInputAdmin } from '../entities/contract.entity'

const CONTRACT_STATUS_UPDATE = gql`
  mutation UpdateContractStatusAdmin(
    $updateContractStatusInputAdmin: UpdateContractStatusInputAdmin!
  ) {
    updateContractStatusAdmin(
      updateContractStatusInputAdmin: $updateContractStatusInputAdmin
    ) {
      id
    }
  }
`

export function useUpdateContractStatus() {
  const [updateContractStatusGql, { loading, data, error }] = useMutation<
    { updateContractStatusAdmin: { id: string } },
    { updateContractStatusInputAdmin: UpdateContractStatusInputAdmin }
  >(CONTRACT_STATUS_UPDATE)

  function updateContractStatus(
    updateContractStatusInputAdmin: UpdateContractStatusInputAdmin,
  ) {
    return updateContractStatusGql({
      variables: { updateContractStatusInputAdmin },
    })
  }

  return { updateContractStatus, loading, data, error }
}
