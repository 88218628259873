import { gql, useMutation } from '@apollo/client'
import { TutorRating } from '../entities/tutor-rating.entity'

const DELETE_REVIEWS = gql`
  mutation DeleteTutorRating($tutorRatingId: String!) {
    deleteTutorRating(tutorRatingId: $tutorRatingId) {
      id
    }
  }
`

export function useDeleteReviews() {
  const [deleteReviewsGql, { loading, data, error }] = useMutation<
    { deleteTutorRating: TutorRating },
    { tutorRatingId: string }
  >(DELETE_REVIEWS)

  function deleteReviews(tutorRatingId: string) {
    return deleteReviewsGql({
      variables: { tutorRatingId },
    })
  }

  return { deleteReviews, loading, data, error }
}
