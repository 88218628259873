import React from 'react'
import SubjectsSection from './components/subjects'
import UniversitiesSection from './components/universities'
import MajorsSection from './components/major'
import TestLinkSection from './components/test-link'
import DegreeTypesSection from './components/degree-types'

const StaticData = () => {
  return (
    <div>
      <SubjectsSection />
      <UniversitiesSection />
      <MajorsSection />
      <DegreeTypesSection />
      <TestLinkSection />
    </div>
  )
}

export default StaticData
