import React, { useState } from 'react'
import { Alert, Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material'
import { DailyLog } from '../entities/daily-log.entity'
import { Payment } from '../entities/payment.entity'
import EditIcon from '@mui/icons-material/Edit'
import UpdateDailyLogStatus from './update-daily-log-status'
import { DailyLogStatusEnum, PaymentStatusEnum } from '../entities/payment.enums'
import UpdateDailyLogDialog from './update-daily-log.dialog'
import { Child } from '../../contracts/entities/child.entity'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import { useUpdateDailyLog } from '../hook/use-update-daily-log.hook'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { changeDateToTime } from '../../../functions/date-functions'

interface DailyLogCardProps {
  dailyLog: DailyLog
  payment: Payment
  children: Child[]

  refetch(): void
}

const DailyLogCard: React.FC<DailyLogCardProps> = ({
  dailyLog,
  payment,
  children,
  refetch,
}) => {
  const [openUpdateDailyLog, setOpenUpdateDailyLog] = useState(false)
  const [openDeleteDailyLog, setOpenDeleteDailyLog] = useState(false)

  const { updateDailyLog, loading, error } = useUpdateDailyLog()

  const handleDelete = () => {
    setOpenDeleteDailyLog(false)
    updateDailyLog({
      paymentId: payment.id,
      dailyLogs: [
        {
          id: dailyLog.id,
          delete: true,
          childId: dailyLog.childId,
        },
      ],
    }).then(() => refetch())
  }

  return (
    <Box my={1}>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message} </Alert>}

      <UpdateDailyLogDialog
        payment={payment}
        dailyLog={dailyLog}
        openUpdateDailyLog={openUpdateDailyLog}
        setOpenUpdateDailyLog={setOpenUpdateDailyLog}
        refetch={refetch}
      />

      <Dialog open={openDeleteDailyLog}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Typography>Are you sure you want to delete this log?</Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenDeleteDailyLog(false)}
            >
              No
            </Button>
            <Button variant="outlined" color="error" onClick={() => handleDelete()}>
              Yes
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Paper
        style={{
          border: 0,
          borderLeft: 8,
          borderColor: 'goldenrod',
          borderStyle: 'solid',
        }}
      >
        <Box p={1}>
          <Grid container>
            <Grid item xs={6}>
              {new Date(dailyLog.date).toISOString().split('T')[0]} {`${dailyLog.duration}hrs`}
              <br />
              {`${changeDateToTime(dailyLog.startTime, 'invalid')} - ${changeDateToTime(
                dailyLog.endTime,
                'invalid',
              )}`}
            </Grid>
            <Grid item xs={6}>
              {payment.status !== PaymentStatusEnum.VERIFIED && (
                <Box display="flex" justifyContent="flex-end">
                  <UpdateDailyLogStatus
                    payment={payment}
                    dailyLog={dailyLog}
                    refetch={refetch}
                  />
                  <Box mx={1} />
                  {dailyLog.status !== DailyLogStatusEnum.APPROVED && (
                    <Box display="flex">
                      <Button
                        size="small"
                        variant="outlined"
                        color="warning"
                        endIcon={<EditIcon fontSize="small" />}
                        onClick={() => setOpenUpdateDailyLog(true)}
                      >
                        Edit
                      </Button>
                      <Box mx={1} />
                      <IconButton
                        onClick={() => {
                          setOpenDeleteDailyLog(true)
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {dailyLog.description}
          </Typography>
          <Box my={2} />
          {children.map(c =>
            c.id === dailyLog.childId ? (
              <Typography key={c.id}>{c.studentName}</Typography>
            ) : (
              <Typography key={c.id} />
            ),
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default DailyLogCard
