import { UserGenderEnum } from '../../users/entities/user.enums'
import { TutorApplicationStatusEnum } from './tutor_prospects.enum'
import { locationStringFull, UserLocation } from '../../../common/location/location.entity'
import { User, userStringFull } from '../../users/entities/user.entity'
import { Note, noteStringFull } from '../../notes/entity/notes.entity'

export interface ProspectTutor {
  applicationStatus: TutorApplicationStatusEnum
  id: string
  fullName: string
  email: string
  phoneNumber: string
  gender: UserGenderEnum
  location?: UserLocation
  cgpa: number
  eueeResult: number
  university: string
  stream: string
  major: string
  documentLinks?: {
    euee?: string
    video?: string
    transcript?: string
    identification?: string
  }
  graduationDate: Date
  dateOfBirth: Date
  referralSource: string
  numberOfTestsTaken: number
  congratulatoryEmailSent: boolean
  userId: string
  user?: User
  latestTestTakenId?: string
  token: string
  tokenExpiresAt: Date
  secondRoundExpiresAt?: Date
  resubmitDocuments?: Boolean
  notes: Note[]
  statusUpdatedAt: string
  createdAt?: string
  updatedAt: string
  videoUploaded: Boolean
}

export const prospectTutorStringFull = `
  id
  cgpa
  congratulatoryEmailSent
  createdAt
  dateOfBirth
  email
  eueeResult
  fullName
  gender
  graduationDate
  latestTestTaken {
    answers
    createdAt
    score
    status
    testFinished
    updatedAt
  }
  location {
    ${locationStringFull}
  }
  documentLinks {
    euee
    video
    transcript
    identification
  }
  major
  numberOfTestsTaken
  phoneNumber
  referralSource
  applicationStatus
  stream
  university
  updatedAt
  userId
  statusUpdatedAt
  secondRoundExpiresAt
  resubmitDocuments
  user {
    ${userStringFull}
  }
  notes {
    ${noteStringFull}
  }
`
