import React, { useEffect, useState } from 'react'
import { Box, Grid, Slider } from '@mui/material'
import GenericSearchPage from '../generic-search/generic-search.page'
import { PaginationInput } from '../../common/pagination/pagination'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import {
  findInitPaymentSortState,
  useFindPayments,
  useFindPaymentsCount,
} from './hooks/use-find-payments.hook'
import { FindPaymentsDateInputs, FindPaymentsNumberInputs } from './dto/find-payments.input'
import { PaymentStatusEnum } from '../payments/entities/payment.enums'
import PaymentCardList from './components/payment-card-list'
import { useSearchPageState } from '../../context/search_page_context'
import { SortPaymentInput } from './dto/sort-payment.input'
import SortPaymentCard from './components/sort-payment-card'

const SearchPaymentsPage = ({
  customerId,
  tutorId,
}: {
  customerId?: string
  tutorId?: string
}) => {
  const { findPayments, loading, data, error } = useFindPayments()

  const pageState = useSearchPageState()

  const [dateStates, setDateStates] = useState<FindPaymentsDateInputs>({
    createdAt: {
      gte: dayjs('2023-01-01').toDate(),
      lte: new Date(),
    },
  })

  const [numberStates, setNumberStates] = useState<FindPaymentsNumberInputs>({
    totalHours: {
      gte: 0,
      lte: 100,
    },
  })

  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    skip: 0,
    limit: 10,
  })

  const [currentSortState, setCurrentSortState] = useState<SortPaymentInput>(
    findInitPaymentSortState,
  )

  const { findCount, data: contractDataCount } = useFindPaymentsCount()

  const [searchNow, setSearchNow] = useState(false)

  const handleLoad = (sort?: SortPaymentInput) => {
    if (searchNow) {
      let search = { ...pageState?.searchPageState.paymentsSearchState }
      if (customerId) {
        if (!search) search = {}
        search.customerId = customerId
      }
      if (search?.status && search?.status?.toString() === 'ALL') {
        search.status = undefined
      }
      findPayments({
        currentSearchState: search,
        currentSortState: sort ? sort : currentSortState,
        paginationInput,
        dateStates,
        numberStates,
      }).then()
    } else {
      setSearchNow(true)
    }
  }

  useEffect(() => {
    findCount()
      .then()
      .catch(e => e)
  }, [])
  const handleSortPayment = (sortState: SortPaymentInput) => {
    setCurrentSortState(sortState)
    handleLoad(sortState)
  }

  return (
    <Box pb={2}>
      <GenericSearchPage
        data={data?.payments}
        loading={loading}
        error={error}
        countData={contractDataCount?.payments}
        find={handleLoad}
        showSearch={
          (customerId === null || customerId === undefined) &&
          (tutorId === null || tutorId === undefined)
        }
        pageName="payments"
        statusArray={PaymentStatusEnum}
        searchEntityName="paymentsSearchState"
        currentState={pageState?.searchPageState.paymentsSearchState}
        paginationInput={paginationInput}
        setPaginationInput={setPaginationInput}
        searchFields={[
          {
            name: 'status',
            inputType: 'SelectInputField',
            menuItems: Object.entries(PaymentStatusEnum),
          },
        ]}
      >
        <div id="ignore_fields">
          {/*You can add more custom fields like date manipulation fields*/}

          <Box id="number_fields">
            <label>Total Hour Range</label>
            <Slider
              getAriaLabel={() => 'Total Hour Range'}
              value={[
                numberStates?.totalHours?.gte ? numberStates?.totalHours?.gte : 0,
                numberStates?.totalHours?.lte ? numberStates?.totalHours?.lte : 100,
              ]}
              onChange={(_: Event, newValue: number | number[]) => {
                setNumberStates(prev => ({
                  ...prev,
                  totalHours: Array.isArray(newValue)
                    ? {
                        gte: newValue ? (newValue.length > 1 ? newValue[0] : 0) : 0,
                        lte: newValue ? (newValue.length > 1 ? newValue[1] : 100) : 100,
                      }
                    : { gte: 0, lte: 100 },
                }))
              }}
              valueLabelDisplay="auto"
              name="totalHours"
              max={100}
              min={0}
            />
          </Box>

          <Box>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: 'Created date From', end: 'To' }}
            >
              <DateRangePicker
                label="Date"
                value={[
                  dateStates?.createdAt?.gte
                    ? dayjs(dateStates?.createdAt?.gte)
                    : dayjs('2023-01-01'),
                  dateStates?.createdAt?.lte
                    ? dayjs(dateStates?.createdAt?.lte)
                    : dayjs(new Date()),
                ]}
                onChange={newValue => {
                  setDateStates(prev => ({
                    ...prev,
                    createdAt: Array.isArray(newValue)
                      ? {
                          gte: newValue
                            ? newValue.length > 1
                              ? (newValue[0] as dayjs.Dayjs).toDate()
                              : dayjs('2023-01-01').toDate()
                            : dayjs('2023-01-01').toDate(),
                          lte: newValue
                            ? newValue.length > 1
                              ? (newValue[1] as dayjs.Dayjs).toDate()
                              : new Date()
                            : new Date(),
                        }
                      : { gte: dayjs('2023-01-01').toDate(), lte: new Date() },
                  }))
                }}
              />
            </LocalizationProvider>
          </Box>
        </div>
        <div id="list_grid">
          <SortPaymentCard
            sortPayment={handleSortPayment}
            initialState={findInitPaymentSortState}
          />

          <Box my={3} />
          <Grid container spacing={2} id="list_grid">
            {data &&
              data.payments.items.map(data => (
                <Grid key={data.id} item xs={12} md={6} lg={4}>
                  <PaymentCardList payment={data} refetch={handleLoad} />
                  <Box my={1} />
                </Grid>
              ))}
          </Grid>
        </div>
      </GenericSearchPage>
    </Box>
  )
}

export default SearchPaymentsPage
