import React from 'react'
import { Alert, Box, Button } from '@mui/material'
import TextInputField from '../../components/inputs/text-input-field'
import { Formik } from 'formik'
import { Contract } from '../../contracts/entities/contract.entity'
import { Child } from '../../contracts/entities/child.entity'
import { useAddDailyLog } from '../hook/use-add-daily-log.hook'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { changeTimeToDate } from '../../../functions/date-functions'

interface AddDailyLogDialogProps {
  contract: Contract
  child: Child
  openAddDailyLog: boolean

  setOpenAddDailyLog(val: boolean): void

  refetch(): void
}

const AddDailyLogDialog: React.FC<AddDailyLogDialogProps> = ({
  contract,
  child,
  openAddDailyLog,
  setOpenAddDailyLog,
  refetch,
}) => {
  const { addDailyLog, data, loading, error } = useAddDailyLog()

  return (
    <Dialog open={openAddDailyLog} fullWidth>
      {loading && <CircularIndeterminate />}

      <Formik
        initialValues={{
          startTime: '17:00:00',
          endTime: '18:00:00',
          description: '',
          date: new Date().toISOString().split('T')[0],
        }}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)

          addDailyLog({
            contractId: contract.id,
            dailyLogs: [
              {
                ...values,
                childId: child.id,
                startTime: changeTimeToDate(values.date, values.startTime, '17:00'),
                endTime: changeTimeToDate(values.date, values.endTime, '18:00'),
              },
            ],
          })
            .then(() => {
              setOpenAddDailyLog(false)
              refetch()
            })
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box display="flex">
                <TextInputField
                  id="start-time"
                  name="startTime"
                  label="Start time"
                  value={values.startTime}
                  type="time"
                  onChange={handleChange}
                />
                <TextInputField
                  id="end-time"
                  name="endTime"
                  label="End time"
                  value={values.endTime}
                  type="time"
                  onChange={handleChange}
                />
              </Box>
              <Box my={2} />
              <TextInputField
                id="date"
                name="date"
                label="Date"
                value={values.date}
                type="date"
                onChange={handleChange}
              />
              <Box my={2} />
              <TextInputField
                id="description"
                name="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
              />
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenAddDailyLog(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AddDailyLogDialog
