import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { Form, Formik } from 'formik'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FindTutorsDateInputs, FindTutorsInput } from '../dto/find-tutors.input'
import { UserGenderEnum, UserStatusEnum } from '../../users/entities/user.enums'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import SelectInputFieldCheckbox from '../../components/inputs/select-input-field-checkbox'
import TextInputField from '../../components/inputs/text-input-field'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { denormalizeValue, normalizeInitialState } from '../../../functions/filter-objects'
import { GradeLevelList } from '../../contracts/entities/contract.enums'
import { UserLocationInput } from '../../../common/location/location.entity'
import PlaceDetailSelector from '../../components/google_places/place-detail-selector'
import GradeLevelMultipleInput from '../../components/inputs/grade-level-multiple.input'

export const SubjectsArray: string[] = [
  'Math',
  'Biology',
  'Physics',
  'Chemistry',
  'Geography',
  'Economics',
  'Programming',
  'History',
  'General Science',
  'Social Studies',
  'Civic',
  'English',
  'Amharic',
  'Oromifa',
  'Tigregna',
]

interface SearchFiltersProps {
  initialState: FindTutorsInput
  dateStates: FindTutorsDateInputs
  userLocation: UserLocationInput | null | undefined
  setDateStates: React.Dispatch<React.SetStateAction<FindTutorsDateInputs>>

  setUserLocation(
    val: UserLocationInput | null | undefined | ((val: UserLocationInput) => void),
  ): void

  searchTutors(searchState: FindTutorsInput): void
}

const SearchTutorsCard: React.FC<SearchFiltersProps> = ({
  searchTutors,
  initialState,
  userLocation,
  setUserLocation,
  setDateStates,
  dateStates,
}) => {
  const handleSubmit = (values: FindTutorsInput) => {
    searchTutors(values)
  }

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box py={1}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="h6">Search Filters</Typography>
            </FormLabel>
          </FormControl>
        </AccordionSummary>
        <AccordionDetails>
          <Card elevation={0}>
            <CardContent>
              <Formik
                initialValues={normalizeInitialState(initialState)}
                onSubmit={(values: any, { setSubmitting }) => {
                  setSubmitting(false)
                  handleSubmit(
                    denormalizeValue({
                      ...values,
                    }) as FindTutorsInput,
                  )
                }}
              >
                {({ values, handleChange, resetForm }) => (
                  <Form id="search-tutor-form">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextInputField
                          id="fullName"
                          name="fullName"
                          label="Name"
                          required={false}
                          value={values.fullName}
                          onChange={handleChange}
                        />

                        <Box my={2} />

                        <TextInputField
                          id="phone-number"
                          name="phoneNumber"
                          label="Phone Number"
                          type="tel"
                          required={false}
                          value={values.phoneNumber}
                          onChange={handleChange}
                        />

                        <Box my={2} />

                        <SelectInputFieldCheckbox
                          id="subjects"
                          value={values.subjects}
                          label="Subjects"
                          name="subjects"
                          onChange={e => handleChange(e)}
                          multiple
                          required={false}
                          menuItems={
                            SubjectsArray.map(value => ({
                              name: value.toLowerCase(),
                              value: value.toLowerCase(),
                            })) as SelectMenuItem[]
                          }
                        />

                        <Box my={2} />

                        <SelectInputFieldCheckbox
                          id="grade-level"
                          value={values.gradeLevels}
                          label="Grade Level"
                          name="gradeLevels"
                          onChange={e => handleChange(e)}
                          multiple
                          required={false}
                          menuItems={
                            GradeLevelList.map(value => ({
                              name: value.toLowerCase(),
                              value,
                            })) as SelectMenuItem[]
                          }
                        />
                        <GradeLevelMultipleInput
                          value={values.gradeLevels}
                          handleChange={handleChange}
                        />

                        <Box my={2} />

                        <PlaceDetailSelector
                          setUserLocation={setUserLocation}
                          userLocation={userLocation}
                          required={false}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextInputField
                          id="email"
                          name="email"
                          label="Email"
                          type="email"
                          required={false}
                          value={values.email}
                          onChange={handleChange}
                        />

                        <Box my={2} />

                        <SelectInputField
                          id="gender"
                          name="gender"
                          value={values.gender}
                          label="Gender"
                          onChange={handleChange}
                          required={false}
                          menuItems={
                            Object.entries(UserGenderEnum).map(([_, value]) => ({
                              name: value.toLowerCase(),
                              value,
                            })) as SelectMenuItem[]
                          }
                        />

                        <Box my={2} />

                        <SelectInputFieldCheckbox
                          id="service-type"
                          name="serviceTypes"
                          value={values.serviceTypes}
                          label="Service Type"
                          multiple
                          required={false}
                          onChange={e => handleChange(e)}
                          menuItems={
                            Object.entries(ServiceTypeEnum).map(([_, value]) => ({
                              name: value.toLowerCase(),
                              value,
                            })) as SelectMenuItem[]
                          }
                        />

                        <Box my={2} />

                        <SelectInputField
                          id="status"
                          value={values.status}
                          label="Status"
                          name="status"
                          required={false}
                          onChange={e => handleChange(e)}
                          menuItems={
                            Object.entries(UserStatusEnum).map(([_, value]) => ({
                              name: value.toLowerCase(),
                              value,
                            })) as SelectMenuItem[]
                          }
                        />

                        <Box my={2} />

                        <Box>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{ start: 'Created date From', end: 'To' }}
                          >
                            <DateRangePicker
                              label="Date"
                              value={[
                                dateStates?.createdAt?.gte
                                  ? dayjs(dateStates?.createdAt?.gte)
                                  : dayjs('2023-01-01'),
                                dateStates?.createdAt?.lte
                                  ? dayjs(dateStates?.createdAt?.lte)
                                  : dayjs(new Date()),
                              ]}
                              onChange={newValue => {
                                setDateStates((prev: any) => ({
                                  ...prev,
                                  createdAt: Array.isArray(newValue)
                                    ? {
                                        gte: newValue
                                          ? newValue.length > 1
                                            ? (newValue[0] as dayjs.Dayjs).toDate()
                                            : dayjs('2023-01-01').toDate()
                                          : dayjs('2023-01-01').toDate(),
                                        lte: newValue
                                          ? newValue.length > 1
                                            ? (newValue[1] as dayjs.Dayjs).toDate()
                                            : new Date()
                                          : new Date(),
                                      }
                                    : { gte: dayjs('2023-01-01').toDate(), lte: new Date() },
                                }))
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex">
                          <Button
                            variant="outlined"
                            type="reset"
                            color="error"
                            onClick={() => {
                              resetForm()
                              setUserLocation(null)
                              handleSubmit(initialState)
                            }}
                            fullWidth
                          >
                            Cancel
                          </Button>
                          <Box mx={2} />
                          <Button variant="contained" type="submit" fullWidth>
                            Search
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default SearchTutorsCard
