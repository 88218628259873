import React from 'react'
import { Alert, Box, Button } from '@mui/material'
import TextInputField from '../../components/inputs/text-input-field'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { useGiveRating } from '../hooks/use-give-rating.hook'

export interface GiveRatingDialogProps {
  openGiveRating: boolean
  tutorId: string
  customerId: string
  contractId: string

  setOpenGiveRating(val: boolean): void

  refetch(): void
}

const GiveRatingDialog: React.FC<GiveRatingDialogProps> = ({
  openGiveRating,
  setOpenGiveRating,
  tutorId,
  customerId,
  contractId,
  refetch,
}) => {
  const { createRating, loading: giveRatingLoading, error: giveRatingError } = useGiveRating()

  return (
    <Dialog open={openGiveRating} fullWidth>
      {giveRatingLoading && <CircularIndeterminate />}

      <Formik
        initialValues={{
          review: '',
          rating: 0.0,
        }}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          createRating({
            review: values.review,
            rating: values.rating,
            tutorId,
            customerId,
          })
            .then(() => refetch())
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box py={1}>
                <TextInputField
                  name="rating"
                  id="rating"
                  value={values.rating}
                  label="Rating"
                  type="number"
                  onChange={handleChange}
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  name="review"
                  id="review"
                  value={values.review}
                  label="Review"
                  onChange={handleChange}
                />
              </Box>
            </Box>

            {giveRatingError && <Alert severity="error">{giveRatingError.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenGiveRating(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default GiveRatingDialog
