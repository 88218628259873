import { gql, useLazyQuery } from '@apollo/client'
import { TutorRating } from '../entities/tutor-rating.entity'

const FIND_REVIEWS = gql`
  query AssignTutor($findTutorRatingInput: FindTutorRatingInput!) {
    tutorRatings(findTutorRatingInput: $findTutorRatingInput) {
      id
      tutorId
      customerId
      review
      rating
      customer {
        id
        fullName
      }
      updatedAt
      createdAt
    }
  }
`

export function useFindReviews() {
  const [findReviewsGql, { loading, data, error }] = useLazyQuery<
    { tutorRatings: TutorRating[] },
    { findTutorRatingInput: any }
  >(FIND_REVIEWS, { fetchPolicy: 'no-cache' })

  function findReviews(tutorId: string) {
    return findReviewsGql({
      variables: { findTutorRatingInput: { tutorId } },
    })
  }

  return { findReviews, loading, data, error }
}
