import React, { useEffect, useState } from 'react'
import { Box, Grid, Slider } from '@mui/material'
import dayjs from 'dayjs'

import { SubjectsArray } from './components/search-tutors-card'
import { FindTutorsDateInputs, FindTutorsNumberInputs } from './dto/find-tutors.input'
import { SortTutorsInput } from '../tutors/dto/sort-tutors.input'
import SortTutorsCard from './components/sort-tutors-card'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import {
  findInitSortState,
  useFindTutors,
  useFindTutorsCount,
} from './hooks/use-find-tutors.hook'
import TutorListCard from './components/tutor-list-card'
import { UserLocationInput } from '../../common/location/location.entity'
import { PaginationInput } from '../../common/pagination/pagination'
import { useSearchPageState } from '../../context/search_page_context'
import { GradeLevelList } from '../contracts/entities/contract.enums'
import GenericSearchPage from '../generic-search/generic-search.page'
import { UserGenderEnum, UserStatusEnum } from '../users/entities/user.enums'
import { ServiceTypeEnum } from '../tutors/entities/tutor.enums'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import PlaceDetailSelector from '../components/google_places/place-detail-selector'

const SearchTutorsPage: React.FC = () => {
  const pageState = useSearchPageState()

  const { findTutors, loading, data, error } = useFindTutors()

  const [dateStates, setDateStates] = useState<FindTutorsDateInputs>({
    createdAt: {
      gte: dayjs('2019-01-01').toDate(),
      lte: new Date(),
    },
  })

  const [numberStates, setNumberStates] = useState<FindTutorsNumberInputs>({})

  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    skip: 0,
    limit: 10,
  })

  const [currentSortState, setCurrentSortState] = useState<SortTutorsInput>(findInitSortState)

  const { findCount, data: contractDataCount } = useFindTutorsCount()

  const [searchNow, setSearchNow] = useState(false)

  const [userLocation, setUserLocation] = useState<UserLocationInput>({
    city: 'addis ababa',
    coordinates: [],
    country: 'ethiopia',
    description: '',
    district: '',
    placeId: '',
    streetName: '',
    subCity: '',
    zipCode: '',
  })

  const handleLoad = (sort?: SortTutorsInput) => {
    if (searchNow) {
      let search = { ...pageState?.searchPageState.tutorsSearchState }

      if (search?.status && search?.status?.toString() === 'ALL') {
        search.status = undefined
      }

      findTutors({
        currentSearchState: {
          ...search,
          location: userLocation,
        },
        currentSortState: sort ? sort : currentSortState,
        paginationInput,
        dateStates,
        numberStates,
      }).then()
    } else {
      setSearchNow(true)
    }
  }

  useEffect(() => {
    findCount()
      .then()
      .catch(e => e)
  }, [])

  const handleSortTutors = (sortState: SortTutorsInput) => {
    setCurrentSortState(sortState)
    handleLoad(sortState)
  }

  return (
    <Box pb={2}>
      <GenericSearchPage
        data={data?.searchTutorsAdmin}
        loading={loading}
        error={error}
        countData={contractDataCount?.searchTutorsAdmin}
        find={handleLoad}
        showSearch={true}
        pageName="tutors"
        statusArray={UserStatusEnum}
        searchEntityName="tutorsSearchState"
        currentState={pageState?.searchPageState.tutorsSearchState}
        paginationInput={paginationInput}
        setPaginationInput={setPaginationInput}
        searchFields={[
          { name: 'fullName', inputType: 'TextInputField' },
          { name: 'phoneNumber', inputType: 'TextInputField', type: 'tel' },
          { name: 'email', inputType: 'TextInputField', type: 'email' },
          {
            name: 'gender',
            inputType: 'SelectInputField',
            menuItems: Object.entries(UserGenderEnum),
          },
          {
            name: 'status',
            inputType: 'SelectInputField',
            menuItems: Object.entries(UserStatusEnum),
          },
          {
            name: 'serviceTypes',
            inputType: 'SelectInputField',
            menuItems: Object.entries(ServiceTypeEnum),
          },
          {
            name: 'subjects',
            inputType: 'SelectInputFieldCheckbox',
            menuItems: SubjectsArray,
          },
          {
            name: 'gradeLevels',
            inputType: 'SelectInputFieldCheckbox',
            menuItems: GradeLevelList,
          },
        ]}
      >
        <div id="ignore_fields">
          {/*You can add more custom fields like date manipulation fields*/}

          <Box id="number_fields">
            <label>Current Clients</label>
            <Slider
              getAriaLabel={() => 'Current Clients'}
              value={[
                numberStates?.currentClients?.gte ? numberStates?.currentClients?.gte : 0,
                numberStates?.currentClients?.lte ? numberStates?.currentClients?.lte : 10,
              ]}
              onChange={(_: Event, newValue: number | number[]) => {
                setNumberStates((prev: any) => ({
                  ...prev,
                  currentClients: Array.isArray(newValue)
                    ? {
                        gte: newValue ? (newValue.length > 1 ? newValue[0] : 0) : 0,
                        lte: newValue ? (newValue.length > 1 ? newValue[1] : 10) : 10,
                      }
                    : { gte: 0, lte: 10 },
                }))
              }}
              valueLabelDisplay="auto"
              name="currentClients"
              max={10}
              min={0}
            />
          </Box>

          <Box id="number_fields">
            <label>Total Clients</label>
            <Slider
              getAriaLabel={() => 'Total Clients'}
              value={[
                numberStates?.clientsWorkedWith?.gte
                  ? numberStates?.clientsWorkedWith?.gte
                  : 0,
                numberStates?.clientsWorkedWith?.lte
                  ? numberStates?.clientsWorkedWith?.lte
                  : 50,
              ]}
              onChange={(_: Event, newValue: number | number[]) => {
                setNumberStates((prev: any) => ({
                  ...prev,
                  clientsWorkedWith: Array.isArray(newValue)
                    ? {
                        gte: newValue ? (newValue.length > 1 ? newValue[0] : 0) : 0,
                        lte: newValue ? (newValue.length > 1 ? newValue[1] : 50) : 50,
                      }
                    : { gte: 0, lte: 10 },
                }))
              }}
              valueLabelDisplay="auto"
              name="clientsWorkedWith"
              max={50}
              min={0}
            />
          </Box>

          <Box>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: 'Created date From', end: 'To' }}
            >
              <DateRangePicker
                label="Date"
                value={[
                  dateStates?.createdAt?.gte
                    ? dayjs(dateStates?.createdAt?.gte)
                    : dayjs('2023-01-01'),
                  dateStates?.createdAt?.lte
                    ? dayjs(dateStates?.createdAt?.lte)
                    : dayjs(new Date()),
                ]}
                onChange={newValue => {
                  setDateStates((prev: any) => ({
                    ...prev,
                    createdAt: Array.isArray(newValue)
                      ? {
                          gte: newValue
                            ? newValue.length > 1
                              ? (newValue[0] as dayjs.Dayjs).toDate()
                              : dayjs('2023-01-01').toDate()
                            : dayjs('2023-01-01').toDate(),
                          lte: newValue
                            ? newValue.length > 1
                              ? (newValue[1] as dayjs.Dayjs).toDate()
                              : new Date()
                            : new Date(),
                        }
                      : { gte: dayjs('2023-01-01').toDate(), lte: new Date() },
                  }))
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box>
            <PlaceDetailSelector
              setUserLocation={setUserLocation}
              userLocation={userLocation}
              required={false}
            />
          </Box>
        </div>

        <div id="list_grid">
          <SortTutorsCard sortTutors={handleSortTutors} initialState={findInitSortState} />

          <Box my={3} />

          <Grid container spacing={2}>
            {data &&
              data.searchTutorsAdmin.items.map(items => (
                <Grid key={items.id} item xs={12} md={6} lg={4}>
                  <TutorListCard tutor={items} />
                  <Box my={1} />
                </Grid>
              ))}
          </Grid>
        </div>
      </GenericSearchPage>
    </Box>
  )
}

export default SearchTutorsPage
