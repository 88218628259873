import React, { Fragment } from 'react'
import { Alert, Box, Button, Card, CardContent, Typography } from '@mui/material'
import { ProspectTutor } from '../entities/prospect-tutor.entity'
import UpdateTutorProspectStatus from './update-prospect-tutors-status'
import TutorProspectDetailDialog from './tutor-prospect-detail-dialog'
import ReactTimeAgo from 'react-time-ago'
import {
  useResendPaymentConfirmationEmailResend,
  useResendPaymentEmailResend,
  useResendWelcomeEmailResend,
} from '../hook/use-resend-email-to-prospects.hook'
import { useSendExtendDeadlineEmail } from '../hook/use-extend-deadline.hook'
import { TutorApplicationStatusEnum } from '../entities/tutor_prospects.enum'
import AddNoteCard from '../../notes/components/add-note-card'
import { useSendDocumentResubmissionEmail } from '../hook/use-send-document-resubmission-email.hook'
import { PreLoader } from '../../components/preloader/preloader'

const ProspectTutorItem = ({
  tutorProspect,
  refetch,
}: {
  tutorProspect: ProspectTutor
  refetch(): void
}) => {
  const [openDetail, setOpenDetail] = React.useState(false)

  const {
    resendWelcomeEmail,
    loading: welcomeSendingSending,
    data: welcomeSent,
    error: welcomeSendingError,
  } = useResendWelcomeEmailResend()

  const {
    resendPaymentEmail,
    loading: paymentSending,
    data: paymentSent,
    error: paymentSendingError,
  } = useResendPaymentEmailResend()

  const {
    sendExtendDeadlineEmail,
    loading: deadlineExtensionSending,
    data: deadlineExtensionSent,
    error: deadlineExtensionSendingError,
  } = useSendExtendDeadlineEmail()

  const {
    sendDocumentResubmissionEmail,
    loading: documentResubmissionLoading,
    data: documentResubmissionSent,
    error: documentResubmissionError,
  } = useSendDocumentResubmissionEmail()

  const {
    resendPaymentConfirmationEmail,
    loading: resendPaymentConfirmationLoading,
    data: resendPaymentConfirmationSent,
    error: resendPaymentConfirmationError,
  } = useResendPaymentConfirmationEmailResend()

  return (
    <Card>
      <TutorProspectDetailDialog
        open={openDetail}
        handleClose={setOpenDetail}
        prospectTutor={tutorProspect}
      />

      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography>{tutorProspect.fullName}</Typography>
            <Typography>{tutorProspect.email}</Typography>
            <Typography>{tutorProspect.phoneNumber}</Typography>
          </Box>
          <Typography fontSize="small">
            <ReactTimeAgo date={new Date(tutorProspect.statusUpdatedAt)} locale="en-US" />
          </Typography>
        </Box>

        <Box my={1} />

        {tutorProspect.documentLinks && (
          <>
            <Typography fontSize="small">Document Links</Typography>
            {Object.entries(tutorProspect.documentLinks).map(([filed, value]) => {
              return (
                filed !== '__typename' && (
                  <Fragment key={filed}>
                    <a href={`${value}`} target="_blank">
                      {filed}
                    </a>{' '}
                    <br />
                  </Fragment>
                )
              )
            })}
          </>
        )}

        <Box pt={1} />

        {tutorProspect.applicationStatus === TutorApplicationStatusEnum.CONTRACT_SIGNED && (
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                resendWelcomeEmail(tutorProspect.userId).then()
              }}
            >
              Re-send welcome email
            </Button>
          </Box>
        )}

        {tutorProspect.applicationStatus === TutorApplicationStatusEnum.VIDEO_PASSED && (
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                resendPaymentEmail(tutorProspect.id ? tutorProspect.id : '').then()
              }}
            >
              Re-send payment email
            </Button>
          </Box>
        )}

        {tutorProspect.applicationStatus === TutorApplicationStatusEnum.PAID && (
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                resendPaymentConfirmationEmail(tutorProspect.id ? tutorProspect.id : '').then()
              }}
            >
              Re-send payment confirmation email
            </Button>
          </Box>
        )}

        {tutorProspect.applicationStatus === TutorApplicationStatusEnum.PASSED &&
          tutorProspect?.secondRoundExpiresAt &&
          new Date(tutorProspect.secondRoundExpiresAt) < new Date(Date.now()) && (
            <Box>
              <Button
                variant="outlined"
                onClick={() => {
                  sendExtendDeadlineEmail(tutorProspect.id ? tutorProspect.id : '').then(() =>
                    refetch(),
                  )
                }}
              >
                Give 3 days Extension
              </Button>
            </Box>
          )}

        {((tutorProspect.applicationStatus === TutorApplicationStatusEnum.VIDEO_UPLOADED &&
          tutorProspect.resubmitDocuments == false) ||
          (tutorProspect.applicationStatus === TutorApplicationStatusEnum.VIDEO_UPLOADED &&
            tutorProspect.resubmitDocuments == true &&
            tutorProspect.secondRoundExpiresAt &&
            new Date(tutorProspect.secondRoundExpiresAt) < new Date(Date.now()))) && (
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                sendDocumentResubmissionEmail(tutorProspect.id ? tutorProspect.id : '').then(
                  () => refetch(),
                )
              }}
            >
              Allow Resubmission
            </Button>
          </Box>
        )}
      </CardContent>

      {welcomeSendingError && <Alert severity="error">{welcomeSendingError.message}</Alert>}
      {welcomeSent && <Alert severity="info">{welcomeSent.sendWelcomeEmail}</Alert>}

      {paymentSendingError && <Alert severity="error">{paymentSendingError.message}</Alert>}
      {paymentSent && <Alert severity="info">{paymentSent.sendRegistrationFeeInfo}</Alert>}

      {deadlineExtensionSendingError && (
        <Alert severity="error">{deadlineExtensionSendingError.message}</Alert>
      )}
      {deadlineExtensionSent && (
        <Alert severity="info">{deadlineExtensionSent.sendExtendDeadlineEmail}</Alert>
      )}

      {documentResubmissionError && (
        <Alert severity="error">{documentResubmissionError.message}</Alert>
      )}
      {documentResubmissionSent && (
        <Alert severity="info">{documentResubmissionSent.sendDocumentResubmissionEmail}</Alert>
      )}

      {resendPaymentConfirmationError && (
        <Alert severity="error">{resendPaymentConfirmationError.message}</Alert>
      )}
      {resendPaymentConfirmationSent && (
        <Alert severity="info">
          {resendPaymentConfirmationSent.sendPaymentConfirmationEmail}
        </Alert>
      )}

      {(welcomeSendingSending ||
        paymentSending ||
        deadlineExtensionSending ||
        documentResubmissionLoading ||
        resendPaymentConfirmationLoading) && <PreLoader />}

      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <UpdateTutorProspectStatus prospectTutor={tutorProspect} refetch={refetch} />

        <Button color="info" onClick={() => setOpenDetail(true)}>
          Detail
        </Button>
      </Box>
      <AddNoteCard prospectTutor={tutorProspect} refetch={refetch} />
    </Card>
  )
}

export default ProspectTutorItem
