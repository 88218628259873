import React from 'react'
import { Box, Typography } from '@mui/material'

const BirrInfoCard = ({
  title,
  amount,
  label,
}: {
  title: string
  amount: string
  label: string
}) => (
  <Box
    sx={{
      border: 1,
      borderRadius: 1,
      borderColor: 'secondary.dark',
      p: 1,
      color: 'secondary.dark',
      backgroundColor: 'lightBlue',
    }}
  >
    <Typography variant="body1" noWrap>
      {title}
    </Typography>
    <Box display="inline-flex">
      <Typography noWrap variant="h5" fontWeight="bold">
        {amount}
      </Typography>
      <Typography noWrap variant="subtitle2">
        {label}
      </Typography>
    </Box>
  </Box>
)

export default BirrInfoCard
