import { gql, useLazyQuery } from '@apollo/client'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Add } from '@mui/icons-material'
import { Commission } from '../commission/entities/commission.entity'

const FIND_COMMISSIONS = gql`
  query FindAllCommissions {
    findAllCommissions {
      year
      id
      active
      paymentRanges {
        from
        id
        percent
        to
      }
    }
  }
`

const PaymentRanges = () => {
  const [findPaymentRanges, { loading, data, error }] = useLazyQuery<{
    findAllCommissions: Commission[]
  }>(FIND_COMMISSIONS)

  const handleLoadPayments = () => {
    findPaymentRanges()
  }

  useEffect(() => {
    handleLoadPayments()
  }, [])

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" my={2}>
        <Button startIcon={<Add />} variant="contained">
          Add
        </Button>
      </Box>
      {data && (
        <Grid container spacing={2}>
          {data.findAllCommissions.map(c => (
            <Grid key={c.id} item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="bold" fontSize="large">
                      &quot;{c.year}&quot;
                    </Typography>
                    <Typography fontWeight="bold" color={c.active ? 'green' : 'red'}>
                      {c.active ? 'Active' : 'Disabled'}
                    </Typography>
                  </Box>
                  <Box my={1} />
                  <Box>
                    {c.paymentRanges.map(p => (
                      <Box key={p.percent} display="flex" justifyContent="space-evenly">
                        <Typography fontWeight="bold">{p.from}</Typography>
                        <Typography>-</Typography>
                        <Typography fontWeight="bold" fontSize="bold">
                          {p.to}
                        </Typography>
                        <Typography>-</Typography>
                        <Typography fontWeight="bold">{p.percent}%</Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default PaymentRanges
