import React from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import { User } from '../entities/user.entity'
import { GridCloseIcon } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'

interface UserDetailDialogProps {
  open: boolean
  user: User

  handleClose(open: boolean): void
}

const UserDetailDialog: React.FC<UserDetailDialogProps> = ({ open, user, handleClose }) => {
  return (
    <Dialog open={open}>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>User Info</Typography>
            <IconButton onClick={() => handleClose(false)}>
              <GridCloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" noWrap fontSize={18}>
              {`${user?.fullName}`}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography>{user?.phoneNumber}</Typography>
          </Box>
          <Box display="flex">
            <Typography>{user?.email}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Gender
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{user?.gender}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              User type
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{user?.role}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Status
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{user?.status}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Registration Date
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography noWrap>{user.createdAt.toString().split('T')[0]}</Typography>
          </Box>
        </CardContent>

        <CardActionArea>
          <Box p={2}>
            <Link
              href={
                user.tutorId
                  ? `/tutor/${user.tutorId}`
                  : user.customerId
                  ? `/customer/${user.customerId}`
                  : ''
              }
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Typography color="mediumblue">
                {user.tutorId
                  ? 'Goto Tutor Detail'
                  : user.customerId
                  ? 'Goto Customer Detail'
                  : ''}
              </Typography>
            </Link>
          </Box>
        </CardActionArea>
      </Card>
    </Dialog>
  )
}

export default UserDetailDialog
