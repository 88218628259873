import React, { useEffect, useState } from 'react'
import { Alert, Box, Grid } from '@mui/material'
import { CircularIndeterminate } from '../components/preloader/preloader'
import { PaymentStatusEnum } from '../payments/entities/payment.enums'
import TablePaginationComponent from '../components/pagination/table-paginaiton-component'
import moment from 'moment'
import { useFindPayments } from '../search_payments/hooks/use-find-payments.hook'
import { CustomerTypeEnum } from '../customers/entity/customer.enums'
import PaymentCardList from '../search_payments/components/payment-card-list'
import { PaginationInput } from '../../common/pagination/pagination'

const UpcomingPaymentsPage: React.FC = () => {
  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    skip: 0,
    limit: 10,
  })

  const {
    findPayments,
    loading: paymentLoading,
    data: paymentData,
    error: paymentError,
  } = useFindPayments()

  const handleLoadPayments = () => {
    findPayments({
      currentSearchState: {
        customerType: CustomerTypeEnum.SINGLE_CUSTOMER,
        endDate: {
          lte: moment(new Date()).add(5, 'days').toDate(),
          // gte: new Date(new Date().getTime() - 90 * 60 * 60 * 24 * 1000),
        },
        status: PaymentStatusEnum.ACTIVE,
      },
      paginationInput,
    }).catch(e => e)
  }

  useEffect(() => {
    handleLoadPayments()
  }, [paginationInput])

  return (
    <Box>
      {paymentLoading && <CircularIndeterminate />}
      {paymentError && <Alert severity="error">{paymentError.message}</Alert>}

      <Box pb={3}>
        <Grid container spacing={2}>
          {paymentData &&
            paymentData.payments.items.map(payment => (
              <Grid key={payment.id} item xs={12} md={6} lg={4}>
                <PaymentCardList payment={payment} refetch={handleLoadPayments} />
              </Grid>
            ))}
        </Grid>

        {paymentData && (
          <TablePaginationComponent
            setPaginationInput={setPaginationInput}
            pageInfo={paymentData.payments.pageInfo}
          />
        )}
      </Box>
    </Box>
  )
}

export default UpcomingPaymentsPage
