import React from 'react'
import { Box, Grid } from '@mui/material'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import TextInputField from '../../components/inputs/text-input-field'
import { LearningDaysEnum } from '../../contracts/entities/contract.enums'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import { UserGenderEnum } from '../../users/entities/user.enums'
import FormLabel from '../../components/labels/form-label'
import SelectInputFieldCheckbox from '../../components/inputs/select-input-field-checkbox'

interface JobInfoProps {
  tutorGender: UserGenderEnum
  startDate: string
  description: string
  serviceType: ServiceTypeEnum
  startTime: string
  endTime: string
  learningDays: LearningDaysEnum[]

  handleChange(val: any): void
}

const CreateContractInfo: React.FC<JobInfoProps> = ({
  tutorGender,
  serviceType,
  learningDays,
  description,
  startTime,
  startDate,
  endTime,
  handleChange,
}) => (
  <Box py={4}>
    <FormLabel name="Job Info" />
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box py={1}>
          <SelectInputField
            id="tutor-gender"
            name="tutorGender"
            value={tutorGender}
            label="Tutor Gender"
            onChange={e => {
              handleChange(e)
            }}
            menuItems={
              Object.entries(UserGenderEnum).map(([_, value]) => ({
                name: value.toLowerCase(),
                value,
              })) as SelectMenuItem[]
            }
          />
        </Box>
        <Box py={1}>
          <Box py={1}>
            <TextInputField
              name="startDate"
              id="start-date"
              value={
                new Date(startDate).toISOString().length >= 10
                  ? new Date(startDate).toISOString().substring(0, 10)
                  : startDate
              }
              label="Start Date"
              type="date"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box py={1}>
          <SelectInputFieldCheckbox
            id="learning-days"
            value={learningDays}
            name="learningDays"
            label="Learning Days"
            onChange={e => {
              handleChange(e)
            }}
            multiple
            menuItems={
              Object.entries(LearningDaysEnum).map(([_, value]) => ({
                name: value.toLowerCase(),
                value,
              })) as SelectMenuItem[]
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box py={1}>
          <SelectInputField
            id="service-type"
            name="serviceType"
            value={serviceType}
            label="Service Type"
            onChange={handleChange}
            menuItems={
              Object.entries(ServiceTypeEnum).map(([_, value]) => ({
                name: value.toLowerCase(),
                value,
              })) as SelectMenuItem[]
            }
          />
        </Box>
        <Box pt={2}>
          <Grid container>
            <Grid item xs={6}>
              <TextInputField
                name="startTime"
                id="start-time"
                value={startTime}
                label="Start Time"
                type="time"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputField
                name="endTime"
                id="end-time"
                value={endTime}
                label="End Time"
                type="time"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box pt={2}>
          <Box py={1}>
            <TextInputField
              id="description"
              value={description}
              label="Description"
              name="description"
              onChange={handleChange}
              required={false}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
)

export default CreateContractInfo
