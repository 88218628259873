import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { User } from '../app/users/entities/user.entity'

interface AuthState {
  accessToken: string
  refreshToken: string
  role: string
  user: User
}

interface AuthContextType {
  authState: AuthState | null
  setAuthState: (authState: AuthState | null) => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider: React.FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const [authState, setAuthState] = useState<AuthState | null>(() => {
    const storedAuth = localStorage.getItem('authState')
    return storedAuth ? JSON.parse(storedAuth) : null
  })

  useEffect(() => {
    if (authState) {
      localStorage.setItem('authState', JSON.stringify(authState))
    } else {
      localStorage.removeItem('authState')
    }
  }, [authState])

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>{children}</AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
