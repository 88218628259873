import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'

const MajorsSection = () => {
  const MAJORS = gql`
    query {
      majors {
        id
        name
      }
    }
  `

  const CREATE_MAJORS = gql`
    mutation ($majors: [String!]!) {
      createMajors(majors: $majors)
    }
  `

  const DELETE_MAJORS = gql`
    mutation ($majorId: String!) {
      deleteMajor(majorId: $majorId) {
        id
      }
    }
  `
  const [getMajors, { loading, data, error }] = useLazyQuery(MAJORS)
  const [createMajors, { loading: creating, data: creatingData, error: creatingError }] =
    useMutation(CREATE_MAJORS)
  const [deleteMajors, { loading: deleting, data: deletingData, error: deletingError }] =
    useMutation(DELETE_MAJORS)

  useEffect(() => {
    getMajors().then()
  }, [creatingData, deletingData])

  const [expanded, setExpanded] = useState<string | false>(false)
  const [openAddMajors, setOpenAddMajors] = useState(false)
  const [majorName, setMajorsName] = useState('')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box mb={3}>
      {(loading || creating || deleting) && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {creatingError && <Alert severity="error">{creatingError.message}</Alert>}
      {deletingError && <Alert severity="error">{deletingError.message}</Alert>}

      <Dialog open={openAddMajors}>
        <Box p={2}>
          <form
            onSubmit={e => {
              e.preventDefault()
              setOpenAddMajors(false)
              createMajors({
                variables: {
                  majors: majorName
                    .split(',')
                    .map(m => m.trim().replace(/"/gi, '').replace(/'/gi, '')),
                },
              }).then()
            }}
          >
            <Box mb={2}>
              <TextField
                id="name"
                name="name"
                value={majorName}
                required
                onChange={e => setMajorsName(e.target.value)}
              />
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button color="secondary" type="button" onClick={() => setOpenAddMajors(false)}>
                Cancel
              </Button>
              <Button type="submit">Add</Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5">Majors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2} display="flex" justifyContent="right">
            <Button onClick={() => setOpenAddMajors(true)}>Add Majors</Button>
          </Box>
          {data && (
            <Box>
              <Grid container spacing={2}>
                {data.majors.map((s: any) => (
                  <Grid key={s.id} item xs={6} md={4} lg={3}>
                    <Card>
                      <CardContent>
                        <Box display="flex" justifyContent="space-between">
                          <Typography>{s.name}</Typography>
                          <Box display="flex">
                            <IconButton
                              onClick={() => {
                                deleteMajors({
                                  variables: {
                                    majorId: s.id,
                                  },
                                }).then()
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default MajorsSection
