import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { FindTutorsInput } from '../app/search_tutors/dto/find-tutors.input'
import { FindUsersInput } from '../app/users/dto/find-users.input'
import { FindProspectsTutorsInput } from '../app/prospect_tutors/dto/find-prospects-tutors.input'
import { FindPaymentsInput } from '../app/search_payments/dto/find-payments.input'
import { FindContractsInput } from '../app/search_contracts/dto/find-contracts.input'
import { findTutorInitialState } from '../app/search_tutors/hooks/use-find-tutors.hook'
import { findContractsInitialState } from '../app/search_contracts/hooks/use-find-contracts.hook'
import { initialUserSearchState } from '../app/users/hooks/use-find-users.hook'
import { findProspectTutorsInitialState } from '../app/prospect_tutors/hook/use-find-tutor-prospects.hook'
import { findPaymentsInitialState } from '../app/search_payments/hooks/use-find-payments.hook'

interface SearchPageState {
  tutorsSearchState: FindTutorsInput
  contractsSearchState: FindContractsInput
  paymentsSearchState: FindPaymentsInput
  prospectTutorsSearchState: FindProspectsTutorsInput
  usersSearchState: FindUsersInput
}

interface SearchPageContextType {
  searchPageState: SearchPageState
  setSearchPageState: (searchPageState: SearchPageState) => void
}

const SearchPageContext = createContext<SearchPageContextType | null>(null)

export const SearchPageProvider: React.FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const [searchPageState, setSearchPageState] = useState<SearchPageState>({
    tutorsSearchState: findTutorInitialState,
    contractsSearchState: findContractsInitialState,
    usersSearchState: initialUserSearchState,
    prospectTutorsSearchState: findProspectTutorsInitialState,
    paymentsSearchState: findPaymentsInitialState,
  })
  // const [searchPageState, setSearchPageState] = useState<SearchPageState>(() => {
  //   const storedState = localStorage.getItem('searchPageState')
  //   return storedState
  //     ? JSON.parse(storedState)
  //     : {
  //         tutorsSearchState: findTutorInitialState,
  //         contractsSearchState: findContractsInitialState,
  //         usersSearchState: initialUserSearchState,
  //         prospectTutorsSearchState: findProspectTutorsInitialState,
  //         paymentsSearchState: findPaymentsInitialState,
  //       }
  // })

  useEffect(() => {
    if (searchPageState) {
      // localStorage.setItem('searchPageState', JSON.stringify(searchPageState))
    } else {
      localStorage.removeItem('searchPageState')
    }
  }, [searchPageState])

  return (
    <SearchPageContext.Provider value={{ searchPageState, setSearchPageState }}>
      {children}
    </SearchPageContext.Provider>
  )
}

export const useSearchPageState = () => useContext(SearchPageContext)
