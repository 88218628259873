import * as React from 'react'
import { TablePagination } from '@mui/material'
import { PageInfo, PaginationInput } from '../../../common/pagination/pagination'

interface TablePaginationComponentProps {
  pageInfo: PageInfo
  setPaginationInput: React.Dispatch<React.SetStateAction<PaginationInput>>
}

const TablePaginationComponent: React.FC<TablePaginationComponentProps> = ({
  pageInfo,
  setPaginationInput,
}) => {
  const handlePageChange = (_: React.MouseEvent<HTMLButtonElement> | null, skip: number) => {
    setPaginationInput(prev => ({
      ...prev,
      skip,
    }))
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaginationInput(prev => ({
      ...prev,
      limit: event.target.value ? parseInt(event.target.value, 10) : 100,
    }))
  }

  return (
    <TablePagination
      component="div"
      color="black"
      count={pageInfo.count}
      page={pageInfo.skip}
      onPageChange={handlePageChange}
      rowsPerPage={pageInfo.limit}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  )
}

export default TablePaginationComponent
