import StatusToggleButton from '../../components/inputs/status-toggle-button'
import React from 'react'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Alert, Box } from '@mui/material'
import { ProspectTutor } from '../entities/prospect-tutor.entity'
import { useUpdateProspectTutor } from '../hook/use-update-prospect-tutors-status.hook'
import {
  TutorApplicationStatusEnum,
  TutorApplicationStatusUpdateEnum,
} from '../entities/tutor_prospects.enum'

interface UpdateProspectTutorStatusProps {
  prospectTutor: ProspectTutor

  refetch(): void
}

const UpdateTutorProspectStatus: React.FC<UpdateProspectTutorStatusProps> = ({
  prospectTutor,
  refetch,
}) => {
  const { updateProspectTutor, loading, error, data } = useUpdateProspectTutor()

  return (
    <Box>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}
      <StatusToggleButton
        options={Object.entries(TutorApplicationStatusUpdateEnum).map(([_, value]) => value)}
        clicked={s =>
          prospectTutor.id &&
          updateProspectTutor({
            applicationStatus: s as TutorApplicationStatusEnum,
            tutorProspectId: prospectTutor.id,
          })
            .then(() => {
              refetch()
            })
            .catch(e => e)
        }
        selected={prospectTutor.applicationStatus}
      />
    </Box>
  )
}

export default UpdateTutorProspectStatus
