import React, { useEffect, useState } from 'react'
import { Alert, Grid, Typography } from '@mui/material'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { useFindPayments } from '../../search_payments/hooks/use-find-payments.hook'
import PaymentCard from '../../payments/components/payment-card'
import { Contract } from '../../contracts/entities/contract.entity'
import { Payment } from '../../payments/entities/payment.entity'

interface PaymentsSectionProps {
  contract: Contract

  handleGetContracts(): void
}

const PaymentsSection: React.FC<PaymentsSectionProps> = ({ handleGetContracts, contract }) => {
  const { findPayments, loading, data, error } = useFindPayments()

  const [payments, setPayments] = useState<Payment[] | null | undefined>(null)

  const handleFindPayments = () => {
    findPayments({
      paginationInput: { skip: 0, limit: 100 },
      currentSearchState: { contractId: contract.id },
    }).then(res => setPayments(res.data?.payments?.items))
  }

  useEffect(() => {
    handleFindPayments()
  }, [contract])

  return (
    <>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}

      {payments && payments.length > 0 && (
        <>
          <Typography fontWeight="bold" fontSize={18} mb={2}>
            Payments
          </Typography>
          <Grid container spacing={2}>
            {payments.map(payment => (
              <Grid key={payment.id} item xs={12}>
                <PaymentCard
                  key={payment.id}
                  payment={payment}
                  contract={contract}
                  refetch={() => {
                    handleGetContracts()
                    handleFindPayments()
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  )
}

export default PaymentsSection
