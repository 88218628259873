import { gql, useLazyQuery } from '@apollo/client'
import { Contract, contractStringFull } from '../../contracts/entities/contract.entity'

export function useFindContractDetail() {
  const [findContractDetailGql, { loading, data, error }] = useLazyQuery<
    { contract: Contract },
    { contractId: string }
  >(GET_CONTRACT_DETAIL, { fetchPolicy: 'no-cache' })

  function findContractDetail(id?: string) {
    return findContractDetailGql({
      variables: {
        contractId: id ? id?.toString() : '',
      },
    })
  }

  return { findContractDetail, loading, data, error }
}

const GET_CONTRACT_DETAIL = gql`
  query Contract($contractId: String!) {
    contract(contractId: $contractId) {
      ${contractStringFull}
    }
  }
`
