import React from 'react'
import { Alert, Box, Button } from '@mui/material'
import TextInputField from '../../components/inputs/text-input-field'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Payment } from '../entities/payment.entity'
import { useUpdateDailyLog } from '../hook/use-update-daily-log.hook'
import { DailyLog } from '../entities/daily-log.entity'
import { changeDateToTime, changeTimeToDate } from '../../../functions/date-functions'
import moment from 'moment'

interface UpdateDailyLogDialogProps {
  dailyLog: DailyLog
  payment: Payment
  openUpdateDailyLog: boolean

  setOpenUpdateDailyLog(val: boolean): void

  refetch(): void
}

const UpdateDailyLogDialog: React.FC<UpdateDailyLogDialogProps> = ({
  payment,
  dailyLog,
  openUpdateDailyLog,
  setOpenUpdateDailyLog,
  refetch,
}) => {
  const { updateDailyLog, loading, error } = useUpdateDailyLog()

  return (
    <Dialog open={openUpdateDailyLog} fullWidth>
      {loading && <CircularIndeterminate />}
      <Formik
        initialValues={{
          startTime: dailyLog.startTime,
          endTime: dailyLog.endTime,
          description: dailyLog.description,
          date: dailyLog.date,
        }}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          updateDailyLog({
            paymentId: payment.id,
            dailyLogs: [
              {
                ...values,
                id: dailyLog.id,
                childId: dailyLog.childId,
                startTime: changeTimeToDate(values.date, values.startTime, '17:00'),
                endTime: changeTimeToDate(values.date, values.endTime, '18:00'),
              },
            ],
          })
            .then(() => {
              setOpenUpdateDailyLog(false)
              refetch()
            })
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box display="flex">
                <TextInputField
                  id="start-time"
                  name="startTime"
                  label="Start time"
                  value={changeDateToTime(values.startTime, '17:00:00')}
                  type="time"
                  onChange={handleChange}
                />
                <TextInputField
                  id="end-time"
                  name="endTime"
                  label="End time"
                  value={changeDateToTime(values.endTime, '18:00:00')}
                  type="time"
                  onChange={handleChange}
                />
              </Box>
              <Box my={2} />
              <TextInputField
                id="date"
                name="date"
                label="Date"
                value={moment(values.date).format('yyyy-MM-DD')}
                type="date"
                onChange={handleChange}
              />
              <Box my={2} />
              <TextInputField
                id="description"
                name="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
              />
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenUpdateDailyLog(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default UpdateDailyLogDialog
