export interface UserLocation {
  id: string
  country: string
  city: string
  subCity: string
  district: string
  description: string
  zipCode: string
  streetName: string
  placeId: string
  coordinates: number[]
}

export interface UserLocationInput {
  id?: string
  country: string
  city: string
  subCity: string
  district: string
  description: string
  zipCode: string
  streetName: string
  placeId: string
  coordinates: number[]
}

export const locationStringFull = `
  city
  country
  description
  id
  placeId
  streetName
  zipCode
  coordinates
  district
  subCity
`
