import { Alert, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useContractsData } from '../hooks/use-contracts-data.hook'
import Chart from './chart'
import { ContractStatusEnum } from '../../app/contracts/entities/contract.enums'
import { SelectMenuItem } from '../../app/components/inputs/select-input-field'
import SelectInputFieldCheckbox from '../../app/components/inputs/select-input-field-checkbox'
import { setDefaultValues } from '../../functions/filter-objects'

const ContractsData = () => {
  const { contractsData, data, error } = useContractsData()

  const [statuses, setStatuses] = useState([
    ContractStatusEnum.CONFIRMED,
    ContractStatusEnum.STARTED,
    ContractStatusEnum.FINISHED,
  ])
  const [response, setResponse] = useState<null | JSON[]>(null)

  useEffect(() => {
    if (data) {
      setResponse(setDefaultValues(data.advancedContractsAnalytics, statuses))
    }
  }, [data])

  return (
    <Box px={3} mb={5}>
      {error && <Alert severity="error">{error.message}</Alert>}

      <Box my={5}>
        {/* <SelectInputFieldCheckbox
          id="statuses"
          value={statuses}
          label="Statuses"
          name="statuses"
          onChange={e => {
            setStatuses(e.target.value)
          }}
          multiple
          menuItems={
            Object.entries(ContractStatusEnum).map(([key, value]) => ({
              name: key,
              value,
            })) as SelectMenuItem[]
          }
        /> */}
      </Box>

      {/* <Chart data={response} getData={contractsData} defaultStatuses={statuses} /> */}
    </Box>
  )
}

export default ContractsData
