import React from 'react'
import { TextField } from '@mui/material'

interface TextInputFieldProps {
  id: string
  label: string
  name: string
  value?: any
  type?: string
  required?: boolean

  onChange?(value?: any): void
}

const TextInputField: React.FC<TextInputFieldProps> = ({
  id,
  name,
  label,
  value,
  required,
  onChange,
  type = 'text',
}) => (
  <TextField
    id={id}
    name={name}
    label={label}
    variant="standard"
    type={type}
    fullWidth
    required={required !== undefined ? required : true}
    value={value}
    onChange={onChange}
  />
)

export default TextInputField
