import React from 'react'
import { Alert, Box, Button, Grid } from '@mui/material'
import { ContractTypeEnum } from '../../contracts/entities/contract.enums'
import TextInputField from '../../components/inputs/text-input-field'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import { UserGenderEnum } from '../../users/entities/user.enums'
import { Customer } from '../../customers/entity/customer.entity'
import {
  createContractInitialState,
  useCreateContract,
} from '../../create_contract/hooks/use-create-contract.hook'

export interface AddContractDialogProps {
  customer: Customer
  openAddContract: boolean

  setOpenAddContract(val: boolean): void

  refetch(): void
}

const AddContractDialog: React.FC<AddContractDialogProps> = ({
  customer,
  openAddContract,
  setOpenAddContract,
  refetch,
}) => {
  const { createContract, loading, data, error } = useCreateContract()

  return (
    <Dialog open={openAddContract} fullWidth>
      {loading && <CircularIndeterminate />}

      <Formik
        initialValues={createContractInitialState}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          createContract({
            ...values,
            customerId: customer.id,
            children: [],
            fullName: customer.fullName,
            phoneNumber: customer.phoneNumber,
            customerGender: customer.gender,
            location: { ...customer.location, __typename: undefined },
          })
            .then(() => {
              refetch()
              setOpenAddContract(false)
            })
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box py={1}>
                <SelectInputField
                  id="contractType"
                  value={values.contractType}
                  label="Contract Type"
                  name="contractType"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(ContractTypeEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputField
                  id="service-type"
                  name="serviceType"
                  value={values.serviceType}
                  label="Service Type"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(ServiceTypeEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputField
                  id="tutor-gender"
                  name="tutorGender"
                  value={values.tutorGender}
                  label="Tutor Gender"
                  onChange={e => {
                    handleChange(e)
                  }}
                  menuItems={
                    Object.entries(UserGenderEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  name="startDate"
                  id="start-date"
                  value={values.startDate.toISOString().slice(0, 10)}
                  label="Start Date"
                  type="date"
                  onChange={handleChange}
                />
              </Box>

              <Box pt={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <TextInputField
                      name="startTime"
                      id="start-time"
                      value={values.startTime}
                      label="Start Time"
                      type="time"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInputField
                      name="endTime"
                      id="end-time"
                      value={values.endTime}
                      label="End Time"
                      type="time"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenAddContract(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AddContractDialog
