import React, { useEffect, useState } from 'react'
import { Alert, Avatar, Box, Button, Grid, Typography } from '@mui/material'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Tutor } from '../../tutors/entities/tutor.entity'
import {
  updateTutorInitialState,
  useUpdateTutor,
} from '../../tutors/hooks/use-update-tutor.hook'
import { UpdateTutorInput } from '../../tutors/dto/update-tutor.inputs'
import TextInputField from '../../components/inputs/text-input-field'
import SelectInputFieldCheckbox from '../../components/inputs/select-input-field-checkbox'
import { SubjectsArray } from '../../search_tutors/components/search-tutors-card'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import { GradeLevelList, LearningDaysEnum } from '../../contracts/entities/contract.enums'
import { UserStatusEnum } from '../../users/entities/user.enums'

const Reasons = [
  'did not pay commission fee',
  'constantly rejecting jobs',
  'discipline issues',
  'does not follow rules',
  'continued working with the customer without informing',
  'stopped tutoring',
  'went with another tutoring service',
  'test',
]

export interface UpdateTutorDialogProps {
  tutor: Tutor
  openTutorEdit: boolean

  setOpenTutorEdit(val: boolean): void

  refetch(): void
}

const UpdateTutorDialog: React.FC<UpdateTutorDialogProps> = ({
  tutor,
  openTutorEdit,
  setOpenTutorEdit,
  refetch,
}) => {
  const { updateTutor, loading, data, error } = useUpdateTutor()

  useEffect(() => {
    if (data) {
      setOpenTutorEdit(false)
      refetch()
    }
  }, [data])

  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [formValues, setFormValues] = useState<UpdateTutorInput>(
    updateTutorInitialState(tutor),
  )
  const [blockReason, setBlockReason] = useState('')

  return (
    <Dialog open={openTutorEdit} fullWidth>
      {loading && <CircularIndeterminate />}

      <Dialog open={openConfirmation}>
        <Box px={3} py={2}>
          <Typography>Are you sure you want to update?</Typography>
          {error && <Alert severity="error">{error.message}</Alert>}
          <form
            onSubmit={e => {
              e.preventDefault()
              const {
                firstName,
                middleName,
                lastName,
                accountNumber,
                bankName,
                tutorId,
                ...updates
              } = formValues
              updateTutor({
                tutorId: tutor.id,
                fullName: `${formValues.firstName} ${formValues.middleName} ${formValues.lastName}`,
                bankInfo: {
                  accountNumber: formValues.accountNumber,
                  bankName: formValues.bankName,
                },
                ...updates,
                blockingReasons:
                  blockReason !== ''
                    ? [
                        {
                          description: blockReason,
                        },
                      ]
                    : undefined,
              } as UpdateTutorInput)
                .then(() => {
                  refetch()
                  setOpenConfirmation(false)
                })
                .catch(e => e)
            }}
          >
            <Box px={3} py={2}>
              {formValues.status === UserStatusEnum.BLOCKED &&
                tutor.status !== UserStatusEnum.BLOCKED && (
                  <SelectInputField
                    id="blockingReasons"
                    value={blockReason}
                    label="Blocking Reasons"
                    name="blockingReasons"
                    required={true}
                    onChange={e => setBlockReason(e.target.value)}
                    menuItems={
                      Reasons.map(value => ({
                        name: value.toLowerCase(),
                        value,
                      })) as SelectMenuItem[]
                    }
                  />
                )}
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                type="button"
                onClick={() => {
                  setOpenConfirmation(false)
                  setBlockReason('')
                }}
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Formik
        initialValues={updateTutorInitialState(tutor)}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          setOpenConfirmation(true)
          setFormValues(values)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box p={2}>
              <Grid container columnSpacing={{ md: 2 }}>
                <Grid item xs={12} md={2}>
                  <Box display="flex" justifyContent="space-evenly">
                    <Avatar
                      alt="profile_pic"
                      // src={data.findTutorById.profilePic ? data.findTutorById.profilePic : pic}
                      sx={{ width: 85, height: 85, borderRadius: '50px' }}
                      variant="square"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextInputField
                    name="firstName"
                    id="first-name"
                    label="first-name"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  <TextInputField
                    name="middleName"
                    id="middle-name"
                    label="middle-name"
                    value={values.middleName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextInputField
                    name="lastName"
                    id="last-name"
                    label="last-name"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                  <TextInputField
                    name="phoneNumber"
                    id="Phone number"
                    label="Phone number"
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={{ md: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextInputField
                    name="pitch"
                    id="pitch"
                    label="pitch"
                    value={values.pitch}
                    onChange={handleChange}
                  />
                  {/*<TextInputField*/}
                  {/*  name="address"*/}
                  {/*  id="address"*/}
                  {/*  label="Address"*/}
                  {/*  value={address}*/}
                  {/*  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
                  {/*    setAddress(e.target.value)*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*<TextInputField*/}
                  {/*  name="bankName"*/}
                  {/*  id="bank-name"*/}
                  {/*  label="Bank name"*/}
                  {/*  value={bankName}*/}
                  {/*  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
                  {/*    setBankName(e.target.value)*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <SelectInputFieldCheckbox
                    id="subjects"
                    value={values.subjects ? values.subjects : []}
                    label="Subjects"
                    name="subjects"
                    onChange={e => {
                      handleChange(e)
                    }}
                    multiple
                    menuItems={
                      SubjectsArray.map(value => ({
                        name: value.toLowerCase(),
                        value: value.toLowerCase(),
                      })) as SelectMenuItem[]
                    }
                  />
                  <SelectInputFieldCheckbox
                    id="working-days"
                    value={values.workingDays ? values.workingDays : []}
                    name="workingDays"
                    label="Working Days"
                    onChange={e => {
                      handleChange(e)
                    }}
                    multiple
                    menuItems={
                      Object.entries(LearningDaysEnum).map(([_, value]) => ({
                        name: value.toLowerCase(),
                        value,
                      })) as SelectMenuItem[]
                    }
                  />
                  <TextInputField
                    name="bankName"
                    id="bankName"
                    label="bankName"
                    value={values.bankName}
                    onChange={handleChange}
                  />
                  <TextInputField
                    name="accountNumber"
                    id="accountNumber"
                    label="accountNumber"
                    value={values.accountNumber}
                    onChange={handleChange}
                  />
                  <TextInputField
                    name="g12Result"
                    id="g12Result"
                    label="g12Result"
                    value={values.g12Result}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputField
                    name="university"
                    id="university"
                    label="university"
                    value={values.university}
                    onChange={handleChange}
                  />
                  <TextInputField
                    name="email"
                    id="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <SelectInputFieldCheckbox
                    id="gradeLevel"
                    name="gradeLevel"
                    value={values.gradeLevels}
                    label="Grade Level"
                    onChange={e => {
                      handleChange(e)
                    }}
                    multiple
                    menuItems={
                      GradeLevelList.map(value => ({
                        name: value.toLowerCase(),
                        value,
                      })) as SelectMenuItem[]
                    }
                  />
                  <SelectInputFieldCheckbox
                    id="service-type"
                    name="serviceType"
                    value={values.serviceTypes}
                    label="Service Type"
                    onChange={e => {
                      handleChange(e)
                    }}
                    multiple
                    menuItems={
                      Object.entries(ServiceTypeEnum).map(([_, value]) => ({
                        name: value.toLowerCase(),
                        value,
                      })) as SelectMenuItem[]
                    }
                  />
                  <TextInputField
                    name="education"
                    id="education"
                    label="Education"
                    value={values.education}
                    onChange={handleChange}
                  />

                  <SelectInputField
                    id="status"
                    value={values.status}
                    label="Status"
                    name="status"
                    onChange={handleChange}
                    menuItems={
                      Object.entries(UserStatusEnum).map(([_, value]) => ({
                        name: value.toLowerCase(),
                        value,
                      })) as SelectMenuItem[]
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenTutorEdit(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default UpdateTutorDialog
