import React, { useEffect, useState } from 'react'
import { useFindUserCount, useFindUsers } from './hooks/use-find-users.hook'
import GenericSearchPage from '../generic-search/generic-search.page'
import {
  UserGenderEnum,
  UserRoleEnum,
  UserRoleEnumSearch,
  UserStatusEnum,
} from './entities/user.enums'
import { useAuth } from '../../context/auth_context'
import { Box, Grid } from '@mui/material'
import UserListCard from './components/user-list-card'
import { User } from './entities/user.entity'
import CreateUser from './components/create-user'
import { PaginationInput } from '../../common/pagination/pagination'
import { useSearchPageState } from '../../context/search_page_context'

const SearchUsersPage: React.FC = () => {
  const loggedInUserRole = useAuth()?.authState?.role

  const pageState = useSearchPageState()

  const { findUser, loading, data, error } = useFindUsers()

  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    skip: 0,
    limit: 10,
  })

  const [searchNow, setSearchNow] = useState(false)

  const handleLoadUsers = () => {
    if (searchNow)
      findUser({
        currentSearchState: { ...pageState?.searchPageState.usersSearchState },
        paginationInput,
      }).then()
    else setSearchNow(true)
  }

  const { findCount, data: contractDataCount } = useFindUserCount()

  useEffect(() => {
    findCount()
      .then()
      .catch(e => e)
  }, [])

  return (
    <>
      {loggedInUserRole === 'SUPER_ADMIN' && <CreateUser refetch={handleLoadUsers} />}

      <GenericSearchPage
        data={data?.users}
        loading={loading}
        error={error}
        find={handleLoadUsers}
        countData={contractDataCount?.users}
        pageName="users"
        showSearch={true}
        statusArray={UserStatusEnum}
        searchEntityName="usersSearchState"
        currentState={pageState?.searchPageState.usersSearchState}
        paginationInput={paginationInput}
        setPaginationInput={setPaginationInput}
        searchFields={[
          { name: 'fullName', inputType: 'TextInputField' },
          { name: 'phoneNumber', inputType: 'TextInputField', type: 'tel' },
          { name: 'email', inputType: 'TextInputField' },
          {
            name: 'gender',
            inputType: 'SelectInputField',
            menuItems: Object.entries(
              loggedInUserRole === 'SUPER_ADMIN' ? UserGenderEnum : UserGenderEnum,
            ),
          },
          {
            name: 'role',
            inputType: 'SelectInputField',
            menuItems: Object.entries(
              loggedInUserRole === 'SUPER_ADMIN' ? UserRoleEnum : UserRoleEnumSearch,
            ),
          },
          {
            name: 'status',
            inputType: 'SelectInputField',
            menuItems: Object.entries(
              loggedInUserRole === 'SUPER_ADMIN' ? UserStatusEnum : UserStatusEnum,
            ),
          },
        ]}
      >
        <div id="ignore_fields">
          {/*You can add more custom fields like date manipulation objects*/}
        </div>

        <Grid container spacing={1} id="list_grid">
          {data &&
            data.users.items.map((user: User) => (
              <Grid item key={user.id} xs={12} md={6} lg={4}>
                <UserListCard user={user} refetch={handleLoadUsers} />
                <Box my={1} />
              </Grid>
            ))}
        </Grid>
      </GenericSearchPage>
    </>
  )
}

export default SearchUsersPage
