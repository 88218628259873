import React from 'react'
import { Box, Container, Drawer, Toolbar } from '@mui/material'
import Menus from './menus'
import AppBarCustom from './app-bar'

const drawerWidth = 240

interface NavigationProps {
  window?: () => Window
  // eslint-disable-next-line no-undef
  children: JSX.Element | JSX.Element[]
}

const Navigation: React.FC<NavigationProps> = ({ window, children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBarCustom handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Menus />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          <Menus />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          py: 2,
          width: '100%',
          backgroundColor: 'lightgrey',
          height: '100vh',
          overflowY: 'scroll',
        }}
      >
        <Toolbar />
        <Box display="flex">
          <Container
            maxWidth="xl"
            sx={{
              flexGrow: 1,
              padding: 0,
              overflow: 'hidden',
              height: '100%',
            }}
          >
            {children}
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default Navigation
