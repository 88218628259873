import { GenderType, StatusType, TutorStat } from '../analytics/dto/tutor-stat-filter'

const calculatePercentage = (item: number, total: number) => {
  return ((item / total) * 100).toFixed(1)
}

export const formatTutorStat = (data: TutorStat[], property: 'gender' | 'status') => {
  let total = 0
  total = (data[0]?.[property] as any).reduce((acc: any, prev: any) => acc + prev.count, 0)

  return data[0]?.[property]?.map((items: GenderType | StatusType) => {
    return {
      value: items.count,
      name: items._id[property as keyof typeof items._id],
      label: items._id[property as keyof typeof items._id],
      percentage: calculatePercentage(items.count, total) + ' %',
    }
  })
}
