import { gql, useMutation } from '@apollo/client'
import { Contract } from '../../contracts/entities/contract.entity'
import { UpdateContractInput } from '../dto/update-contract.inputs'

const UPDATE_CONTRACT = gql`
  mutation UpdateContractAdmin($updateContractInput: UpdateContractInput!) {
    updateContractAdmin(updateContractInput: $updateContractInput) {
      id
    }
  }
`

export function useUpdateContract() {
  const [updateContractGql, { loading, data, error }] = useMutation<
    { updateContractAdmin: Contract },
    { updateContractInput: UpdateContractInput }
  >(UPDATE_CONTRACT)

  function updateContract(updateContractInput: UpdateContractInput) {
    return updateContractGql({
      variables: { updateContractInput },
    })
  }

  return { updateContract, loading, data, error }
}

export function updateContractInitialState(contract: Contract): UpdateContractInput {
  return {
    contractId: contract.id,
    contractType: contract.contractType,
    tutorGender: contract.tutorGender,
    serviceType: contract.serviceType,
    startDate: contract.startDate,
    startTime: contract.startTime,
    endDate: contract.endDate,
    endTime: contract.endTime,
    signed: contract.signed,
    location: contract.location,
    customerType: contract.customerType,
    notes: contract.notes,
    additionalPhoneNumbers: contract.additionalPhoneNumbers,
  }
}
