import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import ContractsData from './components/contracts-data'
import ContractsByStatusData from './components/contracts-by-status-data'

const ContractDetailAnalytics: React.FC = () => {
  return (
    <Paper>
      <Box px={3} pt={5}>
        Contracts Data
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <ContractsByStatusData type="status" />
        </Grid>
        <Grid item xs={12}>
          <ContractsData />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContractDetailAnalytics
