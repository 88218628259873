import { gql, useMutation } from '@apollo/client'
import { TutorRating, TutorRatingUpdateInput } from '../entities/tutor-rating.entity'

const UPDATE_REVIEWS = gql`
  mutation UpdateTutorRating($updateTutorRatingInput: UpdateTutorRatingInput!) {
    updateTutorRating(updateTutorRatingInput: $updateTutorRatingInput) {
      id
    }
  }
`

export function useUpdateReviews() {
  const [updateReviewsGql, { loading, data, error }] = useMutation<
    { updateTutorRating: TutorRating },
    { updateTutorRatingInput: TutorRatingUpdateInput }
  >(UPDATE_REVIEWS)

  function updateReviews(updateTutorRatingInput: TutorRatingUpdateInput) {
    return updateReviewsGql({
      variables: { updateTutorRatingInput },
    })
  }

  return { updateReviews, loading, data, error }
}
