import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { Contract } from '../../contracts/entities/contract.entity'
import { ContractTypeEnum } from '../../contracts/entities/contract.enums'
import CreateChildDialog from './create-child-dialog'
import pic from '../../../assets/img/pic.webp'
import BirrInfoCard from '../../components/cards/birr-info-card'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import UpdateContractStatus from '../../contracts/components/update-contract-status'
import { Info } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import UpdateContractDialog from './update-contract-dialog'
import { useRemoveTutor } from '../../tutors/hooks/use-assign-tutor.hook'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import ChildCard from './child-card'
import ImageModal from '../../payments/components/image-modal'
import GiveRatingDialog from './give-rating-dialog'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import Dialog from '@mui/material/Dialog'
import AddNoteCard from '../../notes/components/add-note-card'
import CancellationReasonCard from '../../search_contracts/components/cancellation-reason-card'

const ReAssignReasons = [
  'inconvenient access to transportation',
  'conflicting schedule',
  'tutor health condition',
  'tutor relocated',
  'tutor no longer working',
  'request did not match',
  'disagreement with parent',
  'did not contact the customer',
  'tutor did not respond',
  'test',
]

interface ContractCardProps {
  contract: Contract

  refetch(): void
}

interface PageDialogs {
  openContractEdit: boolean
  openGiveRating: boolean
  openCreateChild: boolean
  contractImageOpen: boolean
  selectedContractImage: string
  removeDialog: boolean
  openAddNote: boolean
}

const ContractSection: React.FC<ContractCardProps> = ({ contract, refetch }) => {
  const {
    removeTutor,
    loading: removeTutorLoading,
    error: removeTutorError,
  } = useRemoveTutor()

  const [expanded, setExpanded] = useState<string | false>(false)

  const [removeReason, setRemoveReason] = useState('')

  const [openDialogs, setOpenDialogs] = useState<PageDialogs>({
    openContractEdit: false,
    openAddNote: false,
    contractImageOpen: false,
    openCreateChild: false,
    openGiveRating: false,
    removeDialog: false,
    selectedContractImage: '',
  })

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <>
      {removeTutorLoading && <CircularIndeterminate />}
      {removeTutorError && <Alert severity="error">{removeTutorError.message}</Alert>}

      {contract.tutor && (
        <GiveRatingDialog
          openGiveRating={openDialogs.openGiveRating}
          tutorId={contract.tutor.id}
          customerId={contract.customer.id}
          contractId={contract.id}
          setOpenGiveRating={openGiveRating =>
            setOpenDialogs(prevState => ({
              ...prevState,
              openGiveRating,
            }))
          }
          refetch={refetch}
        />
      )}

      <Paper elevation={1}>
        <Box pt={1} px={1} pb={2} mb={2}>
          <Box mt={1} mb={2} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              size="small"
              endIcon={<AddCircleIcon />}
              onClick={() =>
                setOpenDialogs(prevState => ({
                  ...prevState,
                  openCreateChild: true,
                }))
              }
            >
              Add child
            </Button>

            <Box display="flex">
              <Box>
                <Button
                  onClick={() =>
                    setOpenDialogs(prevState => ({
                      ...prevState,
                      openGiveRating: true,
                    }))
                  }
                >
                  Give Rating
                </Button>
              </Box>
              <Tooltip title="Edit contract">
                <IconButton
                  onClick={() =>
                    setOpenDialogs(prevState => ({
                      ...prevState,
                      openContractEdit: !prevState.openContractEdit,
                    }))
                  }
                >
                  <EditIcon color="action" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <Box>
                    <Typography>{contract.creation} Contract</Typography>
                    {contract.createdBy && (
                      <Typography>Created by: {contract.createdBy.fullName}</Typography>
                    )}
                  </Box>
                }
              >
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box my={1}>
            <AddNoteCard contract={contract} refetch={refetch} />
          </Box>

          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <BirrInfoCard
                  title="Unpaid"
                  amount={contract.unpaidPayment.toString()}
                  label="Birr"
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <BirrInfoCard
                  title="Total payment"
                  amount={contract.totalPaymentParent.toString()}
                  label="Birr"
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <BirrInfoCard
                  title="Tutor Hours"
                  amount={contract.totalHours.toString()}
                  label="Hrs"
                />
              </Grid>
            </Grid>
          </Box>

          {contract.children?.map(child => (
            <ChildCard key={child.id} child={child} refetch={refetch} contract={contract} />
          ))}

          <Box px={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Box display="flex" my={1}>
                  {contract.signed ? (
                    <Box>
                      <Typography fontWeight="bold" color="green">
                        CONTRACT SIGNED
                      </Typography>
                      {contract.contractPaperImages &&
                        contract.contractPaperImages.map(i => (
                          <Grid key={i} item xs={12}>
                            <ImageModal
                              onClick={() => {
                                setOpenDialogs(prevState => ({
                                  ...prevState,
                                  contractImageOpen: true,
                                  selectedContractImage: i,
                                }))
                              }}
                              open={openDialogs.contractImageOpen}
                              onClose={() =>
                                setOpenDialogs(prevState => ({
                                  ...prevState,
                                  contractImageOpen: false,
                                }))
                              }
                              src={openDialogs.selectedContractImage}
                              text={'Contract Image'}
                            />
                          </Grid>
                        ))}
                    </Box>
                  ) : (
                    <Typography fontWeight="bold" color="palevioletred">
                      CONTRACT NOT SIGNED!
                    </Typography>
                  )}
                </Box>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Status</Typography>
                  <Box mx={1}>:</Box>
                  <UpdateContractStatus contract={contract} refetch={refetch} />
                </Box>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Tutor Gender</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{contract.tutorGender}</Typography>
                </Box>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Contract Type</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{contract.contractType}</Typography>
                </Box>
                {contract.contractType === ContractTypeEnum.FIXED && (
                  <Box display="flex" my={1}>
                    <Typography fontWeight="bold">Rate</Typography>
                    <Box mx={1}>:</Box>
                    {/*<Typography>{contract.parentRate}</Typography>*/}
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Service Type</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{contract.serviceType}</Typography>
                </Box>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Time</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {contract.startTime} - {contract.endTime}
                  </Typography>
                </Box>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Start Date</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {new Date(contract.startDate).toISOString().split('T')[0]} {' - '}
                    {new Date(contract.endDate).toISOString().split('T')[0]}
                  </Typography>
                </Box>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Date Created</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {new Date(contract.createdAt).toISOString().split('T')[0]}
                  </Typography>
                </Box>
                <Box display="flex" my={1}>
                  <Typography fontWeight="bold">Location</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{contract.location?.streetName}</Typography>
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" my={1}>
              <Typography fontWeight="bold">Tutor</Typography>
              <Box mx={5}>:</Box>
              {contract.tutor ? (
                <Card>
                  <Box display="flex" p={1}>
                    <Avatar
                      alt="profile_pic"
                      src={contract.tutor.profilePic ? contract.tutor.profilePic : pic}
                      sx={{ width: 36, height: 36 }}
                    />
                    <Box ml={1}>
                      <Box display="flex" justifyContent="space-between">
                        <Link
                          to={`/tutor/${contract.tutor.id}`}
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          <Typography fontWeight="bold" pr={2}>
                            {`${contract.tutor.fullName}`}
                          </Typography>
                        </Link>
                        {contract.unpaidPayment === 0 && contract.totalHours === 0 && (
                          <IconButton
                            onClick={() =>
                              contract.tutor &&
                              setOpenDialogs(prevState => ({
                                ...prevState,
                                removeDialog: true,
                              }))
                            }
                          >
                            <DeleteIcon color="error" fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                      <Typography fontSize={12}>{`${contract.tutor?.phoneNumber}`}</Typography>
                      <Typography fontSize={12}>{`${contract.tutor?.email}`}</Typography>
                    </Box>
                  </Box>
                </Card>
              ) : (
                'Tutor Not Assigned Yet!'
              )}
            </Box>

            <Box my={1}>
              {contract.contractType === ContractTypeEnum.COMMISSION && (
                <Box>
                  <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography>{contract.contractType}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        <Typography>
                          {contract.commission.active ? 'ACTIVE' : 'OUTDATED'}
                        </Typography>
                        <Typography fontWeight="bold">Ranges</Typography>
                        {contract.commission.paymentRanges.map(r => (
                          <Box key={r.from}>
                            <span>
                              {r.from} {` - `} {r.to} = {r.percent}%
                            </span>{' '}
                            <br />
                          </Box>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            </Box>

            <Box my={1}>
              <CancellationReasonCard contract={contract} />
            </Box>
          </Box>
        </Box>
      </Paper>

      <Dialog open={openDialogs.removeDialog}>
        <Box px={3} py={2}>
          <Typography>Are you sure you want to remove tutor?</Typography>
          <form
            onSubmit={e => {
              e.preventDefault()
              removeTutor(contract.id, removeReason).then(() => refetch())
              setOpenDialogs(prevState => ({ ...prevState, removeDialog: false }))
            }}
          >
            <Box px={3} py={2}>
              <SelectInputField
                id="removeReason"
                value={removeReason}
                label="Reason for removing"
                name="removeReason"
                required={true}
                onChange={e => setRemoveReason(e.target.value)}
                menuItems={
                  ReAssignReasons.map(value => ({
                    name: value.toLowerCase(),
                    value,
                  })) as SelectMenuItem[]
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                type="button"
                onClick={() =>
                  setOpenDialogs(prevState => ({ ...prevState, removeDialog: false }))
                }
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <CreateChildDialog
        contract={contract}
        refetch={refetch}
        openCreateChild={openDialogs.openCreateChild}
        setOpenCreateChild={openCreateChild =>
          setOpenDialogs(prevState => ({ ...prevState, openCreateChild }))
        }
      />
      <UpdateContractDialog
        contract={contract}
        openContractEdit={openDialogs.openContractEdit}
        setOpenContractEdit={openContractEdit =>
          setOpenDialogs(prevState => ({ ...prevState, openContractEdit }))
        }
        refetch={refetch}
      />
    </>
  )
}

export default ContractSection
