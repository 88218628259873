import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { Alert, Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import {
  UserGenderEnum,
  UserRoleEnum,
  UserRoleEnumSearch,
  UserStatusEnum,
} from '../entities/user.enums'
import { UpdateUserInput } from '../dto/user-update-input'
import Dialog from '@mui/material/Dialog'
import { GridCloseIcon } from '@mui/x-data-grid'
import { User } from '../entities/user.entity'
import TextInputField from '../../components/inputs/text-input-field'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { useUpdateUser } from '../hooks/use-update-user.hook'
import { useResetUsersPassword } from '../hooks/use-reset-password.hook'
import { useAuth } from '../../../context/auth_context'

interface UpdateUserDialogProps {
  open: boolean
  user: User

  handleClose(open: boolean): void

  refetch(): void
}

const UpdateUserDialog: React.FC<UpdateUserDialogProps> = ({
  open,
  user,
  handleClose,
  refetch,
}) => {
  const role = useAuth()?.authState?.role

  const [openDelete, setOpenDelete] = useState(false)

  const { updateUser, loading, error } = useUpdateUser()
  const {
    resetUsersPassword,
    loading: resetPasswordLoading,
    error: resetPasswordError,
  } = useResetUsersPassword()

  const handleSubmit = (values: UpdateUserInput) => {
    updateUser({
      userId: user.id,
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
      gender: values.gender,
      status: values.status,
      role: values.role,
      email: values.email,
    }).then(() => refetch())
    handleClose(false)
  }

  return (
    <Formik
      initialValues={user}
      onSubmit={(values: any, { setSubmitting }) => {
        setSubmitting(false)
        handleSubmit(values as UpdateUserInput)
      }}
    >
      {({ values, handleChange, isSubmitting }) => (
        <Dialog open={open}>
          {loading && <CircularIndeterminate />}
          {resetPasswordLoading && <CircularIndeterminate />}
          <Form id="update-user-form">
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Edit User</Typography>
                  <IconButton onClick={() => handleClose(false)}>
                    <GridCloseIcon fontSize="small" />
                  </IconButton>
                </Box>

                <TextInputField
                  id="full-name"
                  name="fullName"
                  label="Full Name"
                  value={values.fullName}
                  onChange={handleChange}
                />

                <TextInputField
                  id="phone-number"
                  name="phoneNumber"
                  label="Phone Number"
                  value={values.phoneNumber}
                  onChange={handleChange}
                />

                <TextInputField
                  id="email"
                  name="email"
                  label="Email"
                  required={false}
                  value={values.email}
                  onChange={handleChange}
                />

                <SelectInputField
                  id="gender"
                  value={values.gender}
                  label="Gender"
                  name="gender"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(UserGenderEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />

                <SelectInputField
                  id="status"
                  value={values.status}
                  label="Status"
                  name="status"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(UserStatusEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />

                <SelectInputField
                  id="role"
                  value={values.role}
                  label="Role"
                  name="role"
                  onChange={e => handleChange(e)}
                  required={false}
                  menuItems={
                    Object.entries(
                      role === 'SUPER_ADMIN' ? UserRoleEnum : UserRoleEnumSearch,
                    ).map(([_, value]) => ({
                      name: value?.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />

                <Box display="flex" pt={2}>
                  <Button variant="outlined" onClick={() => setOpenDelete(true)}>
                    Reset Password
                  </Button>

                  <Dialog open={openDelete}>
                    <Box p={2}>
                      <Typography>Are you sure you want to reset password?</Typography>
                      <Box my={1} />
                      {resetPasswordError && (
                        <Alert severity="error">{resetPasswordError.message}</Alert>
                      )}
                      <Box my={1} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => setOpenDelete(false)}>No</Button>
                        <Button
                          onClick={() => {
                            resetUsersPassword(user.id).then(() => setOpenDelete(false))
                          }}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Dialog>
                </Box>

                <Box display="flex" justifyContent="space-between" mt={3}>
                  <Button color="secondary" onClick={() => handleClose(false)} autoFocus>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </Box>

                {error && <Alert severity="error">{error.message}</Alert>}
              </CardContent>
            </Card>
          </Form>
        </Dialog>
      )}
    </Formik>
  )
}

export default UpdateUserDialog
