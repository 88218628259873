import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import { SortPaymentInput } from '../dto/sort-payment.input'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface SearchFiltersProps {
  initialState: SortPaymentInput

  sortPayment(sortState: SortPaymentInput): void
}

const SortPaymentCard: React.FC<SearchFiltersProps> = ({ sortPayment, initialState }) => {
  const handleSubmit = (values: SortPaymentInput) => {
    sortPayment(values)
  }

  const [sortObj, setSortObj] = useState<SortPaymentInput>({
    createdAt: 0,
    fullName: 0,
    totalDepositedParent: 0,
    totalDepositedTutor: 0,
    totalHours: 0,
    payableCommission: 0,
    commission: 0,
    netCommission: 0,
    tot: 0,
    incomeTax: 0,
    netTutorPayment: 0,
  })

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box py={1}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="h6">Sort</Typography>
            </FormLabel>
          </FormControl>
        </AccordionSummary>
        <AccordionDetails>
          <Card elevation={0}>
            <CardContent>
              <Formik
                initialValues={initialState}
                onSubmit={(_, { setSubmitting }) => {
                  const newobj: any = {}
                  setSubmitting(false)
                  Object.entries(sortObj).forEach(([key, values]) => {
                    if (values !== 0) {
                      newobj[key] = values
                    }
                  })
                  handleSubmit(newobj as SortPaymentInput)
                }}
              >
                {({ values, handleChange, isSubmitting }) => (
                  <Form>
                    {Object.entries(sortObj).map(([key, value]) => (
                      <Grid container key={key}>
                        <Grid item xs={12} md={3}>
                          <Typography>{key} </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              // defaultValue={`${sortObj}`}
                              name={key}
                              value={value}
                              onChange={e => {
                                setSortObj(prev => ({
                                  ...prev,
                                  [key]: parseInt(e.target.value, 10),
                                }))
                              }}
                            >
                              <FormControlLabel value="0" control={<Radio />} label="None" />
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Ascending"
                              />
                              <FormControlLabel
                                value="-1"
                                control={<Radio />}
                                label="Descending"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ))}

                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                      >
                        Sort
                      </Button>
                    </FormControl>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default SortPaymentCard
