import { gql, useMutation } from '@apollo/client'
import { Contract } from '../../contracts/entities/contract.entity'
import { CreateContractChildInputAdmin } from '../../contracts/entities/child.entity'

const CREATE_CHILD = gql`
  mutation CreateChildren($createContractChildInputAdmin: CreateContractChildInputAdmin!) {
    createChildren(createContractChildInputAdmin: $createContractChildInputAdmin) {
      id
    }
  }
`

export function useCreateChild() {
  const [createChildGql, { loading, data, error }] = useMutation<
    { createChildren: Contract },
    { createContractChildInputAdmin: CreateContractChildInputAdmin }
  >(CREATE_CHILD)

  function createChild(createContractChildInputAdmin: CreateContractChildInputAdmin) {
    return createChildGql({
      variables: { createContractChildInputAdmin },
    })
  }

  return { createChild, loading, data, error }
}
