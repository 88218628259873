import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  FetchResult,
  GraphQLRequest,
  InMemoryCache,
  Observable,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { refreshTokens } from './refresh-tokens'

function isRefreshRequest(operation: GraphQLRequest) {
  return operation.operationName === 'refreshToken'
}

function returnTokenDependingOnOperation(operation: GraphQLRequest) {
  const auth =
    localStorage.getItem('authState') && JSON.parse(localStorage.getItem('authState') || '')
  if (isRefreshRequest(operation)) return auth?.refreshToken || ''
  else return auth?.accessToken || ''
}

export const httpLink = createHttpLink({
  // uri: 'http://localhost:8000/graphql',
  uri: 'https://api.haletatutors.com/graphql',
  // uri: process.env.REACT_APP_API_URL,
})

const authLink = setContext((operation, { headers }) => {
  let token = returnTokenDependingOnOperation(operation)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions.code === 'UNAUTHENTICATED' || err.extensions.code === 'FORBIDDEN') {
        // ignore 401 error for a refresh request
        if (operation.operationName === 'refreshToken') return
        return new Observable<FetchResult<Record<string, any>>>(observer => {
          ;(async () => {
            try {
              await refreshTokens()

              // Retry the failed request
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }

              forward(operation).subscribe(subscriber)
            } catch (err) {
              observer.error(err)
            }
          })()
        })
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})
