import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#eecb76',
      main: '#DAA520',
      dark: '#98710d',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgb(37 50 56)',
      main: 'rgb(37 50 56)',
      dark: '#1a5481',
      contrastText: '#000',
    },
  },
})

export default theme
