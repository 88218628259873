import React from 'react'
import { Alert, Box, Button } from '@mui/material'
import TextInputField from '../components/inputs/text-input-field'
import { UserGenderEnum, UserStatusEnum } from '../users/entities/user.enums'
import SelectInputField, { SelectMenuItem } from '../components/inputs/select-input-field'
import { CustomerTypeEnum } from './entity/customer.enums'
import Dialog from '@mui/material/Dialog'
import { Form, Formik } from 'formik'
import { Customer } from './entity/customer.entity'
import { useUpdateCustomer } from './hooks/use-update-customer'
import { CircularIndeterminate } from '../components/preloader/preloader'
import { UserLocationInput } from '../../common/location/location.entity'
import { CreateCustomerInput } from './dto/customer-create.input'
import PlaceDetailSelector from '../components/google_places/place-detail-selector'

interface UpdateCustomerDialogProps {
  open: boolean
  customer: Customer

  handleClose?(): void

  refetch?(): void
}

const UpdateCustomerDialog: React.FC<UpdateCustomerDialogProps> = ({
  open,
  handleClose,
  customer,
  refetch,
}) => {
  const { updateCustomer, loading, error } = useUpdateCustomer()

  const [userLocation, setUserLocation] = React.useState<UserLocationInput>(customer.location)

  const initialState = {
    firstName: customer.fullName.split(' ')[0],
    middleName: customer.fullName.split(' ').length > 1 ? customer.fullName.split(' ')[1] : '',
    lastName: customer.fullName.split(' ').length > 2 ? customer.fullName.split(' ')[2] : '',
    phoneNumber: customer.phoneNumber,
    location: customer.location,
    status: customer.status,
    gender: customer.gender,
    customerType: customer.customerType,
  }

  const handleConfirm = (values: CreateCustomerInput) => {
    updateCustomer({
      customerId: customer.id,
      fullName: `${values.firstName} ${values.middleName} ${values.lastName}`,
      status: values.status,
      customerType: values.customerType,
      phoneNumber: values.phoneNumber,
      gender: values.gender,
      // @ts-ignore
      location: { ...userLocation, __typename: undefined },
    })
      .then(() => {
        if (refetch) {
          refetch()
        }
        if (handleClose) {
          handleClose()
        }
      })
      .catch(e => e)
  }

  return (
    <Dialog open={open}>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}

      <Box p={2}>
        <Formik
          initialValues={initialState}
          onSubmit={(values: any, { setSubmitting }) => {
            setSubmitting(false)
            handleConfirm(values as CreateCustomerInput)
          }}
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form>
              <>
                <TextInputField
                  name="firstName"
                  id="first-name"
                  label="first-name"
                  value={values.firstName}
                  onChange={handleChange}
                />
                <TextInputField
                  name="middleName"
                  id="middle-name"
                  label="middle-name"
                  value={values.middleName}
                  onChange={handleChange}
                  required={false}
                />
                <TextInputField
                  name="lastName"
                  id="last-name"
                  label="last-name"
                  value={values.lastName}
                  onChange={handleChange}
                  required={false}
                />
                <TextInputField
                  name="phoneNumber"
                  id="Phone number"
                  label="Phone number"
                  value={values.phoneNumber}
                  onChange={handleChange}
                />

                <Box py={1}>
                  <SelectInputField
                    id="gender"
                    value={values.gender}
                    label="Gender"
                    name="gender"
                    onChange={handleChange}
                    menuItems={
                      Object.entries(UserGenderEnum).map(([_, value]) => ({
                        name: value.toLowerCase(),
                        value,
                      })) as SelectMenuItem[]
                    }
                  />
                </Box>

                <PlaceDetailSelector
                  setUserLocation={setUserLocation}
                  userLocation={userLocation}
                />

                <SelectInputField
                  id="status"
                  value={values.status}
                  label="Status"
                  name="status"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(UserStatusEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
                <SelectInputField
                  id="customerType"
                  value={values.customerType}
                  name="customerType"
                  label="Customer Type"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(CustomerTypeEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />

                <Box display="flex" justifyContent="flex-end" pt={2}>
                  <Box display="flex" justifyContent="space-between">
                    <Button color="secondary" onClick={handleClose} autoFocus>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Box>
                </Box>
              </>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  )
}

export default UpdateCustomerDialog
