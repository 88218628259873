import { gql, useMutation } from '@apollo/client'
import { TutorApplicationStatusEnum } from '../entities/tutor_prospects.enum'
import { Note } from '../../notes/entity/notes.entity'

const UPDATE_PROSPECT_TUTORS = gql`
  mutation UpdateTutorProspect($updateTutorProspectInput: UpdateTutorProspectInput!) {
    updateTutorProspect(updateTutorProspectInput: $updateTutorProspectInput) {
      id
    }
  }
`

export function useUpdateProspectTutor() {
  const [updateProspectTutorGql, { loading, data, error }] = useMutation<
    { updateTutorProspect: { id: string } },
    {
      updateTutorProspectInput: {
        tutorProspectId: string
        applicationStatus?: TutorApplicationStatusEnum
        secondRoundExpiresAt?: Date
        notes?: Note[]
      }
    }
  >(UPDATE_PROSPECT_TUTORS)

  function updateProspectTutor(updateTutorProspectInput: {
    tutorProspectId: string
    applicationStatus?: TutorApplicationStatusEnum
    secondRoundExpiresAt?: Date
    notes?: Note[]
  }) {
    return updateProspectTutorGql({
      variables: { updateTutorProspectInput },
    })
  }

  return { updateProspectTutor, loading, data, error }
}
