import { gql, useLazyQuery } from '@apollo/client'
import { User, userStringFull } from '../entities/user.entity'
import { FindUsersInput } from '../dto/find-users.input'
import { Paginated, PaginationInput } from '../../../common/pagination/pagination'
import { UserGenderEnum, UserRoleEnum, UserStatusEnum } from '../entities/user.enums'

const FIND_USERS = gql`
  query Users($findUsersInput: FindUsersInput, $paginationInput: PaginationInput) {
    users(findUsersInput: $findUsersInput, paginationInput: $paginationInput) {
      items {
        ${userStringFull}
      }
      pageInfo {
        count
        limit
        skip
      }
    }
  }
`

const FIND_USERS_COUNT = gql`
  query Users($findUsersInput: FindUsersInput, $paginationInput: PaginationInput) {
    users(findUsersInput: $findUsersInput, paginationInput: $paginationInput) {
      items {
        id
        status
      }
      pageInfo {
        count
        limit
        skip
      }
    }
  }
`

export function useFindUsers() {
  const [findUsersGql, { loading, error, data }] = useLazyQuery<
    { users: Paginated<User> },
    { paginationInput: PaginationInput; findUsersInput: FindUsersInput }
  >(FIND_USERS)

  function findUser({
    currentSearchState,
    paginationInput,
  }: {
    currentSearchState?: any
    paginationInput: PaginationInput
  }) {
    return findUsersGql({
      variables: {
        findUsersInput: userSearchInput(currentSearchState),
        paginationInput,
      },
    })
  }

  return { findUser, loading, error, data }
}

export function useFindUserCount() {
  const [findCountGql, { loading, data, error }] = useLazyQuery<
    { users: Paginated<User> },
    { paginationInput: PaginationInput; findUsersInput: FindUsersInput }
  >(FIND_USERS_COUNT)

  function findCount() {
    return findCountGql({
      variables: {
        findUsersInput: {},
        paginationInput: {
          skip: 0,
          limit: 10000,
        },
      },
    })
  }

  return { findCount, loading, data, error }
}

export const userSearchInput = (currentSearchState: any) => ({
  fullName:
    currentSearchState?.fullName && currentSearchState?.fullName !== ''
      ? {
          regex: currentSearchState?.fullName,
        }
      : undefined,
  phoneNumber:
    currentSearchState?.phoneNumber && currentSearchState?.phoneNumber !== ''
      ? `+251${(currentSearchState.phoneNumber as string).slice(-9)}`
      : undefined,
  email:
    currentSearchState?.email !== '' && currentSearchState?.email
      ? currentSearchState?.email
      : undefined,
  role:
    currentSearchState?.role !== '' && currentSearchState?.role
      ? currentSearchState?.role
      : undefined,
  status:
    currentSearchState?.status !== UserStatusEnum.NONE
      ? currentSearchState?.status
      : undefined,
  gender: currentSearchState?.gender
    ? currentSearchState?.gender === 'BOTH' ||
      currentSearchState?.gender === UserGenderEnum.NONE
      ? undefined
      : currentSearchState?.gender
    : undefined,
})

export const initialUserSearchState: FindUsersInput = {
  gender: UserGenderEnum.NONE,
  status: UserStatusEnum.ACTIVE,
  role: UserRoleEnum.NORMAL,
  createdAt: undefined,
  sort: {
    createdAt: -1,
  },
}
