import { gql, useLazyQuery } from '@apollo/client'
import { Paginated, PaginationInput } from '../../../common/pagination/pagination'
import { Tutor, tutorStringFull } from '../../tutors/entities/tutor.entity'
import {
  FindTutorsDateInputs,
  FindTutorsInput,
  FindTutorsNumberInputs,
} from '../dto/find-tutors.input'
import { SortTutorsInput } from '../../tutors/dto/sort-tutors.input'
import { UserGenderEnum, UserStatusEnum } from '../../users/entities/user.enums'

export function useFindTutors() {
  const [findTutorsGql, { loading, data, error }] = useLazyQuery<
    { searchTutorsAdmin: Paginated<Tutor> },
    {
      pageInfoInput: PaginationInput
      findTutorsInput: FindTutorsInput
      sortTutorsInput: SortTutorsInput
    }
  >(FIND_TUTORS)

  function findTutors({
    currentSearchState,
    currentSortState,
    paginationInput,
    dateStates,
    numberStates,
  }: {
    currentSearchState: FindTutorsInput
    currentSortState: SortTutorsInput
    paginationInput: PaginationInput
    dateStates?: FindTutorsDateInputs
    numberStates?: FindTutorsNumberInputs
  }) {
    return findTutorsGql({
      variables: {
        pageInfoInput: paginationInput,
        sortTutorsInput: { ...currentSortState },
        findTutorsInput: tutorsSearchInput(currentSearchState, dateStates, numberStates),
      },
    })
  }

  return { findTutors, loading, data, error }
}

export const tutorsSearchInput = (
  search: FindTutorsInput,
  dateStates?: FindTutorsDateInputs,
  numberStates?: FindTutorsNumberInputs,
) => ({
  ...search,
  fullName: search?.fullName
    ? {
        regex: search?.fullName as string,
      }
    : undefined,
  gender:
    search?.gender &&
    (search?.gender === UserGenderEnum.BOTH || search?.gender === 'None'
      ? undefined
      : search?.gender),
  serviceTypes:
    search?.serviceTypes && search?.serviceTypes.length > 0 ? search?.serviceTypes : undefined,
  subjects:
    search?.subjects && search?.subjects.length > 0
      ? search.subjects.filter(s => s === 'all').length > 0
        ? undefined
        : search?.subjects.map(s => s.toLowerCase())
      : undefined,
  gradeLevels:
    search?.gradeLevels && search?.gradeLevels.length > 0 ? search.gradeLevels : undefined,
  workingDays:
    search?.workingDays && search?.workingDays.length > 0 ? search?.workingDays : undefined,
  ...dateStates,
  ...numberStates,
})

export const findTutorInitialState: FindTutorsInput = {
  id: undefined,
  fullName: undefined,
  email: undefined,
  phoneNumber: undefined,
  status: UserStatusEnum.ACTIVE,
  education: undefined,
  g12Result: undefined,
  university: undefined,
  workStatus: undefined,
  serviceTypes: [],
  subjects: [],
  gradeLevels: [],
  workingDays: [],
  location: {
    country: 'ethiopia',
    city: 'addis ababa',
    coordinates: [],
    description: '',
    district: '',
    placeId: '',
    streetName: '',
    subCity: '',
    zipCode: '',
  },
  gender: undefined,
  createdAt: undefined,
  clientsWorkedWith: {
    gte: 0,
    lte: 50,
  },
  currentClients: {
    gte: 0,
    lte: 10,
  },
  // totalHoursWorked?: NumberRange
  // totalAmountEarned?: NumberRange
  // rating?: NumberRange
  // createdAt?: DateRange
}

export function useFindTutorsCount() {
  const [findCountGql, { loading, data, error }] = useLazyQuery<
    { searchTutorsAdmin: Paginated<Tutor> },
    {
      pageInfoInput: PaginationInput
      findTutorsInput: FindTutorsInput
    }
  >(TUTORS_COUNT, { fetchPolicy: 'cache-first' })

  function findCount() {
    return findCountGql({
      variables: {
        findTutorsInput: {},
        pageInfoInput: {
          skip: 0,
          limit: 10000,
        },
      },
    })
  }

  return { findCount, loading, data, error }
}

export const findInitSortState: SortTutorsInput = {
  fullName: undefined,
  clientsWorkedWith: undefined,
  currentClients: undefined,
  totalHoursWorked: undefined,
  totalAmountEarned: undefined,
  rating: undefined,
  createdAt: -1,
}

const FIND_TUTORS = gql`
  query Items(
    $findTutorsInput: FindTutorsInput
    $sortTutorsInput: SortTutorsInput
    $pageInfoInput: PaginationInput
  ) {
    searchTutorsAdmin(
      findTutorsInput: $findTutorsInput
      sortTutorsInput: $sortTutorsInput
      pageInfoInput: $pageInfoInput
    ) {
      items {
        ${tutorStringFull}
      }
      pageInfo {
        count
        limit
        skip
      }
    }
  }
`

const TUTORS_COUNT = gql`
  query Items($findTutorsInput: FindTutorsInput, $pageInfoInput: PaginationInput) {
    searchTutorsAdmin(findTutorsInput: $findTutorsInput, pageInfoInput: $pageInfoInput) {
      items {
        id
        status
      }
      pageInfo {
        count
        limit
        skip
      }
    }
  }
`
