import { gql, useMutation } from '@apollo/client'
import { User } from '../entities/user.entity'

const RESET_USERS_PASSWORD = gql`
  mutation AdminResetPassword($userId: String!) {
    adminResetPassword(userId: $userId) {
      id
    }
  }
`

export function useResetUsersPassword() {
  const [resetUsersPasswordGql, { loading, data, error }] = useMutation<
    { adminResetPassword: User },
    { userId: string }
  >(RESET_USERS_PASSWORD)

  function resetUsersPassword(userId: string) {
    return resetUsersPasswordGql({
      variables: { userId },
    })
  }

  return { resetUsersPassword, loading, data, error }
}
