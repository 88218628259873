import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material'
import { Payment } from '../entities/payment.entity'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DailyLogCard from './daily-log-card'
import { DailyLogStatusEnum, PaymentStatusEnum } from '../entities/payment.enums'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import UpdatePaymentStatus from './update-payment-status'
import { Contract } from '../../contracts/entities/contract.entity'
import { useUpdateDailyLog } from '../hook/use-update-daily-log.hook'
import EditIcon from '@mui/icons-material/Edit'
import UpdatePaymentDialog from './update-payment-dialog'
import AddNoteCard from '../../notes/components/add-note-card'
import NoteCard from '../../contract_detail/components/note-card'

interface PaymentCardProps {
  payment: Payment
  contract: Contract

  refetch(): void
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  p: 4,
}

const PaymentCard: React.FC<PaymentCardProps> = ({ payment, contract, refetch }) => {
  const [open, setOpen] = React.useState(false)
  const [image, setImage] = React.useState('')
  const [openPaymentCardEdit, setOpenPaymentCardEdit] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)

  const [openAddNote, setOpenAddNote] = useState(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const { updateDailyLog, loading, error } = useUpdateDailyLog()

  return (
    <>
      <Card>
        {loading && <CircularIndeterminate />}
        {error && <Alert severity="error">{error.message} </Alert>}

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img style={{ width: '100%' }} src={image} alt="" />
          </Box>
        </Modal>

        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" fontWeight="bold">
              {payment.month}
            </Typography>
            <Tooltip title="Edit contract">
              <IconButton onClick={() => setOpenPaymentCardEdit(!openPaymentCardEdit)}>
                <EditIcon color="action" />
              </IconButton>
            </Tooltip>

            <Box display="flex">
              <UpdatePaymentStatus payment={payment} refetch={refetch} />
            </Box>
          </Box>

          <Grid container my={1} spacing={1}>
            <Grid item xs={6}>
              <Box display="flex">
                <Typography noWrap fontWeight="bold">
                  Parent's Total payment
                </Typography>
                <Box mx={0.5}>:</Box>
                <Typography>{payment.totalDepositedParent}</Typography>
              </Box>
              <Box display="flex">
                <Typography noWrap fontWeight="bold">
                  Tutor's Total payment
                </Typography>
                <Box mx={0.5}>:</Box>
                <Typography>{payment.totalDepositedTutor}</Typography>
              </Box>
              <Box display="flex">
                <Typography noWrap fontWeight="bold">
                  Total hours
                </Typography>
                <Box mx={1}>:</Box>
                <Typography>{payment.totalHours}</Typography>
              </Box>
              <Box display="flex">
                <Typography noWrap fontWeight="bold">
                  Net tutor payment
                </Typography>
                <Typography mx={1}>:</Typography>
                <Typography>{payment.netTutorPayment}</Typography>
              </Box>
              <Box display="flex">
                <Typography fontWeight="bold" noWrap>
                  Start date
                </Typography>
                <Typography mx={1}>:</Typography>
                <Typography noWrap>
                  {payment?.startDate &&
                    new Date(payment.startDate).toISOString().split('T')[0]}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Typography fontWeight="bold" noWrap>
                  Net commission
                </Typography>
                <Typography mx={1}>:</Typography>
                <Typography>{payment.netCommission}</Typography>
              </Box>
              <Box display="flex">
                <Typography fontWeight="bold" noWrap>
                  Payable commission by tutor
                </Typography>
                <Typography mx={1}>:</Typography>
                <Typography>{payment.payableCommission}</Typography>
              </Box>
              <Box display="flex">
                <Typography fontWeight="bold" noWrap>
                  Total commission
                </Typography>
                <Typography mx={1}>:</Typography>
                <Typography>{payment.commission}</Typography>
              </Box>
              <Box display="flex">
                <Typography fontWeight="bold" noWrap>
                  TOT
                </Typography>
                <Typography mx={1}>:</Typography>
                <Typography>{payment.tot}</Typography>
              </Box>
              <Box display="flex">
                <Typography fontWeight="bold">Expected End date</Typography>
                <Typography mx={1}>:</Typography>
                <Typography>
                  {payment?.endDate && new Date(payment.endDate).toISOString().split('T')[0]}
                </Typography>
              </Box>
              {payment.receiptImage && (
                <Grid item xs={12}>
                  <Typography noWrap fontSize="small">
                    Receipt
                  </Typography>
                  <Typography noWrap>
                    <Button
                      onClick={() => {
                        setOpen(true)
                        setImage(payment.receiptImage ? payment.receiptImage : '')
                      }}
                    >
                      View Receipt
                    </Button>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Daily Logs ({payment.dailyLogs.length})
          </AccordionSummary>
          <AccordionDetails>
            {payment.attendancePapers &&
              payment.attendancePapers.map((att, i) => (
                <Box key={`${att.childId}-${i}`}>
                  <Typography noWrap fontSize="small">
                    {contract.children.map(child => (
                      <span key={child.id}>
                        {child.id === att.childId ? child.studentName : <></>}
                      </span>
                    ))}
                  </Typography>
                  <Typography>
                    {att?.images?.map(i => (
                      <Button
                        key={i}
                        onClick={() => {
                          setOpen(true)
                          setImage(i)
                        }}
                      >
                        View Attendance
                      </Button>
                    ))}
                  </Typography>
                </Box>
              ))}
            <Box display="flex" justifyContent="flex-end">
              {payment.status !== PaymentStatusEnum.VERIFIED && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    updateDailyLog({
                      paymentId: payment.id,
                      dailyLogs: payment.dailyLogs
                        .filter(d => d.status !== DailyLogStatusEnum.APPROVED)
                        .map(d => ({
                          id: d.id,
                          status: DailyLogStatusEnum.APPROVED,
                          childId: d.childId,
                          date: d.date,
                          startTime: d.startTime,
                          endTime: d.endTime,
                          description: d.description,
                        })),
                    }).then(() => {
                      refetch()
                    })
                  }}
                >
                  Approve all
                </Button>
              )}
            </Box>
            <Box>
              {payment.dailyLogs.map(day => (
                <DailyLogCard
                  children={contract.children}
                  payment={payment}
                  key={day.id}
                  dailyLog={day}
                  refetch={refetch}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        <AddNoteCard payment={payment} refetch={refetch} />
        {payment.status === PaymentStatusEnum.CANCELED && (
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography>Cancellation Reasons</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {payment &&
                payment.cancellationReasons &&
                payment.cancellationReasons.map(reason => (
                  <NoteCard key={reason.id} note={reason} />
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Card>

      <UpdatePaymentDialog
        payment={payment}
        OpenPaymentCardEdit={openPaymentCardEdit}
        setOpenPaymentCardEdit={setOpenPaymentCardEdit}
        refetch={refetch}
      />
    </>
  )
}

export default PaymentCard
