import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import TutorsData from './components/tutors-data'
import ProspectTutorsData from './components/prospect-tutors-data'
import TutorStatData from './components/tutor-stat-data'
import ContractsData from './components/contracts-data'
import PaymentsData from './components/payments-data'
import CustomersData from './components/customers-data'
import UsersData from './components/users-data'
import { Link } from 'react-router-dom'

const Dashboard: React.FC = () => {
  return (
    <Paper>
     <Box px={3} pt={5}>
        Dashboard
      </Box>
      <Grid container>
        <Grid item sm={12} md={6}>
          <TutorStatData type="gender" />
        </Grid>
        <Grid item sm={12} md={6}>
          <TutorStatData type="status" />
        </Grid>
        <Grid item sm={12} lg={12}>
          <TutorsData />
        </Grid>
        <Grid item sm={12}>
          <ProspectTutorsData />
        </Grid>
        <Grid item sm={12}>
          <Box px={3}>
            <Link to="/dashboard/contracts">
              <Typography variant="h5">Contracts</Typography>
            </Link>
          </Box>
          <ContractsData />
        </Grid>
        <Grid item sm={12}>
          <PaymentsData />
        </Grid>
        <Grid item sm={12} lg={6}>
          <CustomersData />
        </Grid>
        <Grid item sm={12} lg={6}>
          <UsersData />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Dashboard
