import React, { useState } from 'react'
import { Formik } from 'formik'
import { SendSmsInput } from './dto/sms.input'
import { sendSmsInitialState, useSendSms } from './hooks/use-send-sms.hook'
import { CircularIndeterminate } from '../components/preloader/preloader'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material'
import TextInputField from '../components/inputs/text-input-field'
import MultilineInputField from '../components/inputs/text-area-field'

const SmsPage: React.FC = () => {
  const { sendCustomSms, loading, error } = useSendSms()
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [values, setValues] = useState(sendSmsInitialState) // Moved inside the component
  const [isSent, setIsSent] = useState(false)

  const handleConfirmSend = (formValues: SendSmsInput) => {
    setValues(formValues)
    setOpenConfirmDialog(true)
  }

  const handleSend = async () => {
    try {
      await sendCustomSms({
        message: values.message,
        phoneNumber: values.phoneNumber,
      })
      setIsSent(true)
    } catch (error) {
      console.log(error)
      // Handle error if needed
    } finally {
      setOpenConfirmDialog(false)
    }
  }
  const handleCloseDialog = () => {
    setOpenConfirmDialog(false)
  }

  return (
    <>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {isSent && <Alert severity="success">SMS Sent Successfully!</Alert>}

      <Box my={1} />

      <Formik
        initialValues={sendSmsInitialState}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          handleConfirmSend(values as SendSmsInput)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Paper>
              <Box px={3} py={5}>
                <Box my={1}>
                  <TextInputField
                    name="phoneNumber"
                    id="phoneNumber"
                    value={values.phoneNumber}
                    label="Phone Number"
                    type="text"
                    onChange={handleChange}
                  />
                </Box>
                <Box my={3}>
                  <MultilineInputField
                    name="message"
                    id="message"
                    value={values.message}
                    label="Message"
                    type="text"
                    onChange={handleChange}
                  />
                </Box>

                <Box my={2} display="flex" justifyContent="space-between">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            </Paper>

            {error && <Alert severity="error">{error.message}</Alert>}
          </form>
        )}
      </Formik>
      <Dialog open={openConfirmDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Send</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to send this SMS?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSend} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SmsPage
