import { gql, useLazyQuery } from '@apollo/client'
import { TutorStat, TutorStatFilters } from '../dto/tutor-stat-filter'
import { ContractStatusEnum } from '../../app/contracts/entities/contract.enums'

export function useContractsData() {
  const [contractsDataGql, { loading, data, error }] = useLazyQuery<
    { advancedContractsAnalytics: JSON },
    { groupBy: string; statuses: ContractStatusEnum[]; startDate: Date; endDate: Date }
  >(CONTRACTS_DATA)

  function contractsData(
    groupBy: string,
    statuses: ContractStatusEnum[],
    startDate: Date,
    endDate: Date,
  ) {
    return contractsDataGql({
      variables: {
        groupBy,
        statuses,
        startDate,
        endDate,
      },
    })
  }

  return { contractsData, loading, data, error }
}

const CONTRACTS_DATA = gql`
  query Query(
    $groupBy: String!
    $statuses: [ContractStatusEnum!]!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    advancedContractsAnalytics(
      groupBy: $groupBy
      statuses: $statuses
      startDate: $startDate
      endDate: $endDate
    )
  }
`

export function useContractStatData() {
  const [contractStatDataGql, { loading, data, error }] = useLazyQuery<
    {
      getContractAnalytics: TutorStat[]
    },
    { getContractAnalyticsInput: any }
  >(CONTRACT_STAT_DATA)

  function contractStatData(statFilters: TutorStatFilters) {
    return contractStatDataGql({
      variables: {
        getContractAnalyticsInput: {
          statFilters,
        },
      },
    })
  }

  return { contractStatData, loading, data, error }
}

const CONTRACT_STAT_DATA = gql`
  query GetTutor($getContractAnalyticsInput: GetContractAnalyticsInput!) {
    getContractAnalytics(getContractAnalyticsInput: $getContractAnalyticsInput)
  }
`
