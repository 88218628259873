export const normalizeInitialState = (initialState: any) => {
  const initState: any = {}
  Object.keys(initialState).forEach(key => {
    if (key === 'subjects' || key === 'gradeLevels') {
      initState[key] = initialState[key] === undefined ? [] : initialState[key]
    } else {
      initState[key] = initialState[key] === undefined ? '' : initialState[key]
    }
  })
  return initState
}

export const denormalizeValue = (value: any) => {
  const values: any = {}
  Object.keys(value).forEach(key => {
    values[key] = value[key] === '' ? undefined : value[key]
  })
  return values
}

export function setDefaultValues(data: any, requiredKeys: string[]): any {
  return data.map((item: any) => {
    let newItem = { ...item }
    requiredKeys.forEach(key => {
      if (newItem[key] === undefined) {
        newItem[key] = 0
      }
    })
    return newItem
  })
}
