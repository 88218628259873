import { gql, useMutation } from '@apollo/client'
import { TutorRating } from '../../tutors/entities/tutor-rating.entity'

const CREATE_RATING = gql`
  mutation CreateTutorRating($createTutorRatingInput: CreateTutorRatingInput!) {
    createTutorRating(createTutorRatingInput: $createTutorRatingInput) {
      id
    }
  }
`

export function useGiveRating() {
  const [createTutorRatingGql, { loading, data, error }] = useMutation<
    { createTutorRating: TutorRating },
    { createTutorRatingInput: TutorRating }
  >(CREATE_RATING)

  function createRating(createTutorRatingInput: TutorRating) {
    return createTutorRatingGql({
      variables: { createTutorRatingInput },
    })
  }

  return { createRating, loading, data, error }
}
