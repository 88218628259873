export enum ServiceTypeEnum {
  IN_PERSON = 'IN_PERSON',
  ONLINE = 'ONLINE',
}

export enum WorkStatusEnum {
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  GRADUATED = 'GRADUATED',
  EMPLOYED = 'EMPLOYED',
}

export enum TutorDepartment {
  NATURAL_SCIENCE = 'NATURAL_SCIENCE',
  SOCIAL_SCIENCE = 'SOCIAL_SCIENCE',
}
