import SelectInputField, { SelectMenuItem } from './select-input-field'
import { GradeLevelList } from '../../contracts/entities/contract.enums'
import React from 'react'

const GradeLevelSingleInput = ({
  value,
  onChange,
}: {
  value: any
  onChange(e: any): void
}) => {
  return (
    <SelectInputField
      id="gradeLevel"
      value={value}
      label="Grade Level"
      name="gradeLevel"
      onChange={onChange}
      menuItems={
        GradeLevelList.map(value => ({
          name: value.toLowerCase(),
          value,
        })) as SelectMenuItem[]
      }
    />
  )
}

export default GradeLevelSingleInput
