import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useAuth } from '../../context/auth_context'

const LOGOUT = gql`
  mutation {
    logout
  }
`

const Logout = () => {
  const auth = useAuth()

  const [logout] = useMutation(LOGOUT)

  useEffect(() => {
    // dispatch(clearTokens())
    auth?.setAuthState(null)
    logout().then()
  }, [])

  return <Navigate to="/login" />
}

export default Logout
