import { gql, useMutation } from '@apollo/client'
import { Customer } from '../entity/customer.entity'
import { UpdateCustomerInput } from '../dto/update-customer.input'

const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomerAdmin($updateCustomerAdminInput: UpdateCustomerAdminInput!) {
    updateCustomerAdmin(updateCustomerAdminInput: $updateCustomerAdminInput) {
      id
    }
  }
`

export function useUpdateCustomer() {
  const [updateCustomerGql, { loading, error }] = useMutation<
    { updateCustomerAdmin: Customer },
    { updateCustomerAdminInput: UpdateCustomerInput }
  >(UPDATE_CUSTOMER)

  function updateCustomer(updateCustomerAdminInput: UpdateCustomerInput) {
    return updateCustomerGql({
      variables: { updateCustomerAdminInput },
    })
  }

  return { updateCustomer, loading, error }
}
