import { gql, useLazyQuery } from '@apollo/client'
import { Contract, contractStringFull } from '../../contracts/entities/contract.entity'
import { Paginated, PaginationInput } from '../../../common/pagination/pagination'
import {
  FindContractsDateInputs,
  FindContractsInput,
  FindContractsNumberInputs,
} from '../dto/find-contracts.input'
import {
  ContractGenderEnum,
  ContractStatusEnum,
  ContractTypeEnum,
} from '../../contracts/entities/contract.enums'
import { SortContractInput } from '../dto/sort-contract.input'

export function useFindContracts() {
  const [findContractGql, { loading, data, error }] = useLazyQuery<
    { contracts: Paginated<Contract> },
    {
      paginationInput: PaginationInput
      findContractsInput: FindContractsInput
      sortContractInput: SortContractInput
    }
  >(FIND_CONTRACTS, { fetchPolicy: 'cache-and-network' })

  function findContracts({
    currentSearchState,
    currentSortState,
    paginationInput,
    dateStates,
    numberStates,
  }: {
    currentSearchState: FindContractsInput
    currentSortState: SortContractInput
    paginationInput: PaginationInput
    dateStates?: FindContractsDateInputs
    numberStates?: FindContractsNumberInputs
  }) {
    return findContractGql({
      variables: {
        paginationInput,
        sortContractInput: { ...currentSortState },
        findContractsInput: contractSearchInput(currentSearchState, dateStates, numberStates),
      },
    })
  }

  return { findContracts, loading, data, error }
}

export const contractSearchInput = (
  search: FindContractsInput,
  dateStates?: FindContractsDateInputs,
  numberStates?: FindContractsNumberInputs,
): FindContractsInput => ({
  ...search,
  fullName: search.fullName ? { regex: search.fullName as string } : undefined,
  tutorGender:
    search.tutorGender && search.tutorGender === ContractGenderEnum.BOTH
      ? undefined
      : search.tutorGender
      ? search.tutorGender
      : undefined,
  serviceType:
    search.serviceType && search.serviceType.length > 0 ? search.serviceType : undefined,
  subjects:
    search.subjects && search.subjects.length > 0
      ? search.subjects.filter((s: string) => s === 'all').length > 0
        ? undefined
        : search.subjects.map((s: string) => s.toLowerCase())
      : undefined,
  gradeLevels:
    search.gradeLevels && search.gradeLevels.length > 0 ? search.gradeLevels : undefined,
  ...dateStates,
  ...numberStates,
})

export function useFindContractCount() {
  const [findCountGql, { loading, data, error }] = useLazyQuery<
    { contracts: Paginated<Contract> },
    { findContractsInput: FindContractsInput; paginationInput: PaginationInput }
  >(CONTRACTS_COUNT, { fetchPolicy: 'cache-first' })

  function findCount({
    customerId,
    tutorId,
    contractType,
  }: {
    customerId?: string
    tutorId?: string
    contractType?: ContractTypeEnum
  }) {
    return findCountGql({
      variables: {
        findContractsInput: {
          customerId: customerId !== undefined && customerId !== null ? customerId : undefined,
          tutorId: tutorId !== undefined && tutorId !== null ? tutorId : undefined,
          contractType,
        },
        paginationInput: {
          skip: 0,
          limit: 10000,
        },
      },
    })
  }

  return { findCount, loading, data, error }
}

export const findContractsInitialState: FindContractsInput = {
  id: undefined,
  location: undefined,
  // rate: [0, 0],
  // hrsDay: undefined,
  totalHours: undefined,
  // subjects: undefined,
  // gradeLevel: undefined,
  // learningDays: undefined,
  startDate: undefined,
  endDate: undefined,
  tutorGender: undefined,
  status: ContractStatusEnum.NOT_CONFIRMED,
  serviceType: undefined,
  createdAt: undefined,
  subjects: [],
  gradeLevels: [],
  totalPayment: undefined,
  unpaidPayment: undefined,
  totalPaymentParent: undefined,
  parentRate: undefined,
  totalPaymentTutor: undefined,
  fullName: undefined,
}

export const findInitContractSortState: SortContractInput = {
  fullName: undefined,
  totalPayment: undefined,
  unpaidPayment: undefined,
  totalHours: undefined,
  totalPaymentParent: undefined,
  parentRate: undefined,
  createdAt: -1,
}

const FIND_CONTRACTS = gql`
  query Contracts($findContractsInput: FindContractsInput, $sortContractInput: SortContractInput, $paginationInput: PaginationInput) {
    contracts(findContractsInput: $findContractsInput, sortContractInput: $sortContractInput, paginationInput: $paginationInput) {
      items {
        ${contractStringFull}
      }
      pageInfo {
        skip
        limit
        count
      }
    }
  }
  `

const CONTRACTS_COUNT = gql`
  query Items($findContractsInput: FindContractsInput, $paginationInput: PaginationInput) {
    contracts(findContractsInput: $findContractsInput, paginationInput: $paginationInput) {
      items {
        id
        status
      }
    }
  }
`
