import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Box, Card, Grid, Typography } from '@mui/material'
import CustomerInfoCard from '../customers/components/customer-info-card'
import { CircularIndeterminate } from '../components/preloader/preloader'
import { Contract } from '../contracts/entities/contract.entity'
import ContractSection from './components/contract-section'
import PaymentsSection from './components/payments-section'
import SearchTutorSection from './components/search-tutor-section'
import { ContractStatusEnum } from '../contracts/entities/contract.enums'
import { useFindContractDetail } from './hooks/use-find-contract-by-id.hook'

const ContractDetailPage = () => {
  const {
    findContractDetail,
    loading: findContractLoading,
    error: findContractError,
  } = useFindContractDetail()

  useEffect(() => {
    handleGetContracts()
  }, [])

  const { id } = useParams()

  const [contractData, setContractData] = useState<Contract | undefined>(undefined)

  const handleGetContracts = () => {
    findContractDetail(id).then(res => {
      setContractData(res.data?.contract)
    })
  }

  return (
    <>
      {findContractLoading && <CircularIndeterminate />}
      {findContractError && <Alert severity="error">{findContractError.message}</Alert>}

      {contractData && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <CustomerInfoCard customer={contractData.customer} />
            <Box py={1} />
            <Box height="65vh" overflow="scroll">
              <ContractSection contract={contractData} refetch={handleGetContracts} />
            </Box>
          </Grid>

          <Grid item xs={12} lg={6}>
            {contractData.status === ContractStatusEnum.NOT_CONFIRMED ? (
              <Card>
                <Box p={3}>
                  <Typography>Please change the status to "CONFIRMED" to proceed!</Typography>
                </Box>
              </Card>
            ) : (
              <Box height="90vh" overflow="scroll">
                <PaymentsSection
                  handleGetContracts={handleGetContracts}
                  contract={contractData}
                />

                <Box py={1} />

                <SearchTutorSection
                  contract={contractData}
                  findContractLoading={findContractLoading}
                  handleGetContracts={handleGetContracts}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ContractDetailPage
