import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'

const TestLinkSection = () => {
  const GET_TEST_LINK = gql`
    query {
      testLink {
        link
      }
    }
  `

  const CREATE_TEST_LINK = gql`
    mutation ($link: String!) {
      createTestLink(link: $link) {
        link
      }
    }
  `

  const DELETE_TEST_LINK = gql`
    mutation {
      removeTestLink
    }
  `
  const [getTestLink, { loading, data, error: linkError }] = useLazyQuery(GET_TEST_LINK)
  const [createTestLink, { loading: creating, data: creatingData, error: creatingError }] =
    useMutation(CREATE_TEST_LINK)
  const [deleteTestLink, { loading: deleting, data: deletingData, error: deletingError }] =
    useMutation(DELETE_TEST_LINK)

  const handleGetLink = () => {
    getTestLink().then()
  }

  useEffect(() => {
    handleGetLink()
  }, [creatingData, deletingData])

  const [expanded, setExpanded] = useState<string | false>(false)
  const [openAddTestLink, setOpenAddTestLink] = useState(false)
  const [link, setLink] = useState('')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box mb={3}>
      {(loading || creating || deleting) && <CircularIndeterminate />}
      {linkError && <Alert severity="error">{linkError.message}</Alert>}
      {creatingError && <Alert severity="error">{creatingError.message}</Alert>}
      {deletingError && <Alert severity="error">{deletingError.message}</Alert>}

      <Dialog open={openAddTestLink}>
        <Box p={2}>
          <form
            onSubmit={e => {
              e.preventDefault()
              setOpenAddTestLink(false)
              createTestLink({ variables: { link } }).then()
            }}
          >
            <Box mb={2}>
              <TextField
                id="link"
                name="link"
                value={link}
                required
                onChange={e => setLink(e.target.value)}
              />
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button
                color="secondary"
                type="button"
                onClick={() => setOpenAddTestLink(false)}
              >
                Cancel
              </Button>
              <Button type="submit">Add</Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5">TestLink</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2} display="flex" justifyContent="right">
            <Button onClick={() => setOpenAddTestLink(true)}>Add TestLink</Button>
          </Box>
          {!linkError && data && (
            <Box p={2}>
              <Grid container spacing={2}>
                {data.testLink && (
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="space-between">
                        <Typography>{data.testLink?.link}</Typography>
                        <Box display="flex">
                          <IconButton onClick={() => deleteTestLink().then()}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default TestLinkSection
