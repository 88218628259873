export enum TutorApplicationStatusEnum {
  NOT_TAKEN = 'NOT_TAKEN',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  VIDEO_UPLOADED = 'VIDEO_UPLOADED',
  VIDEO_PASSED = 'VIDEO_PASSED',
  VIDEO_FAILED = 'VIDEO_FAILED',
  PAID = 'PAID',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  PROFILE_FILLED = 'PROFILE_FILLED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  ALL = 'ALL',
}

export enum TutorApplicationStatusUpdateEnum {
  NOT_TAKEN = 'NOT_TAKEN',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  VIDEO_UPLOADED = 'VIDEO_UPLOADED',
  VIDEO_PASSED = 'VIDEO_PASSED',
  VIDEO_FAILED = 'VIDEO_FAILED',
  PAID = 'PAID',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  PROFILE_FILLED = 'PROFILE_FILLED',
  NOT_INTERESTED = 'NOT_INTERESTED',
}
