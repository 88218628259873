export interface Commission {
  id: string
  year: string
  active: boolean
  paymentRanges: PaymentRanges[]
}

export interface PaymentRanges {
  id?: string
  _id?: string
  from: number
  to: number
  percent: number
}

export const commissionStringFull = `
  active
  id
  year
  paymentRanges {
    from
    id
    percent
    to
  }
`
