import { Tutor, tutorStringMini } from '../../tutors/entities/tutor.entity'
import { Child, childStringFull } from './child.entity'
import { Customer, customerStringFull } from '../../customers/entity/customer.entity'
import { CustomerTypeEnum } from '../../customers/entity/customer.enums'
import {
  ContractCreationEnum,
  ContractGenderEnum,
  ContractStatusEnum,
  ContractTypeEnum,
} from './contract.enums'
import { Commission, commissionStringFull } from '../../commission/entities/commission.entity'
import { locationStringFull, UserLocation } from '../../../common/location/location.entity'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import { User } from '../../users/entities/user.entity'
import { Payment, paymentStringFull } from '../../payments/entities/payment.entity'
import { Note, noteStringFull } from '../../notes/entity/notes.entity'

export interface UpdateHistory {
  status: string
  updatedById?: string
  createdAt?: Date
}

export interface TutorAssignmentHistory {
  id?: string
  description?: string
  createdById?: string
  createdBy?: User
  tutorId?: string
  tutor?: Tutor
  createdAt?: Date
  updatedAt?: Date
}

export interface Contract {
  id: string
  name: string
  fullName: string
  phoneNumber: string
  additionalPhoneNumbers?: string[]
  totalPaymentParent: number
  totalPaymentTutor: number
  unpaidPayment: number
  totalHours: number
  children: Child[]
  status: ContractStatusEnum
  customerType: CustomerTypeEnum
  contractType: ContractTypeEnum
  serviceType: ServiceTypeEnum
  tutorGender: ContractGenderEnum
  creation: ContractCreationEnum
  location: UserLocation
  contractPaperImages: string[]
  subjects: string[]
  gradeLevels: string[]
  signed: boolean
  startTime: string
  endTime: string
  startDate: Date
  endDate: Date
  statusUpdatedAt: Date
  notes: Note[]
  cancellationReasons?: Note[]
  statusHistory?: UpdateHistory[]
  tutorAssignmentHistory?: TutorAssignmentHistory[]
  tutorId?: string
  tutor?: Tutor
  customerId: string
  customer: Customer
  commissionId: string
  commission: Commission
  createdById?: string
  createdBy?: User
  payments?: Payment[]
  createdAt: Date
  updatedAt: Date
}

export interface UpdateContractStatusInputAdmin {
  contractId: string
  status?: ContractStatusEnum
  signed?: boolean
  notes?: Note[]
  cancellationReasons?: Note[]
  contractPaperImages?: string[]
}

export const contractStringFull = `
  id
  fullName
  phoneNumber
  totalPaymentParent
  totalPaymentTutor
  totalHours
  unpaidPayment
  # parentRate
  tutorId
  customerId
  status
  customerType
  contractType
  commissionId
  createdById
  creation
  contractPaperImages
  serviceType
  tutorGender
  signed
  startDate
  endDate
  startTime
  endTime
  statusUpdatedAt
  createdAt
  updatedAt
  additionalPhoneNumbers
  createdBy {
    id
    fullName
  }
  children{
    ${childStringFull}
  }
  tutor{
    ${tutorStringMini}
  }
  customer{
    ${customerStringFull}
  }
  commission {
    ${commissionStringFull}
  }
  location{
   ${locationStringFull}
  }
  notes {
    ${noteStringFull}
  }
  cancellationReasons {
    ${noteStringFull}
  }
  payments{
   ${paymentStringFull}
  }
  tutorAssignmentHistory {
    id
    description
    createdById
    createdBy {
       fullName
    }
    tutorId
    tutor {
      id
      fullName
    }
    createdAt
    updatedAt
  }
`
