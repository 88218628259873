import React from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
}

const ImageModal: React.FC<{
  onClick: () => void
  open: boolean
  onClose: () => void
  src: string
  text: string
}> = ({ onClick, onClose, open, src, text }) => {
  return (
    <Typography noWrap>
      <Button onClick={onClick}>{text}</Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img style={{ width: '100%' }} src={src} alt="" />
        </Box>
      </Modal>
    </Typography>
  )
}

export default ImageModal
