import React from 'react'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import { Payment } from '../../payments/entities/payment.entity'

interface PaymentDetailDialogProps {
  open: boolean
  payment: Payment

  handleClose(open: boolean): void
}

const PaymentDetailDialog: React.FC<PaymentDetailDialogProps> = ({
  open,
  payment,
  handleClose,
}) => {
  return (
    <Dialog open={open}>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>Payment Info</Typography>
            <IconButton onClick={() => handleClose(false)}>
              <GridCloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" noWrap fontSize={18}>
              Account Number:
              {`${payment?.accountNumber}`}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">Payment Link</Typography>
            <Box mx={1}>:</Box>
            <Typography>{payment?.paymentLink}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">TransactionId</Typography>
            <Box mx={1}>:</Box>
            <Typography>{payment?.transactionId}</Typography>
          </Box>

          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Payable Commission
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{payment?.payableCommission}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Receipt Number
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{payment?.receiptNumber}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  )
}

export default PaymentDetailDialog
