import { gql, useMutation } from '@apollo/client'
import { AuthResponse } from '../dto/auth.response'
import { LoginInput } from '../dto/login.input'

const LOGIN = gql`
  mutation Login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      accessToken
      refreshToken
      user {
        id
        fullName
        role
        phoneNumber
        status
      }
    }
  }
`

export function useLogin() {
  const [loginGql, { loading, data, error }] = useMutation<
    { login: AuthResponse },
    { loginInput: LoginInput }
  >(LOGIN)

  async function login(loginInput: LoginInput) {
    return loginGql({
      variables: { loginInput },
    })
  }

  return { login, loading, data, error }
}
