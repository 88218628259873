import { gql, useMutation } from '@apollo/client'
import { User } from '../entities/user.entity'
import { UpdateUserInput } from '../dto/user-update-input'

const UPDATE_USER = gql`
  mutation UpdateUserAdmin($updateUserInput: UpdateUserInput!) {
    updateUserAdmin(updateUserInput: $updateUserInput) {
      id
    }
  }
`

export function useUpdateUser() {
  const [updateUserGql, { loading, data, error }] = useMutation<
    { updateUserAdmin: User },
    { updateUserInput: UpdateUserInput }
  >(UPDATE_USER)

  function updateUser(updateUserInput: UpdateUserInput) {
    return updateUserGql({
      variables: { updateUserInput },
    })
  }

  return { updateUser, loading, data, error }
}
