import React from 'react'
import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

export interface SelectMenuItem {
  name: string
  value: string
}

interface SelectInputFieldProps {
  id: string
  label: string
  name: string
  value?: any[]
  multiple?: boolean
  required?: boolean
  menuItems: SelectMenuItem[]

  onChange?(value: any): void
}

const SelectInputFieldCheckbox: React.FC<SelectInputFieldProps> = ({
  id,
  name,
  value,
  label,
  onChange,
  required,
  menuItems,
  multiple = false,
}) => (
  <FormControl variant="standard" fullWidth>
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
    <Select
      labelId={`${id}-label`}
      id={id}
      variant="standard"
      value={value}
      name={name}
      onChange={onChange}
      autoFocus={false}
      fullWidth
      required={required !== undefined ? required : true}
      multiple
      renderValue={selected => selected.join(', ')}
    >
      {menuItems.map(item => (
        <MenuItem key={item.value} value={item.value}>
          <Checkbox checked={value && value.indexOf(item.value) > -1} />
          <ListItemText primary={item.name} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default SelectInputFieldCheckbox
