import React, { useEffect, useState } from 'react'
import { Alert, Avatar, Box, Grid, IconButton, Paper, Rating, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import pic from '../../assets/img/pic.webp'
import { CircularIndeterminate } from '../components/preloader/preloader'
import { useFindTutorById } from './hooks/use-find-tutor-by-id.hook'
import UpdateTutorDialog from './components/update-tutor-dialog'
import RatingSection from '../tutors/review-section'
import { UserStatusEnum } from '../users/entities/user.enums'
import SearchContractsPage from '../search_contracts/search-contracts-page'

const TutorDetailPage: React.FC = () => {
  const [openTutorEdit, setOpenTutorEdit] = useState(false)

  const { findTutorById, loading, data, error } = useFindTutorById()

  const { id } = useParams()

  const handleFetchTutor = () => {
    findTutorById(id ? id?.toString() : '').then()
  }

  useEffect(() => {
    handleFetchTutor()
  }, [])

  return (
    <>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}

      {data && (
        <>
          <Paper>
            <Box p={2}>
              <Box display="flex">
                <Box>
                  <Avatar
                    alt="profile_pic"
                    src={data.tutor.profilePic ? data.tutor.profilePic : pic}
                    sx={{ width: 85, height: 85, borderRadius: '10px' }}
                    variant="square"
                  />
                  <Box my={2} />
                </Box>

                <Box mx={1} />

                <Box>
                  <Typography fontWeight="bold" variant="h6">
                    {`${data.tutor.fullName}`}
                  </Typography>
                  <Typography>{data.tutor.phoneNumber}</Typography>
                  <Typography>{data.tutor.email}</Typography>
                </Box>

                <Box flexGrow={1} />

                <Box>
                  <Typography>{data.tutor.gender}</Typography>
                  <Typography
                    color={
                      data.tutor.status === UserStatusEnum.ACTIVE
                        ? 'green'
                        : data.tutor.status === UserStatusEnum.PAUSED
                        ? 'orange'
                        : data.tutor.status === UserStatusEnum.PENDING
                        ? 'orange'
                        : 'red'
                    }
                  >
                    {data.tutor.status}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" mb={2}>
                <Rating defaultValue={data.tutor.rating} precision={0.5} readOnly />
                <Typography color="gray">({data.tutor.ratingCount} Reviews)</Typography>
              </Box>

              <Box display="flex">
                <Typography fontWeight="bold">Work status</Typography>
                <Box mx={1}>:</Box>
                <Typography>{data.tutor.workStatus}</Typography>
              </Box>
              <Box my={1} />

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box display="flex">
                      <Typography noWrap>Bank Name</Typography>
                      <Box mx={1} />
                      <Typography fontWeight="bold">
                        {data.tutor.bankInfo?.bankName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box display="flex">
                      <Typography noWrap>Account Number</Typography>
                      <Box mx={1} />
                      <Typography fontWeight="bold">
                        {data.tutor.bankInfo?.accountNumber}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box display="flex">
                  <Typography fontWeight="bold">Address</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{data.tutor.yourLocation?.streetName}</Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Pitch</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{data.tutor.pitch}</Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Education</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{data.tutor.education}</Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Grade 12 result</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>{data.tutor.g12Result}</Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Grade levels</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {data.tutor.gradeLevels
                      ? data.tutor.gradeLevels.map(gradeLevel => (
                          <span key={gradeLevel}>{gradeLevel}, </span>
                        ))
                      : ''}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Service type</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {data.tutor.serviceTypes.map(serviceType => (
                      <span key={serviceType}>{serviceType} </span>
                    ))}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Working Locations</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {data.tutor.locationDetails.map((location, i) => (
                      <span key={`${location.id}`}>{location.streetName}, </span>
                    ))}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Subjects</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {data.tutor.subjects.map(subject => (
                      <span key={subject}>{subject}, </span>
                    ))}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">Availability days</Typography>
                  <Box mx={1}>:</Box>
                  <Typography>
                    {data.tutor.workingDays.map(workingDay => (
                      <span key={workingDay}>{workingDay}, </span>
                    ))}
                  </Typography>
                </Box>
                <Grid container columnSpacing={{ md: 14 }}>
                  <Grid item md={6}>
                    <Box display="flex">
                      <Typography fontWeight="bold">Total clients</Typography>
                      <Box mx={1}>:</Box>
                      <Typography>{data.tutor.clientsWorkedWith}</Typography>
                    </Box>
                    <Box display="flex">
                      <Typography fontWeight="bold">Current clients</Typography>
                      <Box mx={1}>:</Box>
                      <Typography>{data.tutor.currentClients}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box display="flex">
                      <Typography fontWeight="bold">Total hours</Typography>
                      <Box mx={1}>:</Box>
                      <Typography>{data.tutor.totalHoursWorked}</Typography>
                    </Box>
                    <Box display="flex">
                      <Typography fontWeight="bold">Total earned</Typography>
                      <Box mx={1}>:</Box>
                      <Typography>{data.tutor.totalAmountEarned}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => setOpenTutorEdit(true)}>
                <EditIcon />
              </IconButton>
            </Box>
          </Paper>

          <Box my={5} />
          {id && <SearchContractsPage tutorId={id} />}

          <UpdateTutorDialog
            tutor={data.tutor}
            refetch={handleFetchTutor}
            openTutorEdit={openTutorEdit}
            setOpenTutorEdit={setOpenTutorEdit}
          />
        </>
      )}

      <Box mb={2}>
        <RatingSection tutorId={id ? id?.toString() : ''} refetch={handleFetchTutor} />
      </Box>
    </>
  )
}

export default TutorDetailPage
