import { gql, useMutation } from '@apollo/client'
import { SendSmsInput } from '../dto/sms.input'

const SEND_SMS = gql`
  mutation Mutation($sendSmsInput: SendSmsInput!) {
    sendCustomSms(sendSmsInput: $sendSmsInput)
  }
`

export function useSendSms() {
  const [createContractGql, { loading, data, error }] = useMutation<{
    sendSmsInput: SendSmsInput
  }>(SEND_SMS)

  function sendCustomSms(sendSmsInput: SendSmsInput) {
    return createContractGql({
      variables: { sendSmsInput },
    })
  }

  return { sendCustomSms, loading, data, error }
}

export const sendSmsInitialState: SendSmsInput = {
  message: '',
  phoneNumber: '',
}
