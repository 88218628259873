import { GradeLevelList } from '../../contracts/entities/contract.enums'
import { SelectMenuItem } from './select-input-field'
import SelectInputFieldCheckbox from './select-input-field-checkbox'
import React from 'react'

const GradeLevelMultipleInput = ({
  value,
  handleChange,
}: {
  value?: string[]
  handleChange?(e: any): void
}) => {
  return (
    <SelectInputFieldCheckbox
      id="grade-level"
      value={value}
      label="Grade Level"
      name="gradeLevels"
      onChange={e => handleChange && handleChange(e)}
      multiple
      required={false}
      menuItems={
        GradeLevelList.map(value => ({
          name: value.toLowerCase(),
          value,
        })) as SelectMenuItem[]
      }
    />
  )
}

export default GradeLevelMultipleInput
