import { useEffect } from 'react'
import { useTutorStatData } from '../hooks/use-tutor-stat-data.hook'
import { Alert, Box, Typography } from '@mui/material'
import { formatTutorStat } from '../../functions/format-tutor-stat'
import PieChartComponent from './pie-chart'
import { TutorStat } from '../dto/tutor-stat-filter'

const TutorStatData = ({ type }: { type: keyof TutorStat }) => {
  const { data, error, loading, tutorStatData } = useTutorStatData()

  useEffect(() => {
    tutorStatData({ [type]: 1 }).then()
  }, [])

  const filteredData =
    !loading && data?.getTutorAnalytics ? formatTutorStat(data?.getTutorAnalytics, type) : null

  return (
    <Box px={3} py={5} my={2}>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Typography variant="h5">
        Tutors {type[0]?.toUpperCase() + type?.slice(1, type.length)} Data
      </Typography>
      <Box my={2} />
      {filteredData && <PieChartComponent data={filteredData} />}
    </Box>
  )
}

export default TutorStatData
