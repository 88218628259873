import React from 'react'
import { Alert, Box, Button, Grid } from '@mui/material'
import TextInputField from '../../components/inputs/text-input-field'
import { Payment } from '../entities/payment.entity'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import { updatePaymentInitialState, useUpdatePayment } from '../hook/use-update-payment.hook'
import { UpdatePaymentInput } from '../dto/update-payment.inputs'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import moment from 'moment'

export interface UpdatePaymentDialogProps {
  payment: Payment
  OpenPaymentCardEdit: boolean

  setOpenPaymentCardEdit(val: boolean): void

  refetch(): void
}

const UpdatePaymentDialog: React.FC<UpdatePaymentDialogProps> = ({
  payment,
  OpenPaymentCardEdit,
  setOpenPaymentCardEdit,
  refetch,
}) => {
  const { updatePayment, loading, error } = useUpdatePayment()

  return (
    <Dialog open={OpenPaymentCardEdit} fullWidth>
      {loading && <CircularIndeterminate />}

      <Formik
        initialValues={updatePaymentInitialState(payment)}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          updatePayment(values as UpdatePaymentInput)
            .then(() => {
              refetch()
              setOpenPaymentCardEdit(false)
            })
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box py={1}>
                <TextInputField
                  name="paymentLink"
                  id="payment-link"
                  value={values.paymentLink}
                  label="Payment Link"
                  type="text"
                  onChange={handleChange}
                  required={false}
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  name="receiptNumber"
                  id="receipt-number"
                  value={values.receiptNumber}
                  label="Receipt Number"
                  type="text"
                  onChange={handleChange}
                  required={false}
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  name="bankName"
                  id="bank-name"
                  value={values.bankName}
                  label="Bank Name"
                  type="text"
                  onChange={handleChange}
                  required={false}
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  name="accountNumber"
                  id="account-number"
                  value={values.accountNumber}
                  label="Account Number"
                  type="text"
                  onChange={handleChange}
                  required={false}
                />
              </Box>

              {/*<Box py={1}>*/}
              {/*  <Grid container>*/}
              {/*    <Grid item xs={6}>*/}
              {/*      <TextInputField*/}
              {/*        name='requestDate'*/}
              {/*        id='request-date'*/}
              {/*        value={moment(values.requestDate).format('yyyy-MM-DD')}*/}
              {/*        label='Request Date'*/}
              {/*        type='date'*/}
              {/*        onChange={handleChange}*/}
              {/*        required={false}*/}
              {/*      />*/}
              {/*    </Grid>*/}
              {/*    <Grid item xs={6}>*/}
              {/*      <TextInputField*/}
              {/*        name='parentPaymentDate'*/}
              {/*        id='parent-payment-date'*/}
              {/*        value={moment(values.parentPaymentDate).format('yyyy-MM-DD')}*/}
              {/*        label='Parent Payment Date'*/}
              {/*        type='date'*/}
              {/*        onChange={handleChange}*/}
              {/*        required={false}*/}
              {/*      />*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*</Box>*/}

              <Box py={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <TextInputField
                      name="endDate"
                      id="expected-end-date"
                      value={moment(values.endDate).format('yyyy-MM-DD')}
                      label="Expacted End Date"
                      type="date"
                      onChange={handleChange}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInputField
                      name="verifiedDate"
                      id="verified-date"
                      value={moment(values.verifiedDate).format('yyyy-MM-DD')}
                      label="Verified Date"
                      type="date"
                      onChange={handleChange}
                      required={false}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenPaymentCardEdit(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default UpdatePaymentDialog
