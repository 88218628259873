import { gql, useMutation } from '@apollo/client'
import { Contract } from '../../contracts/entities/contract.entity'

const ASSIGN_TUTOR = gql`
  mutation AssignTutor($contractId: String!, $tutorId: String!) {
    assignTutor(contractId: $contractId, tutorId: $tutorId) {
      id
    }
  }
`

const REMOVE_TUTOR = gql`
  mutation RemoveTutor($contractId: String!, $reason: String!) {
    removeTutor(contractId: $contractId, reason: $reason) {
      id
    }
  }
`

export function useAssignTutor() {
  const [assignTutorGql, { loading, data, error }] = useMutation<
    { assignTutor: Contract },
    { contractId: string; tutorId: string }
  >(ASSIGN_TUTOR)

  function assignTutor(contractId: string, tutorId: string) {
    return assignTutorGql({
      variables: { contractId, tutorId },
    })
  }

  return { assignTutor, loading, data, error }
}

export function useRemoveTutor() {
  const [removeTutorGql, { loading, data, error }] = useMutation<
    { removeTutor: Contract },
    { contractId: string; reason: string }
  >(REMOVE_TUTOR)

  function removeTutor(contractId: string, reason: string) {
    return removeTutorGql({
      variables: { contractId, reason },
    })
  }

  return { removeTutor, loading, data, error }
}
