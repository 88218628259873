import { gql, useMutation } from '@apollo/client'
import { UpdatePaymentInput } from '../dto/update-payment.inputs'
import { Payment } from '../entities/payment.entity'

const UPDATE_PAYMENT = gql`
  mutation UpdatePayment($updatePaymentInput: UpdatePaymentInput!) {
    updatePayment(updatePaymentInput: $updatePaymentInput) {
      id
    }
  }
`

export function useUpdatePayment() {
  const [updatePaymentGql, { loading, data, error }] = useMutation<
    { updatePayment: Payment },
    { updatePaymentInput: UpdatePaymentInput }
  >(UPDATE_PAYMENT)

  function updatePayment(updatePaymentInput: UpdatePaymentInput) {
    return updatePaymentGql({
      variables: {
        updatePaymentInput: {
          ...updatePaymentInput,
        },
      },
    })
  }

  return { updatePayment, loading, data, error }
}

export function updatePaymentInitialState(payment: Payment): UpdatePaymentInput {
  return {
    paymentId: payment.id,
    extendReasons: payment.extendReasons,
    cancellationReasons: payment.cancellationReasons,
    notes: payment.notes,
    paymentLink: payment.paymentLink,
    receiptNumber: payment.receiptNumber,
    bankName: payment.bankName,
    accountNumber: payment.accountNumber,
    parentPaymentDate: payment.parentPaymentDate,
    requestDate: payment.requestDate,
    tutorPaidDate: payment.tutorPaidDate,
    verifiedDate: payment.verifiedDate,
    endDate: payment.endDate,
  }
}
