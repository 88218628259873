import { gql, useMutation } from '@apollo/client'

const RESEND_WELCOME_EMAIL = gql`
  mutation SendWelcomeEmail($userId: String!) {
    sendWelcomeEmail(userId: $userId)
  }
`

const RESEND_PAYMENT_EMAIL = gql`
  mutation SendRegistrationFeeInfo($prospectId: String!) {
    sendRegistrationFeeInfo(prospectId: $prospectId)
  }
`

const RESEND_PAYMENT_CONFIRMATION_EMAIL = gql`
  mutation SendPaymentConfirmationEmail($prospectId: String!) {
    sendPaymentConfirmationEmail(prospectId: $prospectId)
  }
`

export function useResendWelcomeEmailResend() {
  const [resendWelcomeEmailGql, { loading, data, error }] = useMutation<
    { sendWelcomeEmail: string },
    { userId: string }
  >(RESEND_WELCOME_EMAIL)

  function resendWelcomeEmail(userId: string) {
    return resendWelcomeEmailGql({
      variables: { userId },
    })
  }

  return { resendWelcomeEmail, loading, data, error }
}

export function useResendPaymentEmailResend() {
  const [resendPaymentEmailGql, { loading, data, error }] = useMutation<
    { sendRegistrationFeeInfo: string },
    { prospectId: string }
  >(RESEND_PAYMENT_EMAIL)

  function resendPaymentEmail(prospectId: string) {
    return resendPaymentEmailGql({
      variables: { prospectId },
    })
  }

  return { resendPaymentEmail, loading, data, error }
}

export function useResendPaymentConfirmationEmailResend() {
  const [resendPaymentConfirmationEmailGql, { loading, data, error }] = useMutation<
    { sendPaymentConfirmationEmail: string },
    { prospectId: string }
  >(RESEND_PAYMENT_CONFIRMATION_EMAIL)

  function resendPaymentConfirmationEmail(prospectId: string) {
    return resendPaymentConfirmationEmailGql({
      variables: { prospectId },
    })
  }

  return { resendPaymentConfirmationEmail, loading, data, error }
}
