import React, { useEffect, useState } from 'react'
import { Box, Grid, Slider } from '@mui/material'
import ContractCardList from './components/contract-card-list'
import {
  ContractStatusEnum,
  ContractTypeEnum,
  GradeLevelList,
} from '../contracts/entities/contract.enums'
import {
  findInitContractSortState,
  useFindContractCount,
  useFindContracts,
} from './hooks/use-find-contracts.hook'
import { CustomerTypeEnum } from '../customers/entity/customer.enums'
import GenericSearchPage from '../generic-search/generic-search.page'
import { UserGenderEnum } from '../users/entities/user.enums'
import { PaginationInput } from '../../common/pagination/pagination'
import { ServiceTypeEnum } from '../tutors/entities/tutor.enums'
import { SubjectsArray } from '../search_tutors/components/search-tutors-card'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import { FindContractsDateInputs, FindContractsNumberInputs } from './dto/find-contracts.input'
import { useSearchPageState } from '../../context/search_page_context'
import SortContractCard from './components/sort-contract-card'
import { SortContractInput } from './dto/sort-contract.input'

const SearchContractsPage = ({
  customerId,
  tutorId,
  customerType,
}: {
  customerId?: string
  tutorId?: string
  customerType?: CustomerTypeEnum
}) => {
  const pageState = useSearchPageState()

  const { findContracts, loading, data, error } = useFindContracts()

  const [dateStates, setDateStates] = useState<FindContractsDateInputs>({
    createdAt: {
      gte: dayjs('2023-01-01').toDate(),
      lte: new Date(),
    },
  })

  const [numberStates, setNumberStates] = useState<FindContractsNumberInputs>({
    totalHours: {
      gte: 0,
      lte: 100,
    },
  })

  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    skip: 0,
    limit: 10,
  })

  const [currentSortState, setCurrentSortState] = useState<SortContractInput>(
    findInitContractSortState,
  )

  const { findCount, data: contractDataCount } = useFindContractCount()

  const [searchNow, setSearchNow] = useState(false)

  const handleLoadContracts = (sort?: SortContractInput) => {
    if (searchNow) {
      let search = { ...pageState?.searchPageState.contractsSearchState }
      if (customerId) {
        if (!search) search = {}
        search.customerId = customerId
      }
      if (tutorId) {
        if (!search) search = {}
        search.tutorId = tutorId
      }
      if (customerType) {
        if (!search) search = {}
        search.contractType =
          customerType === CustomerTypeEnum.SINGLE_CUSTOMER
            ? ContractTypeEnum.COMMISSION
            : ContractTypeEnum.FIXED
      } else {
        if (!search) search = {}
        search.customerType = CustomerTypeEnum.SINGLE_CUSTOMER
      }
      if (search?.status && search?.status?.toString() === 'ALL') {
        search.status = undefined
      }
      if (customerId || tutorId) {
        findContracts({
          currentSearchState: search,
          paginationInput,
          currentSortState: sort ? sort : currentSortState,
        }).then()
      } else {
        findContracts({
          currentSearchState: search,
          paginationInput,
          dateStates,
          numberStates,
          currentSortState: sort ? sort : currentSortState,
        }).then()
      }
    } else {
      setSearchNow(true)
    }
  }

  useEffect(() => {
    findCount({
      customerId,
      tutorId,
      contractType:
        customerType &&
        (customerType === CustomerTypeEnum.SINGLE_CUSTOMER
          ? ContractTypeEnum.COMMISSION
          : ContractTypeEnum.FIXED),
    })
      .then()
      .catch(e => e)
  }, [])
  const handleSortContract = (sortState: SortContractInput) => {
    setCurrentSortState(sortState)
    handleLoadContracts(sortState)
  }

  return (
    <Box pb={2}>
      <GenericSearchPage
        data={data?.contracts}
        loading={loading}
        error={error}
        countData={contractDataCount?.contracts}
        find={handleLoadContracts}
        showSearch={
          (customerId === null || customerId === undefined) &&
          (tutorId === null || tutorId === undefined)
        }
        pageName="contracts"
        statusArray={ContractStatusEnum}
        searchEntityName="contractsSearchState"
        currentState={pageState?.searchPageState.contractsSearchState}
        paginationInput={paginationInput}
        setPaginationInput={setPaginationInput}
        searchFields={[
          { name: 'fullName', inputType: 'TextInputField' },
          { name: 'phoneNumber', inputType: 'TextInputField', type: 'tel' },
          {
            name: 'tutorGender',
            inputType: 'SelectInputField',
            menuItems: Object.entries(UserGenderEnum),
          },
          {
            name: 'status',
            inputType: 'SelectInputField',
            menuItems: Object.entries(ContractStatusEnum),
          },
          {
            name: 'serviceType',
            inputType: 'SelectInputField',
            menuItems: Object.entries(ServiceTypeEnum),
          },
          {
            name: 'subjects',
            inputType: 'SelectInputFieldCheckbox',
            menuItems: SubjectsArray,
          },
          {
            name: 'gradeLevels',
            inputType: 'SelectInputFieldCheckbox',
            menuItems: GradeLevelList,
          },
        ]}
      >
        <div id="ignore_fields">
          {/*You can add more custom fields like date manipulation fields*/}

          <Box id="number_fields">
            <label>Total Hour Range</label>
            <Slider
              getAriaLabel={() => 'Total Hour Range'}
              value={[
                numberStates?.totalHours?.gte ? numberStates?.totalHours?.gte : 0,
                numberStates?.totalHours?.lte ? numberStates?.totalHours?.lte : 100,
              ]}
              onChange={(_: Event, newValue: number | number[]) => {
                setNumberStates((prev: any) => ({
                  ...prev,
                  totalHours: Array.isArray(newValue)
                    ? {
                        gte: newValue ? (newValue.length > 1 ? newValue[0] : 0) : 0,
                        lte: newValue ? (newValue.length > 1 ? newValue[1] : 100) : 100,
                      }
                    : { gte: 0, lte: 100 },
                }))
              }}
              valueLabelDisplay="auto"
              name="totalHours"
              max={100}
              min={0}
            />
          </Box>

          <Box>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: 'Created date From', end: 'To' }}
            >
              <DateRangePicker
                label="Date"
                value={[
                  dateStates?.createdAt?.gte
                    ? dayjs(dateStates?.createdAt?.gte)
                    : dayjs('2023-01-01'),
                  dateStates?.createdAt?.lte
                    ? dayjs(dateStates?.createdAt?.lte)
                    : dayjs(new Date()),
                ]}
                onChange={newValue => {
                  setDateStates((prev: any) => ({
                    ...prev,
                    createdAt: Array.isArray(newValue)
                      ? {
                          gte: newValue
                            ? newValue.length > 1
                              ? (newValue[0] as dayjs.Dayjs).toDate()
                              : dayjs('2023-01-01').toDate()
                            : dayjs('2023-01-01').toDate(),
                          lte: newValue
                            ? newValue.length > 1
                              ? (newValue[1] as dayjs.Dayjs).toDate()
                              : new Date()
                            : new Date(),
                        }
                      : { gte: dayjs('2023-01-01').toDate(), lte: new Date() },
                  }))
                }}
              />
            </LocalizationProvider>
          </Box>
        </div>
        <div id="list_grid">
          <SortContractCard
            sortContract={handleSortContract}
            initialState={findInitContractSortState}
          />

          <Box my={3} />

          <Grid container spacing={2} id="list_grid">
            {data &&
              data.contracts.items.map(contract => (
                <Grid key={contract.id} item xs={12} md={6} lg={4}>
                  <ContractCardList contract={contract} refetch={handleLoadContracts} />
                  <Box my={1} />
                </Grid>
              ))}
          </Grid>
        </div>
      </GenericSearchPage>
    </Box>
  )
}

export default SearchContractsPage
