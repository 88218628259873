import { Alert, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import TextInputField from '../../components/inputs/text-input-field'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Payment } from '../../payments/entities/payment.entity'
import { useUpdatePayment } from '../../payments/hook/use-update-payment.hook'
import { useUpdateContract } from '../../contract_detail/hooks/use-update-contract.hook'
import { Contract } from '../../contracts/entities/contract.entity'
import { ProspectTutor } from '../../prospect_tutors/entities/prospect-tutor.entity'
import { useUpdateProspectTutor } from '../../prospect_tutors/hook/use-update-prospect-tutors-status.hook'

type AddNoteDialogProp = {
  openAddNote: boolean
  payment?: Payment
  contract?: Contract
  prospectTutor?: ProspectTutor
  setOpenAddNote(val: boolean): void
  refetch(): void
}

const AddNoteDialog = ({
  openAddNote,
  setOpenAddNote,
  payment,
  contract,
  prospectTutor,
  refetch,
}: AddNoteDialogProp) => {
  const [note, setNote] = useState('')

  const { updatePayment, loading: paymentLoading, error: paymentError } = useUpdatePayment()
  const {
    updateContract,
    loading: contractLoading,
    error: contractError,
  } = useUpdateContract()
  const {
    updateProspectTutor,
    loading: prospectLoading,
    error: prospectError,
  } = useUpdateProspectTutor()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (payment) {
      updatePayment({
        paymentId: payment.id,
        notes: [{ description: note }],
      }).then(() => {
        refetch()
        setOpenAddNote(false)
      })
    } else if (contract) {
      updateContract({
        contractId: contract.id,
        notes: [{ description: note }],
      }).then(() => {
        refetch()
        setOpenAddNote(false)
      })
    } else if (prospectTutor) {
      updateProspectTutor({
        tutorProspectId: prospectTutor.id,
        notes: [{ description: note }],
      }).then(() => {
        refetch()
        setOpenAddNote(false)
      })
    }
  }

  return (
    <Dialog open={openAddNote}>
      {(paymentLoading || contractLoading || prospectLoading) && <CircularIndeterminate />}
      {paymentError && <Alert severity="error">{paymentError.message}</Alert>}
      {contractError && <Alert severity="error">{contractError.message}</Alert>}
      {prospectError && <Alert severity="error">{prospectError.message}</Alert>}

      <Box px={3} py={2}>
        <form onSubmit={handleSubmit}>
          <Box py={2}>
            <TextInputField
              id="note"
              name="note"
              label="Note"
              value={note}
              onChange={e => setNote(e.target.value)}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              type="button"
              onClick={() => {
                setOpenAddNote(false)
              }}
              variant="outlined"
              color="error"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Add
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  )
}

export default AddNoteDialog
