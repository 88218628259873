export enum PaymentStatusEnum {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  TUTOR_PAID = 'TUTOR_PAID',
  VERIFIED = 'VERIFIED',
  CANCELED = 'CANCELED',
  HAS_ERROR = 'HAS_ERROR',
  NOT_PAID = 'NOT_PAID',
  ALL = 'ALL',
}

export enum PaymentStatusUpdateEnum {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  TUTOR_PAID = 'TUTOR_PAID',
  VERIFIED = 'VERIFIED',
  CANCELED = 'CANCELED',
  HAS_ERROR = 'HAS_ERROR',
  NOT_PAID = 'NOT_PAID',
  ALL = 'ALL',
}

export enum PaymentStatusTutorEnum {
  REQUESTED = 'REQUESTED',
  TUTOR_PAID = 'TUTOR_PAID',
  CANCELED = 'CANCELED',
}

export enum PaymentStatusParentEnum {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PARENT_PAID = 'PARENT_PAID',
}

export enum DailyLogStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum MonthsEnum {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}
