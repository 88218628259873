import { gql, useMutation } from '@apollo/client'
import { Contract } from '../../contracts/entities/contract.entity'
import { CreatePaymentDailyLogsInput } from '../entities/daily-log.entity'

const ADD_DAILY_LOG = gql`
  mutation CreateDailyLogs($createPaymentDailyLogsInput: CreatePaymentDailyLogsInput!) {
    createDailyLogs(createPaymentDailyLogsInput: $createPaymentDailyLogsInput) {
      id
    }
  }
`

export function useAddDailyLog() {
  const [addDailyLogGql, { loading, data, error }] = useMutation<
    { createDailyLogs: Contract },
    { createPaymentDailyLogsInput: CreatePaymentDailyLogsInput }
  >(ADD_DAILY_LOG)

  function addDailyLog(createPaymentDailyLogsInput: CreatePaymentDailyLogsInput) {
    return addDailyLogGql({
      variables: { createPaymentDailyLogsInput },
    })
  }

  return { addDailyLog, loading, data, error }
}
