import { gql, useMutation } from '@apollo/client'

const EXTEND_DEADLINE = gql`
  mutation SendDocumentResubmissionEmail($prospectId: String!) {
    sendDocumentResubmissionEmail(prospectId: $prospectId)
  }
`

export function useSendDocumentResubmissionEmail() {
  const [sendDocumentResubmissionEmailGql, { loading, data, error }] = useMutation<
    { sendDocumentResubmissionEmail: string },
    { prospectId: string }
  >(EXTEND_DEADLINE)

  function sendDocumentResubmissionEmail(prospectId: string) {
    return sendDocumentResubmissionEmailGql({
      variables: { prospectId },
    })
  }

  return { sendDocumentResubmissionEmail, loading, data, error }
}
