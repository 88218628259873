import { Alert, Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useContractStatData } from '../hooks/use-contracts-data.hook'
import { formatTutorStat } from '../../functions/format-tutor-stat'
import PieChartComponent from './pie-chart'
import { TutorStat } from '../dto/tutor-stat-filter'

const ContractsByStatusData = ({ type }: { type: keyof TutorStat }) => {
  const { contractStatData, data, error, loading } = useContractStatData()

  useEffect(() => {
    contractStatData({ [type]: 1 }).then()
  }, [])

  const filteredData =
    !loading && data?.getContractAnalytics
      ? formatTutorStat(data?.getContractAnalytics, type)
      : null

  return (
    <Box px={3} py={5} my={2}>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Typography variant="h5">
        Contracts {type[0]?.toUpperCase() + type?.slice(1, type.length)} Data
      </Typography>
      <Box my={2} />
      {filteredData && <PieChartComponent data={filteredData} />}
    </Box>
  )
}

export default ContractsByStatusData
