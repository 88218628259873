import { gql, useLazyQuery } from '@apollo/client'
import { Tutor, tutorStringFull } from '../../tutors/entities/tutor.entity'

export function useFindTutorById() {
  const [findTutorByIdGql, { loading, data, error }] = useLazyQuery<
    { tutor: Tutor },
    { tutorId: string }
  >(FIND_TUTOR_BY_ID, { fetchPolicy: 'no-cache' })

  function findTutorById(tutorId: string) {
    return findTutorByIdGql({
      variables: { tutorId },
    })
  }

  return { findTutorById, loading, data, error }
}

const FIND_TUTOR_BY_ID = gql`
  query Tutor($tutorId: String!) {
    tutor(tutorId: $tutorId) {
      ${tutorStringFull}
    }
  }
`
