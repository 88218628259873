export enum LearningDaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const GradeLevelList: string[] = [
  'KG',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'COLLEGE',
  'ADULT',
]

export enum ContractStatusEnum {
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ASSIGNED = 'ASSIGNED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  PAUSED = 'PAUSED',
  CANCELED = 'CANCELED',
  HAS_ERROR = 'HAS_ERROR',
  ALL = 'ALL',
}

export enum ContractStatusUpdateEnum {
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ASSIGNED = 'ASSIGNED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  PAUSED = 'PAUSED',
  CANCELED = 'CANCELED',
  HAS_ERROR = 'HAS_ERROR',
}

export enum ContractTypeEnum {
  COMMISSION = 'COMMISSION',
  FIXED = 'FIXED',
}

export enum ContractCreationEnum {
  NEW = 'NEW',
  RENEWED = 'RENEWED',
  TUTOR_CHANGED = 'TUTOR_CHANGED',
}

export enum ContractGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  BOTH = 'BOTH',
}
