import { gql, useLazyQuery } from '@apollo/client'
import { TutorApplicationStatusEnum } from '../../app/prospect_tutors/entities/tutor_prospects.enum'

export function useProspectTutorsData() {
  const [prospectTutorsDataGql, { loading, data, error }] = useLazyQuery<
    { advancedProspectTutorsAnalytics: JSON },
    {
      groupBy: string
      statuses: TutorApplicationStatusEnum[]
      startDate: Date
      endDate: Date
    }
  >(PROSPECT_TUTORS_DATA)

  function prospectTutorsData(
    groupBy: string,
    statuses: TutorApplicationStatusEnum[],
    startDate: Date,
    endDate: Date,
  ) {
    return prospectTutorsDataGql({
      variables: {
        groupBy,
        statuses,
        startDate,
        endDate,
      },
    })
  }

  return { prospectTutorsData, loading, data, error }
}

const PROSPECT_TUTORS_DATA = gql`
  query Query(
    $groupBy: String!
    $statuses: [TutorApplicationStatusEnum!]!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    advancedProspectTutorsAnalytics(
      groupBy: $groupBy
      statuses: $statuses
      startDate: $startDate
      endDate: $endDate
    )
  }
`
