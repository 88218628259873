import {
  ContractGenderEnum,
  ContractStatusEnum,
} from '../../contracts/entities/contract.enums'
import { Alert, Box, Button, Grid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SearchTutorsCard from '../../search_tutors/components/search-tutors-card'
import {
  findInitSortState,
  findTutorInitialState,
  useFindTutors,
} from '../../search_tutors/hooks/use-find-tutors.hook'
import SortTutorsCard from '../../search_tutors/components/sort-tutors-card'
import TutorListCard from '../../search_tutors/components/tutor-list-card'
import TablePaginationComponent from '../../components/pagination/table-paginaiton-component'
import React, { useEffect, useState } from 'react'
import {
  FindTutorsDateInputs,
  FindTutorsInput,
} from '../../search_tutors/dto/find-tutors.input'
import { SortTutorsInput } from '../../tutors/dto/sort-tutors.input'
import { Contract } from '../../contracts/entities/contract.entity'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import { UserGenderEnum, UserStatusEnum } from '../../users/entities/user.enums'
import { UserLocationInput } from '../../../common/location/location.entity'
import { PaginationInput } from '../../../common/pagination/pagination'
import dayjs from 'dayjs'

interface SearchTutorSectionProps {
  contract: Contract
  findContractLoading: boolean

  handleGetContracts(): void
}

const SearchTutorSection: React.FC<SearchTutorSectionProps> = ({
  findContractLoading,
  handleGetContracts,
  contract,
}) => {
  const {
    findTutors,
    loading: findTutorsLoading,
    data: findTutorsData,
    error: findTutorsError,
  } = useFindTutors()

  const [opened, setOpened] = useState<boolean>(false)
  const [searchNow, setSearchNow] = useState<boolean>(false)
  const [userLocationInput, setUserLocationInput] = useState<
    UserLocationInput | null | undefined
  >(null)
  const [currentSearchState, setCurrentSearchState] =
    useState<FindTutorsInput>(findTutorInitialState)
  const [currentSortState, setCurrentSortState] = useState<SortTutorsInput>(findInitSortState)

  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    skip: 0,
    limit: 10,
  })

  const [dateStates, setDateStates] = useState<FindTutorsDateInputs>({
    createdAt: {
      gte: dayjs('2019-01-01').toDate(),
      lte: new Date(),
    },
  })

  const handleLoadTutors = (
    searchState: FindTutorsInput | null,
    sortState: SortTutorsInput | null,
  ) => {
    const search = searchState !== null ? searchState : currentSearchState
    const sort = sortState !== null ? sortState : currentSortState
    findTutors({
      currentSearchState: {
        ...search,
        location: {
          ...userLocationInput,
          __typename: undefined,
        } as unknown as UserLocationInput,
      },
      currentSortState: sort ? sort : currentSortState,
      paginationInput,
    })
      .then()
      .catch(e => e)
  }

  useEffect(() => {
    if (searchNow) handleLoadTutors(currentSearchState, currentSortState)
  }, [paginationInput])

  const handleSearchTutors = (searchState: FindTutorsInput) => {
    handleLoadTutors(searchState, currentSortState)
    setCurrentSearchState(searchState)
  }

  const handleSortTutors = (sortState: SortTutorsInput) => {
    handleLoadTutors(currentSearchState, sortState)
    setCurrentSortState(sortState)
  }

  useEffect(() => {
    if (contract) {
      if (contract.status !== ContractStatusEnum.ACCEPTED)
        setUserLocationInput({
          ...contract.customer.location,
        })
      setCurrentSearchState({
        status: UserStatusEnum.ACTIVE,
        serviceTypes: [ServiceTypeEnum.IN_PERSON],
        subjects: [
          // @ts-ignore
          ...new Set(contract.children?.map(j => j.subjects).flat(2)),
        ],
        gradeLevels: [
          // @ts-ignore
          ...new Set(contract.children?.map(j => j.gradeLevel).flat(2)),
        ],
        gender:
          contract.tutorGender === ContractGenderEnum.BOTH
            ? undefined
            : (contract.tutorGender as unknown as UserGenderEnum),
        // placeId: contract.location?.placeId,
      })
    }
  }, [contract])

  return (
    <Box>
      {findTutorsLoading && <CircularIndeterminate />}

      {!findContractLoading &&
        contract.status !== ContractStatusEnum.NOT_CONFIRMED &&
        contract.status !== ContractStatusEnum.STARTED &&
        contract.status !== ContractStatusEnum.FINISHED && (
          <>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                endIcon={<SearchIcon />}
                onClick={() => {
                  setOpened(!opened)
                  setSearchNow(true)
                }}
              >
                Search tutor
              </Button>
            </Box>

            <Box my={1} />
            {opened && (
              <>
                <SearchTutorsCard
                  searchTutors={handleSearchTutors}
                  initialState={currentSearchState}
                  setUserLocation={setUserLocationInput}
                  userLocation={userLocationInput}
                  setDateStates={setDateStates}
                  dateStates={dateStates}
                />

                <Box my={1}>
                  <SortTutorsCard
                    sortTutors={handleSortTutors}
                    initialState={findInitSortState}
                  />
                </Box>

                <Box>
                  {findTutorsError && (
                    <Alert severity="error">{findTutorsError.message}</Alert>
                  )}

                  <Box my={2}>
                    <Grid container spacing={2}>
                      {findTutorsData &&
                        findTutorsData.searchTutorsAdmin.items.map(tutor => (
                          <Grid item xs={12} key={tutor.id}>
                            <TutorListCard
                              tutor={tutor}
                              isAssignTutor={true}
                              contract={contract}
                              refetch={handleGetContracts}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>

                <Box>
                  {findTutorsData && (
                    <TablePaginationComponent
                      setPaginationInput={setPaginationInput}
                      pageInfo={findTutorsData.searchTutorsAdmin?.pageInfo}
                    />
                  )}
                </Box>
              </>
            )}
          </>
        )}
    </Box>
  )
}

export default SearchTutorSection
