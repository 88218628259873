import React from 'react'
import { Box, Typography } from '@mui/material'

function ErrorPage() {
  return (
    <Box display="flex" justifyContent="center" height="100vh" alignItems="center">
      <Typography>Error!</Typography>
    </Box>
  )
}

export default ErrorPage
