import { gql, useMutation } from '@apollo/client'
import { UpdatePaymentDailyLogsInputAdmin } from '../entities/daily-log.entity'

const UPDATE_DAILY_LOG = gql`
  mutation UpdateDailyLogsAdmin(
    $updatePaymentDailyLogsInputAdmin: UpdatePaymentDailyLogsInputAdmin!
  ) {
    updateDailyLogsAdmin(updatePaymentDailyLogsInputAdmin: $updatePaymentDailyLogsInputAdmin) {
      id
    }
  }
`

export function useUpdateDailyLog() {
  const [updateDailyLogGql, { loading, data, error }] = useMutation<
    { updateDailyLogsAdmin: { id: string } },
    { updatePaymentDailyLogsInputAdmin: UpdatePaymentDailyLogsInputAdmin }
  >(UPDATE_DAILY_LOG)

  function updateDailyLog(updatePaymentDailyLogsInputAdmin: UpdatePaymentDailyLogsInputAdmin) {
    return updateDailyLogGql({
      variables: { updatePaymentDailyLogsInputAdmin },
    })
  }

  return { updateDailyLog, loading, data, error }
}
