import { UserGenderEnum, UserStatusEnum } from '../../users/entities/user.enums'
import { User, userStringFull } from '../../users/entities/user.entity'
import { CustomerTypeEnum } from './customer.enums'
import { locationStringFull, UserLocation } from '../../../common/location/location.entity'

export interface Customer {
  id: string
  fullName: string
  phoneNumber: string
  email?: string
  gender: UserGenderEnum
  educationLevel?: string
  status: UserStatusEnum
  customerType: CustomerTypeEnum
  location: UserLocation
  createdBy?: string
  userId: string
  user?: User
  createdAt: Date
  updatedAt: Date
}

export const customerStringFull = `
  id
  fullName
  phoneNumber
  email
  createdById
  customerType
  educationLevel
  gender
  status
  userId
  user {
    ${userStringFull}
  }
  location{
   ${locationStringFull}
  }
  createdAt
  updatedAt
`

export const customerStringMini = `
  id
  fullName
  phoneNumber
  email
  createdBy
  customerType
  educationLevel
  gender
  status
  userId
  location {
    ${locationStringFull}
  }
  createdAt
  updatedAt
`
