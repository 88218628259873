import React, { useEffect } from 'react'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Rating,
  Typography,
} from '@mui/material'
import { Tutor } from '../../tutors/entities/tutor.entity'
import pic from '../../../assets/img/pic.webp'
import { useAssignTutor, useRemoveTutor } from '../../tutors/hooks/use-assign-tutor.hook'
import { ContractStatusEnum } from '../../contracts/entities/contract.enums'
import { Contract } from '../../contracts/entities/contract.entity'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { UserStatusEnum } from '../../users/entities/user.enums'

interface TutorCardProps {
  tutor: Tutor
  isAssignTutor?: boolean
  contract?: Contract

  refetch?(): void
}

const TutorListCard: React.FC<TutorCardProps> = ({
  tutor,
  isAssignTutor,
  contract,
  refetch,
}) => {
  const {
    assignTutor,
    loading: assignTutorLoading,
    data: assignTutorData,
    error: assignTutorError,
  } = useAssignTutor()
  const {
    removeTutor,
    loading: removeTutorLoading,
    data: removeTutorData,
    error: removeTutorError,
  } = useRemoveTutor()

  useEffect(() => {
    if ((assignTutorData || removeTutorData) && refetch) {
      refetch()
    }
  }, [assignTutorData, removeTutorData])

  return (
    <Card>
      {(assignTutorLoading || removeTutorLoading) && <CircularIndeterminate />}

      {assignTutorError && <Alert severity="error">{assignTutorError.message}</Alert>}
      {removeTutorError && <Alert severity="error">{removeTutorError.message}</Alert>}

      <CardContent>
        <Box display="flex" mb={1}>
          <Box>
            <Avatar
              alt="profile_pic"
              src={tutor.profilePic ? tutor.profilePic : pic}
              sx={{ width: 56, height: 56 }}
            />
            <Rating
              name="half-rating-read"
              defaultValue={tutor.rating}
              precision={0.5}
              readOnly
              size="small"
            />
          </Box>
          <Box ml={1} flexGrow={1}>
            <Box display="flex" justifyContent="space-between" flexWrap="nowrap">
              <Typography variant="h6" noWrap>
                {`${tutor.fullName.split(' ')[0]} ${tutor.fullName.split(' ')[1]}`}
              </Typography>
              <Box ml={1} />
              <Box justifyContent="center" alignContent="center" alignItems="center">
                {isAssignTutor &&
                  contract?.id &&
                  contract.status &&
                  (contract.status === ContractStatusEnum.CONFIRMED ||
                  contract.status === ContractStatusEnum.REJECTED ||
                  contract.status === ContractStatusEnum.ASSIGNED ? (
                    contract.tutorId === tutor.id ? (
                      <Box>
                        <Button
                          color="error"
                          onClick={() => removeTutor(contract.id, tutor.id)}
                        >
                          Remove
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <Button onClick={() => assignTutor(contract.id, tutor.id)}>
                          Assign
                        </Button>
                      </Box>
                    )
                  ) : (
                    ''
                  ))}
                <Box>
                  <Typography
                    color={tutor.status === UserStatusEnum.ACTIVE ? 'green' : 'red'}
                    fontWeight="bold"
                    fontSize="smaller"
                    align="center"
                  >
                    {tutor.status}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexWrap="wrap">
              <Typography fontSize="small">{tutor.phoneNumber}</Typography>
              <Box mx={1} />
              <Typography fontSize="small">{tutor.email}</Typography>
            </Box>

            <Box display="flex">
              <Typography fontWeight="lighter" fontSize={12}>
                Clients
              </Typography>
              <Typography fontWeight="lighter" fontSize={12} mx={1}>
                :
              </Typography>
              <Typography fontWeight="lighter" fontSize={12}>
                {tutor.currentClients}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" sx={{ textOverflow: 'ellipsis' }}>
          <Typography fontWeight="bold">Grade</Typography>
          <Typography mx={1}>:</Typography>
          <Box display="flex" sx={{ textOverflow: 'ellipsis' }}>
            {tutor.gradeLevels.map(g => (
              <Box key={g} mr={1}>
                <Typography>{g.toString().toLowerCase()}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex">
          <Typography fontWeight="bold" noWrap>
            Services
          </Typography>
          <Typography mx={1}>:</Typography>
          <Box display="flex" sx={{ textOverflow: 'ellipsis' }}>
            {tutor.serviceTypes.map(g => (
              <Box key={g} mr={1}>
                <Typography>{g.toString().toLowerCase()}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex">
          <Typography fontWeight="bold">Subjects</Typography>
          <Typography mx={0.5}>:</Typography>
          <Box display="flex">
            {tutor.subjects.map(g => (
              <Box key={g} mr={1}>
                <Typography noWrap>{g.toString().toLowerCase()}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex">
          <Typography fontWeight="bold">locations</Typography>
          <Typography mx={0.5}>:</Typography>
          <Box>
            {tutor.locationDetails.map(ld => (
              <Typography
                sx={{
                  borderRadius: 2,
                  border: 'solid 1px lightgrey',
                }}
                key={ld.id}
                m={0.3}
                p={1}
              >
                {ld.streetName}
              </Typography>
            ))}
          </Box>
        </Box>
      </CardContent>
      <Link href={`/tutor/${tutor.id}`} style={{ textDecoration: 'none', color: 'black' }}>
        <Typography variant="button">Detail</Typography>
      </Link>
    </Card>
  )
}

export default TutorListCard
