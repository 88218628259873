import { ContractStatusEnum, ContractStatusUpdateEnum } from '../entities/contract.enums'
import StatusToggleButton from '../../components/inputs/status-toggle-button'
import React, { useState } from 'react'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Alert, Box, Button, Typography } from '@mui/material'
import { Contract } from '../entities/contract.entity'
import { useUpdateContractStatus } from '../hooks/use-update-contract-status.hook'
import Dialog from '@mui/material/Dialog'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'

const Reasons = [
  'no tutor found',
  'customer asked to cancel',
  'customer did not respond',
  'customer went with another tutoring service',
  'customer did not agree with the tutor',
  'customer has more requirements',
  'inconvenient access to transportation',
  'customer relocated',
  'duplicate',
  'test',
]

interface UpdateContractStatusProps {
  contract: Contract

  refetch(): void
}

const UpdateContractStatus: React.FC<UpdateContractStatusProps> = ({ contract, refetch }) => {
  const { updateContractStatus, loading, error, data } = useUpdateContractStatus()

  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<ContractStatusEnum>(contract.status)
  const [cancellationReason, setCancellationReason] = useState('')

  return (
    <Box>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}

      <Dialog open={open}>
        <Box px={3} py={2}>
          <Typography>
            Are you sure you want to change the status to "{selectedStatus.toString()}"?
          </Typography>
          {error && <Alert severity="error">{error.message}</Alert>}
          <form
            onSubmit={e => {
              e.preventDefault()
              updateContractStatus({
                contractId: contract.id,
                status: selectedStatus,
                cancellationReasons:
                  cancellationReason !== ''
                    ? [
                        {
                          description: cancellationReason,
                        },
                      ]
                    : [],
              })
                .then(() => {
                  refetch()
                  setOpen(false)
                })
                .catch(e => e)
            }}
          >
            <Box px={3} py={2}>
              {selectedStatus === ContractStatusEnum.CANCELED && (
                <SelectInputField
                  id="cancellationReasons"
                  value={cancellationReason}
                  label="Cancellation Reasons"
                  name="cancellationReasons"
                  required={true}
                  onChange={e => setCancellationReason(e.target.value)}
                  menuItems={
                    Reasons.map(value => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              )}
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                type="button"
                onClick={() => {
                  setOpen(false)
                  setSelectedStatus(contract.status)
                }}
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <StatusToggleButton
        options={Object.entries(ContractStatusUpdateEnum).map(([_, value]) => value)}
        clicked={s => {
          setOpen(true)
          setSelectedStatus(s as ContractStatusEnum)
        }}
        selected={selectedStatus}
      />
    </Box>
  )
}

export default UpdateContractStatus
