import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Box, Button } from '@mui/material'
import CustomerInfoCard from './components/customer-info-card'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useFindCustomer } from './hooks/use-find-customers'
import SearchContractsPage from '../search_contracts/search-contracts-page'
import { CircularIndeterminate } from '../components/preloader/preloader'
import AddContractDialog from '../contract_detail/components/add-contract-dialog'

const CustomerDetailPage: React.FC = () => {
  const {
    findCustomer,
    loading: findCustomerLoading,
    data: findCustomerData,
    error: findCustomerError,
  } = useFindCustomer()

  const [openAddContract, setOpenAddContract] = useState(false)

  const { id } = useParams()

  const handleGetCustomer = () => {
    findCustomer(id ? id?.toString() : '')
      .then()
      .catch(e => e)
  }

  useEffect(() => {
    handleGetCustomer()
  }, [])

  return (
    <Box px={1} mb={5}>
      {findCustomerLoading && <CircularIndeterminate />}
      {findCustomerError && <Alert severity="error">{findCustomerError?.message}</Alert>}

      {findCustomerData && (
        <>
          <CustomerInfoCard customer={findCustomerData.customer} refetch={handleGetCustomer} />
          <Box my={2} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              endIcon={<AddCircleIcon />}
              onClick={() => setOpenAddContract(true)}
            >
              Add Contract
            </Button>
          </Box>

          <SearchContractsPage
            customerId={id}
            customerType={findCustomerData.customer.customerType}
          />

          <AddContractDialog
            customer={findCustomerData.customer}
            refetch={handleGetCustomer}
            openAddContract={openAddContract}
            setOpenAddContract={setOpenAddContract}
          />
        </>
      )}
    </Box>
  )
}
export default CustomerDetailPage
