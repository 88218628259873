import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { NavLink } from 'react-router-dom'
import { Child } from '../../contracts/entities/child.entity'
import { Contract } from '../../contracts/entities/contract.entity'

interface ChildCardMiniProps {
  child: Child
  contract: Contract
}

const ChildCardMini: React.FC<ChildCardMiniProps> = ({ child, contract }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box key={child.id} my={1}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box display="flex">
            <Typography fontWeight="bold">Grade</Typography>
            <Box mx={1}>:</Box>
            <Typography>{child.gradeLevel}</Typography>
            <Box mx={2} />
            <Typography fontWeight="bold">{child.tutorRate}/hr</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <NavLink to={`/contract/${contract.id}/child/${child?.id}`}>
            <Typography noWrap sx={{ color: 'text.secondary' }}>
              {child.studentName !== '' ? child.studentName : 'Student name'}
            </Typography>
          </NavLink>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default ChildCardMini
