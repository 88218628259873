import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import NoteCard from '../../contract_detail/components/note-card'
import { Contract } from '../../contracts/entities/contract.entity'
import { Payment } from '../../payments/entities/payment.entity'
import AddNoteDialog from './add-note-dialog'
import { ProspectTutor } from '../../prospect_tutors/entities/prospect-tutor.entity'
import { Note } from '../entity/notes.entity'

interface AddNoteCardProps {
  contract?: Contract
  payment?: Payment
  prospectTutor?: ProspectTutor

  refetch(): void
}

const AddNoteCard: React.FC<AddNoteCardProps> = ({
  contract,
  payment,
  prospectTutor,
  refetch,
}) => {
  const [openAddNote, setOpenDialogs] = useState<boolean>(false)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const [notes, setNotes] = useState<Note[]>([])

  useEffect(() => {
    if (contract) {
      setNotes(contract?.notes)
    } else if (payment) {
      setNotes(payment?.notes)
    } else if (prospectTutor) {
      setNotes(prospectTutor?.notes)
    }
  }, [contract, payment, prospectTutor])

  return (
    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        {notes.length > 0 ? (
          <Typography fontSize="small">
            Last Conversations
            <Badge
              badgeContent={notes.length}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              color="error"
            />
          </Typography>
        ) : (
          <Typography fontSize="small">No Last Conversations</Typography>
        )}

        <Box flexGrow={1} />
        <Button
          startIcon={<AddCircleIcon />}
          onClick={() => {
            setOpenDialogs(true)
          }}
          size="small"
        >
          Add Note
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        {notes &&
          notes.length > 0 &&
          notes.map(reason => <NoteCard key={reason.id} note={reason} />)}
      </AccordionDetails>

      <AddNoteDialog
        openAddNote={openAddNote}
        setOpenAddNote={setOpenDialogs}
        contract={contract}
        payment={payment}
        prospectTutor={prospectTutor}
        refetch={refetch}
      />
    </Accordion>
  )
}

export default AddNoteCard
