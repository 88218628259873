import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'

interface NavigationLinkProps {
  to: string
  option: any
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ to, option }) => {
  const theme = useTheme()
  const location = useLocation()
  const colored: boolean = location.pathname === to

  return (
    <NavLink to={to} style={{ textDecoration: 'none', color: 'black' }}>
      <ListItemButton
        style={{
          backgroundColor: colored ? theme.palette.secondary.light : '',
          borderRadius: '5px',
        }}
      >
        <ListItemIcon sx={{ color: colored ? 'white' : 'grey' }}>{option.icon}</ListItemIcon>
        <ListItemText>
          <Typography
            fontWeight={`${colored ? 'bold' : 'normal'}`}
            color={`${colored ? theme.palette.primary.main : '#3f4e56'}`}
          >
            {option.primary}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </NavLink>
  )
}

export default NavigationLink
