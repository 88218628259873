import React from 'react'
import { Box, Button, Card, CardContent, Grid, Link, Modal, Typography } from '@mui/material'
import { Payment } from '../../payments/entities/payment.entity'
import UpdatePaymentStatus from '../../payments/components/update-payment-status'
import moment from 'moment'
import AddNoteCard from '../../notes/components/add-note-card'
import PaymentDetailDialog from './payment-detail-dialog'

interface PaymentCardProps {
  payment: Payment

  refetch(): void
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  p: 4,
}

const PaymentCardList: React.FC<PaymentCardProps> = ({ payment, refetch }) => {
  const [open, setOpen] = React.useState(false)
  const [image, setImage] = React.useState<string | undefined>('')
  const [openDetail, setOpenDetail] = React.useState(false)
  return (
    <Card>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img style={{ width: '100%' }} src={image} alt="" />
        </Box>
      </Modal>
      <PaymentDetailDialog open={openDetail} handleClose={setOpenDetail} payment={payment} />

      <CardContent>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <Typography fontSize="small">
              End date: ({payment?.endDate && moment(new Date(payment.endDate)).fromNow()})
            </Typography>
            <Typography fontSize="small">
              Last update: (
              {payment?.statusUpdatedAt && moment(new Date(payment.statusUpdatedAt)).fromNow()}
              )
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" fontWeight="bold">
            {payment.month}
          </Typography>
          <Box display="flex">
            <UpdatePaymentStatus payment={payment} refetch={refetch} />
          </Box>
        </Box>

        <Card>
          <Box px={2} py={1}>
            <Link
              href={`/contract/${payment.contract?.id}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Typography fontSize="small">Customer Info</Typography>
              <Typography fontWeight="bold">{payment.contract?.fullName}</Typography>
              <Typography>{payment.contract?.phoneNumber}</Typography>
            </Link>
          </Box>
        </Card>
        <Box my={1} />
        <Card>
          <Box px={2} py={1}>
            <Link
              href={`/tutor/${payment.contract?.tutor?.id}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Typography fontSize="small">Tutor Info</Typography>
              <Typography fontWeight="bold">{payment.contract?.tutor?.fullName}</Typography>
              <Typography>{payment.contract?.tutor?.phoneNumber}</Typography>
            </Link>
          </Box>
        </Card>

        <Grid container my={1} spacing={1}>
          <Grid item xs={12}>
            {/*<Box display='flex'>*/}
            {/*  <Typography noWrap fontWeight='bold'>*/}
            {/*    Parent's Total payment*/}
            {/*  </Typography>*/}
            {/*  <Box mx={0.5}>:</Box>*/}
            {/*  <Typography>{payment.totalDepositedParent}</Typography>*/}
            {/*</Box>*/}
            <Box display="flex">
              <Typography noWrap fontWeight="bold">
                Tutor's Total payment
              </Typography>
              <Box mx={0.5}>:</Box>
              <Typography>{payment.totalDepositedTutor}</Typography>
            </Box>
            <Box display="flex">
              <Typography noWrap fontWeight="bold">
                Total hours
              </Typography>
              <Box mx={1}>:</Box>
              <Typography>{payment.totalHours}</Typography>
            </Box>
            <Box display="flex">
              <Typography noWrap fontWeight="bold">
                Net tutor payment
              </Typography>
              <Typography mx={1}>:</Typography>
              <Typography>{payment.netTutorPayment}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold" noWrap>
                Start date
              </Typography>
              <Typography mx={1}>:</Typography>
              <Typography noWrap>
                {payment?.startDate && new Date(payment.startDate).toISOString().split('T')[0]}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography fontWeight="bold" noWrap>
                Net commission
              </Typography>
              <Typography mx={1}>:</Typography>
              <Typography>{payment.netCommission}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold" noWrap>
                Payable commission by tutor
              </Typography>
              <Typography mx={1}>:</Typography>
              <Typography>{payment.payableCommission}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold" noWrap>
                Total commission
              </Typography>
              <Typography mx={1}>:</Typography>
              <Typography>{payment.commission}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold" noWrap>
                TOT
              </Typography>
              <Typography mx={1}>:</Typography>
              <Typography>{payment.tot}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold">Expected End date</Typography>
              <Typography mx={1}>:</Typography>
              <Typography>
                {payment?.endDate && new Date(payment.endDate).toISOString().split('T')[0]}
              </Typography>
            </Box>
            {payment.receiptImage && (
              <Grid item xs={12}>
                <Typography noWrap fontSize="small">
                  Receipt
                </Typography>
                <Typography noWrap>
                  <Button
                    onClick={() => {
                      setOpen(true)
                      setImage(payment.receiptImage)
                    }}
                  >
                    View Receipt
                  </Button>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Button color="info" onClick={() => setOpenDetail(true)}>
        Detail
      </Button>
      <AddNoteCard payment={payment} refetch={refetch} />
    </Card>
  )
}

export default PaymentCardList
