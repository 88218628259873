import React from 'react'
import { Typography } from '@mui/material'

const FormLabel = ({ name }: { name: string }) => (
  <Typography variant="h6" color="gray" fontWeight="bold" fontSize="medium">
    {name}
  </Typography>
)

export default FormLabel
