import React from 'react'
import { Avatar, Box, List, Typography } from '@mui/material'
import {
  customersNavOptions,
  jobsNavOptions,
  paymentsNavOptions,
  sendSmsOptions,
  settingsNavOptions,
  tutorRecruitmentNavOptions,
  tutorsNavOptions,
} from './menu-list'
import NavigationLink from './navigation-link'
// @ts-ignore
import avatar from '../../assets/img/haleta_logo.png'
import { useAuth } from '../../context/auth_context'

const Menus = () => {
  const auth = useAuth()

  return (
    <Box m={1}>
      <Box display="flex" justifyContent="center" my={3}>
        <Avatar alt="haleta_logo" src={avatar} sx={{ width: 100, height: 100 }} />
      </Box>
      <List>
        <Box mx={2}>
          <Typography variant="h6" color="gray" fontWeight="bold" fontSize="small">
            TUTORS
          </Typography>
        </Box>
        {tutorsNavOptions.map(option => (
          <NavigationLink key={option.link} to={`/${option.link}`} option={option} />
        ))}
        <Box mx={2} mt={2}>
          <Typography variant="h6" color="gray" fontWeight="bold" fontSize="small">
            CONTRACTS
          </Typography>
        </Box>
        {jobsNavOptions.map(option => (
          <NavigationLink key={option.link} to={`/${option.link}`} option={option} />
        ))}
        <Box mx={2} mt={2}>
          <Typography variant="h6" color="gray" fontWeight="bold" fontSize="small">
            PAYMENTS
          </Typography>
        </Box>
        {paymentsNavOptions.map(option => (
          <NavigationLink key={option.link} to={`/${option.link}`} option={option} />
        ))}
        <Box mx={2} mt={2}>
          <Typography variant="h6" color="gray" fontWeight="bold" fontSize="small">
            NEW TUTORS
          </Typography>
        </Box>
        {tutorRecruitmentNavOptions.map(option => (
          <NavigationLink key={option.link} to={`/${option.link}`} option={option} />
        ))}
        <Box mx={2} mt={2}>
          <Typography variant="h6" color="gray" fontWeight="bold" fontSize="small">
            USERS
          </Typography>
        </Box>
        {customersNavOptions.map(option => (
          <NavigationLink key={option.link} to={`/${option.link}`} option={option} />
        ))}
        <Box mx={2} mt={2}>
          <Typography variant="h6" color="gray" fontWeight="bold" fontSize="small">
            SMS
          </Typography>
        </Box>
        {sendSmsOptions.map(option => (
          <NavigationLink key={option.link} to={`/${option.link}`} option={option} />
        ))}

        {auth?.authState?.role === 'SUPER_ADMIN' && (
          <>
            <Box mx={2} mt={2}>
              <Typography variant="h6" color="gray" fontWeight="bold" fontSize="small">
                SETTINGS
              </Typography>
            </Box>
            {settingsNavOptions.map(option => (
              <NavigationLink key={option.link} to={`/${option.link}`} option={option} />
            ))}
          </>
        )}
      </List>
    </Box>
  )
}

export default Menus
