import React from 'react'
import CreateIcon from '@mui/icons-material/Create'
import SearchIcon from '@mui/icons-material/Search'
import PercentIcon from '@mui/icons-material/Percent'
import PaymentIcon from '@mui/icons-material/Payment'
import JoinInnerIcon from '@mui/icons-material/JoinInner'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined'
import { ListRounded, Payments } from '@mui/icons-material'

export const tutorsNavOptions = [
  {
    link: 'tutors',
    primary: 'Search Tutors',
    icon: <SearchIcon />,
  },
]

export const jobsNavOptions = [
  {
    link: 'contract/create',
    primary: 'Create Contract',
    icon: <CreateIcon />,
  },
  {
    link: 'contracts',
    primary: 'Contract List',
    icon: <SearchIcon />,
  },
]

export const paymentsNavOptions = [
  {
    link: 'payments/upcoming',
    primary: 'Upcoming',
    icon: <Payments />,
  },
  {
    link: 'payments',
    primary: 'Payment List',
    icon: <PaymentIcon />,
  },
]

export const customersNavOptions = [
  {
    link: 'users',
    primary: 'All Users',
    icon: <SearchIcon />,
  },
]

export const tutorRecruitmentNavOptions = [
  {
    link: 'prospect_tutors',
    primary: 'Recruitment',
    icon: <JoinInnerIcon />,
  },
]

export const settingsNavOptions = [
  {
    link: 'settings/payment-ranges',
    primary: 'Payment ranges',
    icon: <PercentIcon />,
  },
  {
    link: 'settings/static-data',
    primary: 'Static Data',
    icon: <ListRounded />,
  },
]

export const sendSmsOptions = [
  {
    link: 'sendsms',
    primary: 'Send Sms',
    icon: <TextsmsOutlinedIcon />,
  },
]
