import React from 'react'
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import { User } from '../entities/user.entity'
import UpdateUserDialog from './update-user-dialog'
import UserDetailDialog from './user-detail-dialog'
import { NavLink } from 'react-router-dom'

interface UserListCardProps {
  user: User

  refetch(): void
}

const UserListCard: React.FC<UserListCardProps> = ({ user, refetch }) => {
  const [openEdit, setOpenEdit] = React.useState(false)
  const [openDetail, setOpenDetail] = React.useState(false)

  return (
    <Card>
      <UpdateUserDialog
        open={openEdit}
        handleClose={setOpenEdit}
        user={user}
        refetch={refetch}
      />

      <UserDetailDialog open={openDetail} handleClose={setOpenDetail} user={user} />

      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight="bold" noWrap fontSize={18}>
            {user.tutorId ? (
              <NavLink
                style={{ textDecoration: 'none', color: 'black' }}
                to={`/tutor/${user.tutorId}`}
              >
                {user?.fullName}
              </NavLink>
            ) : user.customerId ? (
              <NavLink
                style={{ textDecoration: 'none', color: 'black' }}
                to={`/customer/${user.customerId}`}
              >
                {user?.fullName}
              </NavLink>
            ) : (
              user?.fullName
            )}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography>{user?.phoneNumber}</Typography>
        </Box>
        <Box display="flex">
          <Typography>{user?.email}</Typography>
        </Box>
        <Box display="flex">
          <Typography fontWeight="bold" noWrap>
            User type
          </Typography>
          <Typography mx={1}>:</Typography>
          <Typography>{user?.role}</Typography>
        </Box>
        <Box display="flex">
          <Typography fontWeight="bold" noWrap>
            Registration Date
          </Typography>
          <Typography mx={1}>:</Typography>
          <Typography noWrap>{user.createdAt.toString().split('T')[0]}</Typography>
        </Box>
      </CardContent>

      <CardActionArea>
        <Box display="flex" justifyContent="space-between" px={2} py={1}>
          <Typography variant="button" color="deepskyblue" onClick={() => setOpenDetail(true)}>
            Detail
          </Typography>

          <Typography variant="button" color="deepskyblue" onClick={() => setOpenEdit(true)}>
            Edit
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default UserListCard
