import { Alert, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Chart from './chart'
import { useCustomersData } from '../hooks/use-customers-data.hook'
import { UserStatusEnum } from '../../app/users/entities/user.enums'
import { SelectMenuItem } from '../../app/components/inputs/select-input-field'
import SelectInputFieldCheckbox from '../../app/components/inputs/select-input-field-checkbox'
import { setDefaultValues } from '../../functions/filter-objects'

const CustomersData = () => {
  const { customersData, data, error } = useCustomersData()

  const [statuses, setStatuses] = useState([UserStatusEnum.ACTIVE])
  const [response, setResponse] = useState<null | JSON[]>(null)

  useEffect(() => {
    if (data) {
      setResponse(setDefaultValues(data.advancedCustomersAnalytics, statuses))
    }
  }, [data])

  return (
    <Box px={3} py={5} my={2}>
      {error && <Alert severity="error">{error.message}</Alert>}

      <Typography variant="h5">Customers</Typography>

      <Box my={5}>
        <SelectInputFieldCheckbox
          id="statuses"
          value={statuses}
          label="Statuses"
          name="statuses"
          onChange={e => {
            setStatuses(e.target.value)
          }}
          multiple
          menuItems={
            Object.entries(UserStatusEnum).map(([key, value]) => ({
              name: key,
              value,
            })) as SelectMenuItem[]
          }
        />
      </Box>

      <Chart data={response} getData={customersData} defaultStatuses={statuses} />
    </Box>
  )
}

export default CustomersData
