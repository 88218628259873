import { UserGenderEnum, UserRoleEnum, UserStatusEnum } from './user.enums'
import { UserLocation } from '../../../common/location/location.entity'

export interface User {
  id: string
  fullName: string
  phoneNumber: string
  email?: string
  password?: string
  location?: UserLocation
  gender: UserGenderEnum
  status: UserStatusEnum
  role: UserRoleEnum
  tutorId?: string
  customerId?: string
  createdAt: Date
  updatedAt: Date
}

export const userStringFull = `
  createdAt
  customerId
  email
  fullName
  gender
  id
  phoneNumber
  role
  status
  tutorId
  updatedAt
`
