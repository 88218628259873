import { gql, useLazyQuery } from '@apollo/client'
import { UserStatusEnum } from '../../app/users/entities/user.enums'

export function useCustomersData() {
  const [customersDataGql, { loading, data, error }] = useLazyQuery<
    { advancedCustomersAnalytics: JSON },
    { groupBy: string; statuses: UserStatusEnum[]; startDate: Date; endDate: Date }
  >(CUSTOMERS_DATA)

  function customersData(
    groupBy: string,
    statuses: UserStatusEnum[],
    startDate: Date,
    endDate: Date,
  ) {
    return customersDataGql({
      variables: {
        groupBy,
        statuses,
        startDate,
        endDate,
      },
    })
  }

  return { customersData, loading, data, error }
}

const CUSTOMERS_DATA = gql`
  query Query(
    $groupBy: String!
    $statuses: [UserStatusEnum!]!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    advancedCustomersAnalytics(
      groupBy: $groupBy
      statuses: $statuses
      startDate: $startDate
      endDate: $endDate
    )
  }
`
