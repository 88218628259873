import React from 'react'
import { Backdrop, Box, CircularProgress, Dialog, Paper } from '@mui/material'

export const CircularIndeterminate = () => (
  <Dialog open={true}>
    <Box p={1}>
      <CircularProgress />
    </Box>
  </Dialog>
)

export const PreLoader = () => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <CircularProgress size={20} />
  </Box>
)

export const PreLoaderFull = () => (
  <Backdrop open>
    <CircularProgress color="inherit" />
  </Backdrop>
)

export const InlineLoader = () => (
  <Paper>
    <CircularProgress />
  </Paper>
)

export const InlineCircularProgress = ({ value }: { value: number }) => (
  <Paper>
    <CircularProgress variant="determinate" value={value} />
  </Paper>
)
