import React from 'react'
import { Alert, Box, Button, Grid } from '@mui/material'
import { ContractTypeEnum } from '../../contracts/entities/contract.enums'
import TextInputField from '../../components/inputs/text-input-field'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import { Contract } from '../../contracts/entities/contract.entity'
import { Formik } from 'formik'
import { UserGenderEnum } from '../../users/entities/user.enums'
import Dialog from '@mui/material/Dialog'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import {
  updateContractInitialState,
  useUpdateContract,
} from '../hooks/use-update-contract.hook'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { UserLocationInput } from '../../../common/location/location.entity'
import PlaceDetailSelector from '../../components/google_places/place-detail-selector'

export interface UpdateContractDialogProps {
  contract: Contract
  openContractEdit: boolean

  setOpenContractEdit(val: boolean): void

  refetch(): void
}

const UpdateContractDialog: React.FC<UpdateContractDialogProps> = ({
  contract,
  openContractEdit,
  setOpenContractEdit,
  refetch,
}) => {
  const { updateContract, loading, error } = useUpdateContract()

  const [userLocation, setUserLocation] = React.useState<UserLocationInput>(contract.location)

  return (
    <Dialog open={openContractEdit} fullWidth>
      {loading && <CircularIndeterminate />}

      <Formik
        initialValues={updateContractInitialState(contract)}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          updateContract({
            ...values,
            location: userLocation,
          })
            .then(() => {
              refetch()
              setOpenContractEdit(false)
            })
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box py={1}>
                <SelectInputField
                  id="contractType"
                  value={values.contractType}
                  label="Contract Type"
                  name="contractType"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(ContractTypeEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputField
                  id="tutor-gender"
                  name="tutorGender"
                  value={values.tutorGender}
                  label="Tutor Gender"
                  onChange={e => {
                    handleChange(e)
                  }}
                  menuItems={
                    Object.entries(UserGenderEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputField
                  id="service-type"
                  name="serviceType"
                  value={values.serviceType}
                  label="Service Type"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(ServiceTypeEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputField
                  id="signed"
                  name="signed"
                  value={values.signed}
                  label="Contract Signed"
                  onChange={handleChange}
                  menuItems={
                    (
                      [
                        { name: 'true', value: true },
                        { name: 'false', value: false },
                      ] as any[]
                    ).map(value => ({
                      name: value.name,
                      value: value.value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>
              <Box py={1}>
                <TextInputField
                  name="additionalPhoneNumbers"
                  id="additionalPhoneNumbers"
                  value={values.additionalPhoneNumbers || ''}
                  label="additionalPhoneNumbers"
                  type="tel"
                  onChange={handleChange}
                />
              </Box>
              <Box py={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <TextInputField
                      name="startTime"
                      id="start-time"
                      value={values.startTime}
                      label="Start Time"
                      type="time"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInputField
                      name="endTime"
                      id="end-time"
                      value={values.endTime}
                      label="End Time"
                      type="time"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box py={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <TextInputField
                      name="startDate"
                      id="start-date"
                      value={values.startDate?.split('T')[0]}
                      label="Start Date"
                      type="date"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInputField
                      name="endDate"
                      id="end-date"
                      value={values.endDate?.split('T')[0]}
                      label="End Date"
                      type="date"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box py={1}>
                <PlaceDetailSelector
                  setUserLocation={setUserLocation}
                  userLocation={userLocation}
                />
              </Box>
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenContractEdit(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default UpdateContractDialog
