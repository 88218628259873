import React from 'react'
import { Box, Grid } from '@mui/material'
import TextInputField from '../../components/inputs/text-input-field'
import FormLabel from '../../components/labels/form-label'
import { UserGenderEnum } from '../../users/entities/user.enums'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import { CustomerTypeEnum } from '../../customers/entity/customer.enums'
import { UserLocationInput } from '../../../common/location/location.entity'
import PlaceDetailSelector from '../../components/google_places/place-detail-selector'

interface CustomerInfoProps {
  fullName: string
  phoneNumber: string
  additionalPhoneNumbers: string[]
  parentRate: number
  customerType: CustomerTypeEnum
  customerGender: UserGenderEnum
  userLocation: UserLocationInput

  setAdditionalPhoneNumbers(numbers: string[]): void

  setUserLocation(val: UserLocationInput | null | ((val: UserLocationInput) => void)): void

  handleChange(val: any): void
}

const CreateContractCustomerInfo: React.FC<CustomerInfoProps> = ({
  fullName,
  phoneNumber,
  additionalPhoneNumbers,
  parentRate,
  customerType,
  customerGender,
  userLocation,
  setUserLocation,
  setAdditionalPhoneNumbers,
  handleChange,
}) => (
  <Box py={4}>
    <FormLabel name="Customer Info" />
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box py={1}>
          <TextInputField
            id="fullName"
            name="fullName"
            label="Customer Name"
            value={fullName}
            onChange={handleChange}
          />
        </Box>
        <Box py={1}>
          <TextInputField
            id="phone-number"
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            value={phoneNumber}
            onChange={handleChange}
          />
        </Box>
        <Box py={1}>
          <TextInputField
            id="additionalPhoneNumbers"
            name="additionalPhoneNumbers"
            label="Additional Phone Numbers"
            type="tel"
            required={false}
            value={additionalPhoneNumbers.join(', ')}
            onChange={e =>
              setAdditionalPhoneNumbers(
                e.target.value.split(',').map((num: any) => num.trim()),
              )
            }
          />
        </Box>
        <PlaceDetailSelector setUserLocation={setUserLocation} userLocation={userLocation} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box py={1}>
          <SelectInputField
            id="customerGender"
            value={customerGender}
            label="Gender"
            name="customerGender"
            onChange={handleChange}
            menuItems={
              Object.entries(UserGenderEnum).map(([_, value]) => ({
                name: value.toLowerCase(),
                value,
              })) as SelectMenuItem[]
            }
          />
        </Box>
        <Box py={1}>
          <SelectInputField
            id="customerType"
            value={customerType}
            label="Customer Type"
            name="customerType"
            onChange={handleChange}
            menuItems={
              Object.entries(CustomerTypeEnum).map(([_, value]) => ({
                name: value.toLowerCase(),
                value,
              })) as SelectMenuItem[]
            }
          />
        </Box>
        {customerType === CustomerTypeEnum.ORGANIZATION && (
          <Box py={1}>
            <TextInputField
              id="rate"
              value={parentRate}
              label="Price per Hour"
              name="parentRate"
              type="number"
              onChange={handleChange}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  </Box>
)

export default CreateContractCustomerInfo
