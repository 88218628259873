import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { Contract } from '../../contracts/entities/contract.entity'
import ChildCardMini from './child-card-mini'
import UpdateContractStatus from '../../contracts/components/update-contract-status'
import ReactTimeAgo from 'react-time-ago'
import { ContractStatusEnum } from '../../contracts/entities/contract.enums'
import { Info } from '@mui/icons-material'
import AddNoteCard from '../../notes/components/add-note-card'
import CancellationReasonCard from './cancellation-reason-card'

interface ContractCardMiniProps {
  contract: Contract

  refetch(): void
}

const ContractCardList: React.FC<ContractCardMiniProps> = ({ contract, refetch }) => {
  const location = useLocation()

  return (
    <Paper elevation={1}>
      <Box pt={1} px={1} pb={2} mb={2}>
        <Box display="flex" justifyContent="flex-end">
          <Typography fontSize="small">
            Last update:{' '}
            <ReactTimeAgo date={new Date(contract.statusUpdatedAt)} locale="en-US" />
          </Typography>
        </Box>
        <Box display="flex" alignItems="baseline">
          <NavLink
            to={`/customer/${contract.customer.id}`}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Box maxWidth="250px">
              <Typography variant="h6" noWrap textOverflow="ellipsis">
                {`${contract.customer.fullName}`}
              </Typography>
            </Box>
          </NavLink>
          <Box flex={1} />
          <Tooltip
            title={
              <Box>
                <Typography>{contract.creation} Contract</Typography>
                {contract.createdBy && (
                  <Typography>Created by: {contract.createdBy.fullName}</Typography>
                )}
              </Box>
            }
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>
        </Box>

        <Box mx={2} />
        <Box>
          <Typography fontSize="small">{contract.customer.phoneNumber}</Typography>
        </Box>

        {contract.children?.length >= 1 ? (
          <Box key={contract.children[0].id}>
            <ChildCardMini child={contract.children[0]} contract={contract} />
            {contract.children?.length > 1 &&
              location.pathname !== `/contract/${contract.id}` && (
                <NavLink
                  to={`/contract/${contract.id}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <Typography variant="caption">
                    ({contract.children?.length - 1})...more{' '}
                    {contract.children?.length - 1 > 1 ? 'children' : 'Child'}
                  </Typography>
                </NavLink>
              )}
          </Box>
        ) : (
          ''
        )}

        <Box px={1}>
          <Box display="flex">
            {contract.tutor && (
              <>
                <Typography fontWeight="bold">Tutor</Typography>
                <Box mx={1}>:</Box>
                <Box>
                  <NavLink
                    to={`/tutor/${contract.tutor?.id}`}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Typography>{`${contract.tutor?.fullName.split(
                      ' ',
                    )[0]} ${contract.tutor?.fullName.split(' ')[1]}`}</Typography>
                    <Typography fontSize="small">{contract.tutor?.phoneNumber}</Typography>
                  </NavLink>
                </Box>
              </>
            )}
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Contract Status
            </Typography>
            <Box mx={1}>:</Box>
            <UpdateContractStatus contract={contract} refetch={refetch} />
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">Location</Typography>
            <Box mx={1}>:</Box>
            <Typography>{contract.location?.streetName}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">Additional PhoneNumber</Typography>
            <Box mx={1}>:</Box>
            <Typography>{contract.additionalPhoneNumbers}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">Service Type</Typography>
            <Box mx={1}>:</Box>
            <Typography>{contract.serviceType}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">Last Updated at</Typography>
            <Box mx={1}>:</Box>
            <Typography>{new Date(contract.updatedAt).toISOString().split('T')[0]}</Typography>
          </Box>
          {contract.status === ContractStatusEnum.FINISHED && (
            <Box display="flex">
              <Typography fontWeight="bold">End date</Typography>
              <Box mx={1}>:</Box>
              <Typography>{new Date(contract.endDate).toISOString().split('T')[0]}</Typography>
            </Box>
          )}
          <Box pt={2} display="flex" justifyContent="flex-end">
            {location.pathname !== `/contract/${contract.id}` && (
              <NavLink
                to={`/contract/${contract.id}`}
                style={{ textDecoration: 'none', color: 'goldenrod' }}
              >
                <Typography variant="button">show more</Typography>
              </NavLink>
            )}
          </Box>
        </Box>
      </Box>

      <Box mt={1} />
      <AddNoteCard contract={contract} refetch={refetch} />
      {contract.status === ContractStatusEnum.CANCELED && (
        <CancellationReasonCard contract={contract} />
      )}
    </Paper>
  )
}

export default ContractCardList
