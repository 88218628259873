import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NoteCard from '../../contract_detail/components/note-card'
import { Contract } from '../../contracts/entities/contract.entity'

const CancellationReasonCard = ({ contract }: { contract: Contract }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3bh-content"
        id="panel3bh-header"
      >
        <Typography>Cancellation Reasons</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {contract.cancellationReasons &&
          contract.cancellationReasons.map(reason => (
            <NoteCard key={reason.id} note={reason} />
          ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default CancellationReasonCard
