import React, { useEffect, useState } from 'react'
import { Alert, Box } from '@mui/material'
import { CircularIndeterminate } from '../components/preloader/preloader'
import TablePaginationComponent from '../components/pagination/table-paginaiton-component'
import GenericSearchCard from './generic-search-card'
import { PaginationInput } from '../../common/pagination/pagination'
import SelectInputField, { SelectMenuItem } from '../components/inputs/select-input-field'
import TextInputField from '../components/inputs/text-input-field'
import SelectInputFieldCheckbox from '../components/inputs/select-input-field-checkbox'
import { useSearchPageState } from '../../context/search_page_context'
import CustomTabView from '../components/tab-view/custom-tab-view'

interface SearchFields {
  name: string
  inputType: string
  type?: string
  menuItems?: any[]
}

interface GenericSearchPageProps {
  loading: boolean
  showSearch: boolean
  data: any
  countData: any
  error: any
  pageName: string
  searchEntityName: string
  children: React.ReactNode
  searchFields: SearchFields[]
  statusArray: any
  currentState: any
  paginationInput: PaginationInput
  setPaginationInput: React.Dispatch<React.SetStateAction<PaginationInput>>

  find(): void
}

const GenericSearchPage = ({
  loading,
  showSearch,
  data,
  countData,
  error,
  find,
  pageName,
  currentState,
  searchEntityName,
  children,
  statusArray,
  setPaginationInput,
  searchFields,
  paginationInput,
}: GenericSearchPageProps) => {
  const pageState = useSearchPageState()

  const STATUS_ARRAY = Object.entries(statusArray).map(([_, val]: any) => val)

  const start: any = {}

  const [tabCount, setTabCount] = useState(
    Object.keys(statusArray).reduce((p, c) => {
      p[c] = 0 // eslint-disable-line no-param-reassign
      return p
    }, start),
  )

  useEffect(() => {
    find()
  }, [paginationInput])

  useEffect(() => {
    setPaginationInput({ skip: 0, limit: 10 })
  }, [currentState])

  useEffect(() => {
    if (countData) {
      const counts = countData.items.reduce((p: any, c: any) => {
        const name = pageName === 'prospect_tutors' ? c.applicationStatus : c.status
        if (!Object.prototype.hasOwnProperty.call(p, name)) {
          p[name] = 0 // eslint-disable-line no-param-reassign
        }
        p[name] += 1 // eslint-disable-line no-param-reassign
        return p
      }, start)
      setTabCount(counts)
    }
  }, [countData])

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    pageState?.setSearchPageState({
      ...pageState?.searchPageState,
      [searchEntityName]: {
        ...currentState,
        [pageName === 'prospect_tutors' ? 'applicationStatus' : 'status']:
          STATUS_ARRAY[newValue],
      },
    })
  }

  return (
    <>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}

      <CustomTabView
        value={STATUS_ARRAY.indexOf(
          currentState[pageName === 'prospect_tutors' ? 'applicationStatus' : 'status']
            ? currentState[pageName === 'prospect_tutors' ? 'applicationStatus' : 'status']
            : '',
        )}
        tabCount={tabCount}
        entries={Object.entries(statusArray)}
        handleChangeTab={handleChangeTab}
      />

      <Box mt={3}>
        {showSearch && (
          <GenericSearchCard searchEntityName={searchEntityName} currentState={currentState}>
            <div id="search_fields">
              {searchFields.map(item => {
                if (item.inputType === 'SelectInputField') {
                  return (
                    <SelectInputField
                      key={item.name}
                      id={item.name}
                      label={item.name.toUpperCase()}
                      {...item}
                      required={false}
                      menuItems={
                        item.menuItems?.map(([_, value]) => ({
                          name: value?.toLowerCase(),
                          value,
                        })) as SelectMenuItem[]
                      }
                    />
                  )
                } else if (item.inputType === 'TextInputField') {
                  return (
                    <TextInputField
                      key={item.name}
                      id={item.name}
                      label={item.name.toUpperCase()}
                      {...item}
                      required={false}
                    />
                  )
                } else if (item.inputType === 'SelectInputFieldCheckbox') {
                  return (
                    <SelectInputFieldCheckbox
                      key={item.name}
                      id={item.name}
                      label={item.name.toUpperCase()}
                      {...item}
                      multiple
                      required={false}
                      menuItems={
                        item.menuItems?.map(value => ({
                          name: value.toLowerCase(),
                          value: value.toLowerCase(),
                        })) as SelectMenuItem[]
                      }
                    />
                  )
                }
              })}
            </div>

            {React.Children.map(children, child => {
              if (React.isValidElement(child) && child.props.id === 'ignore_fields') {
                return child
              }
              return null
            })}
          </GenericSearchCard>
        )}

        <Box my={2} />

        {React.Children.map(children, child => {
          if (React.isValidElement(child) && child.props.id === 'list_grid') {
            return child
          }
          return null
        })}

        {data && (
          <TablePaginationComponent
            setPaginationInput={setPaginationInput}
            pageInfo={data.pageInfo}
          />
        )}
      </Box>
    </>
  )
}

export default GenericSearchPage
