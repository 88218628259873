import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LogoutIcon from '@mui/icons-material/Logout'
import EditIcon from '@mui/icons-material/Edit'
import UpdateCustomerDialog from '../update-customer-dialog'
import { Customer } from '../entity/customer.entity'

interface CustomerInfoCardProps {
  customer: Customer
  goToGroup?: boolean
  contractId?: string

  refetch?(): void
}

const CustomerInfoCard: React.FC<CustomerInfoCardProps> = ({
  customer,
  refetch,
  contractId,
  goToGroup,
}) => {
  const location = useLocation()

  const [open, setOpen] = React.useState(false)

  return (
    <Card elevation={1}>
      <CardContent>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <NavLink
                style={{ textDecoration: 'none', color: 'black', display: 'flex' }}
                to={goToGroup ? `/job-group/${contractId}` : `/customer/${customer.id}`}
              >
                <Typography variant="h4">{customer.fullName}</Typography>
                {location.pathname !== `/customer/${customer.id}` && (
                  <IconButton>
                    <LogoutIcon color="primary" />
                  </IconButton>
                )}
              </NavLink>

              {location.pathname === `/customer/${customer.id}` && (
                <Box display="flex" justifyContent="flex-end">
                  <EditIcon color="action" onClick={() => setOpen(true)} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex">
              <LocalPhoneIcon fontSize="small" />
              <Box mx={1} />
              <Typography>{customer.phoneNumber}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold">Status: </Typography>
              <Box mx={1} />
              <Typography>{customer.status}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold">CustomerType: </Typography>
              <Box mx={1} />
              <Typography>{customer.customerType}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography>Location : {customer.location?.streetName}</Typography>
            </Box>
          </Grid>
        </Grid>

        <UpdateCustomerDialog
          open={open}
          handleClose={() => setOpen(false)}
          refetch={refetch}
          customer={customer}
        />
      </CardContent>
    </Card>
  )
}

export default CustomerInfoCard
