import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Form, Formik } from 'formik'
import { denormalizeValue, normalizeInitialState } from '../../functions/filter-objects'
import { useSearchPageState } from '../../context/search_page_context'
import { findTutorInitialState } from '../search_tutors/hooks/use-find-tutors.hook'
import { findPaymentsInitialState } from '../search_payments/hooks/use-find-payments.hook'
import { findContractsInitialState } from '../search_contracts/hooks/use-find-contracts.hook'
import { findProspectTutorsInitialState } from '../prospect_tutors/hook/use-find-tutor-prospects.hook'

interface GenericSearchCardProps {
  searchEntityName: string
  currentState: any
  children: React.ReactNode
}

const GenericSearchCard = ({
  children,
  searchEntityName,
  currentState,
}: GenericSearchCardProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const searchState = useSearchPageState()

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="h6">{searchEntityName}</Typography>
            </FormLabel>
          </FormControl>
        </AccordionSummary>
        <AccordionDetails>
          <Card elevation={0}>
            <CardContent>
              <Formik
                initialValues={normalizeInitialState(currentState)}
                enableReinitialize
                onSubmit={(values: any, { setSubmitting }) => {
                  setSubmitting(false)
                  const params = denormalizeValue(values)
                  searchState?.setSearchPageState({
                    ...searchState?.searchPageState,
                    [searchEntityName]: { ...params },
                  })
                }}
              >
                {({ values, handleChange, isSubmitting, resetForm, setFieldValue }) => (
                  <Form id="search-form">
                    <Grid container spacing={2}>
                      {React.Children.map(children, child => {
                        if (React.isValidElement(child)) {
                          if (child.props.id === 'search_fields') {
                            return React.Children.map(child.props.children, c => {
                              if (React.isValidElement(c)) {
                                return (
                                  <Grid item xs={4}>
                                    {React.cloneElement(c, {
                                      // @ts-ignore
                                      value: values[c.props.name]
                                        ? // @ts-ignore
                                          values[c.props.name]
                                        : // @ts-ignore
                                        c.props.name === 'subjects' ||
                                          // @ts-ignore
                                          c.props.name === 'gradeLevels'
                                        ? []
                                        : '',
                                      onChange: handleChange,
                                    })}
                                  </Grid>
                                )
                              }
                            })
                          } else if (child.props.id === 'ignore_fields') {
                            return React.Children.map(child.props.children, c => {
                              return (
                                <Grid item xs={4}>
                                  {React.Children.map(c.props.children, c2 => {
                                    if (React.isValidElement(c2)) {
                                      return React.cloneElement(c2, {
                                        // @ts-ignore
                                        onChange: (e, n) => {
                                          // @ts-ignore
                                          if (n && n.length == 2) {
                                            // @ts-ignore
                                            setFieldValue(c2.props.name, {
                                              gte: n[0],
                                              lte: n[1],
                                            })
                                          } else {
                                            // @ts-ignore
                                            setFieldValue(c2.props.name, { gte: 0, lte: 100 })
                                          }

                                          // @ts-ignore
                                          return c2.props.onChange(e, n)
                                        },
                                      })
                                    }
                                  })}
                                </Grid>
                              )
                            })
                          }
                        }
                        return null
                      })}
                    </Grid>

                    <Box my={2} display="flex" justifyContent="space-between">
                      <Button
                        type="reset"
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          resetForm()
                          searchState?.setSearchPageState({
                            ...searchState?.searchPageState,
                            [searchEntityName]: searchEntityName.includes('prospects')
                              ? findProspectTutorsInitialState
                              : searchEntityName.includes('contracts')
                              ? findContractsInitialState
                              : searchEntityName.includes('payments')
                              ? findPaymentsInitialState
                                ? searchEntityName.includes('tutors')
                                  ? findTutorInitialState
                                  : {}
                                : {}
                              : {},
                          })
                        }}
                      >
                        Clear
                      </Button>
                      <Box px={2} />
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Search
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default GenericSearchCard
