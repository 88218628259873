import { PaymentStatusEnum } from './payment.enums'
import { Contract, UpdateHistory } from '../../contracts/entities/contract.entity'
import { DailyLog, DailyLogStringFull } from './daily-log.entity'
import { Customer } from '../../customers/entity/customer.entity'
import { CustomerTypeEnum } from '../../customers/entity/customer.enums'
import { Note, noteStringFull } from '../../notes/entity/notes.entity'

export interface AttendancePaper {
  id?: string
  childId?: string
  images?: string[]
  delete?: boolean
}

export interface Payment {
  id: string
  month: string
  totalDepositedParent: number
  totalDepositedTutor: number
  totalHours: number
  payableCommission: number
  commission: number
  netCommission: number
  tot: number
  incomeTax: number
  discount: number
  netTutorPayment: number
  extendReasons: Note[]
  cancellationReasons: Note[]
  notes: Note[]
  paymentLink?: string
  transactionId: string
  receiptNumber?: string
  bankName: string
  accountNumber: string
  contractId: string
  contract?: Contract
  customerId?: string
  customer?: Customer
  status: PaymentStatusEnum
  statusHistory: UpdateHistory[]
  dailyLogs: DailyLog[]
  attendancePapers: AttendancePaper[]
  receiptImage?: string
  customerType: CustomerTypeEnum
  startDate: Date
  endDate: Date
  parentPaymentDate?: Date
  requestDate?: Date
  tutorPaidDate?: Date
  verifiedDate?: Date
  statusUpdatedAt: Date
  createdAt: Date
  updatedAt: Date
}

export const paymentStringFull = `
  accountNumber
    bankName
    commission
    contractId
    createdAt
    customerId
    customerType
    discount
    endDate
    id
    incomeTax
    month
    netCommission
    netTutorPayment
    parentPaymentDate
    payableCommission
    paymentLink
    receiptNumber
    receiptImage
    requestDate
    startDate
    status
    statusUpdatedAt
    tot
    totalDepositedParent
    totalDepositedTutor
    totalHours
    transactionId
    tutorPaidDate
    updatedAt
    verifiedDate
    attendancePapers {
      childId
      id
      images
    }
    contract {
      id
      fullName
      phoneNumber
      tutor {
        id
        fullName
        phoneNumber
      }
    }
    dailyLogs {
      ${DailyLogStringFull}
    }
    cancellationReasons {
      ${noteStringFull}
    }
    notes {
      ${noteStringFull}
    }
    extendReasons {
      ${noteStringFull}
    }
`
