import StatusToggleButton from '../../components/inputs/status-toggle-button'
import React from 'react'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Alert, Box } from '@mui/material'
import { DailyLogStatusEnum } from '../entities/payment.enums'
import { Payment } from '../entities/payment.entity'
import { DailyLog } from '../entities/daily-log.entity'
import { useUpdateDailyLog } from '../hook/use-update-daily-log.hook'

interface UpdateDailyLogStatusProps {
  dailyLog: DailyLog
  payment: Payment

  refetch(): void
}

const UpdateDailyLogStatus: React.FC<UpdateDailyLogStatusProps> = ({
  dailyLog,
  refetch,
  payment,
}) => {
  const { updateDailyLog, loading, error } = useUpdateDailyLog()

  return (
    <Box>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}
      <StatusToggleButton
        options={Object.entries(DailyLogStatusEnum).map(([_, value]) => value)}
        clicked={s =>
          dailyLog.id &&
          updateDailyLog({
            paymentId: payment.id,
            dailyLogs: [
              {
                status: s as DailyLogStatusEnum,
                id: dailyLog.id,
                childId: dailyLog.childId,
                startTime: dailyLog.startTime,
                endTime: dailyLog.endTime,
                description: dailyLog.description,
                date: dailyLog.date,
              },
            ],
          }).then(() => refetch())
        }
        selected={dailyLog.status}
      />
    </Box>
  )
}

export default UpdateDailyLogStatus
