import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'

interface AlertDialogProps {
  open: boolean
  dialogTitle: string
  dialogContent: React.ReactNode
  dialogButton: string
  isSubmitting?: boolean

  handleClose?(): void

  handleConfirm?(): void

  handleSubmit?(e?: React.FormEvent<HTMLFormElement>): void
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  isSubmitting,
  open,
  handleClose,
  handleConfirm,
  dialogTitle,
  dialogContent,
  dialogButton,
  handleSubmit,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
    <DialogContent>{dialogContent}</DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={handleClose} autoFocus>
        Cancel
      </Button>
      <Button onClick={handleConfirm} disabled={isSubmitting}>
        {dialogButton}
      </Button>
    </DialogActions>
  </Dialog>
)

export default AlertDialog
