import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../context/auth_context'

const ProtectedRoute = () => {
  const auth = useAuth()

  if (!auth?.authState) {
    return <Navigate to="/login" />
  }

  if (auth?.authState.role !== 'ADMIN' && auth?.authState.role !== 'SUPER_ADMIN')
    return <Navigate to="/access-denied" />
  return <Outlet />
}

export default ProtectedRoute
