import { gql, useMutation } from '@apollo/client'
import { UserGenderEnum } from '../../users/entities/user.enums'
import { ServiceTypeEnum } from '../../tutors/entities/tutor.enums'
import { CustomerTypeEnum } from '../../customers/entity/customer.enums'
import { CreateChildInput } from '../../contracts/entities/child.entity'
import { Contract } from '../../contracts/entities/contract.entity'
import { CreateContractInput } from '../dto/create-contract-input'
import {
  ContractCreationEnum,
  ContractGenderEnum,
  ContractTypeEnum,
} from '../../contracts/entities/contract.enums'

const CREATE_CONTRACT = gql`
  mutation CreateContract($createContractInput: CreateContractInput!) {
    createContract(createContractInput: $createContractInput) {
      id
    }
  }
`

export function useCreateContract() {
  const [createContractGql, { loading, data, error }] = useMutation<
    { createContract: Contract },
    { createContractInput: CreateContractInput }
  >(CREATE_CONTRACT)

  function createContract(createContractInput: CreateContractInput) {
    return createContractGql({
      variables: { createContractInput },
    })
  }

  return { createContract, loading, data, error }
}

export const newChild: CreateChildInput = {
  hrsDay: 1,
  tutorRate: 200,
  subjects: [],
  gradeLevel: '',
  learningDays: [],
  studentName: '',
  description: '',
}

export const createContractInitialState: CreateContractInput = {
  additionalPhoneNumbers: [],
  contractType: ContractTypeEnum.COMMISSION,
  creation: ContractCreationEnum.NEW,
  customerId: '',
  email: '',
  endDate: undefined,
  gradeLevels: [],
  hrsDay: 1,
  location: {
    city: 'Addis Ababa',
    coordinates: [],
    country: 'Ethiopia',
    description: '',
    district: '',
    id: '',
    placeId: '',
    streetName: '',
    subCity: '',
    zipCode: '1000',
  },
  numberOfChildren: 1,
  subjects: [],
  tutorId: undefined,
  fullName: '',
  phoneNumber: '',
  description: '',
  serviceType: ServiceTypeEnum.IN_PERSON,
  customerGender: UserGenderEnum.FEMALE,
  learningDays: [],
  tutorGender: ContractGenderEnum.FEMALE,
  children: [{ ...newChild }],
  startDate: new Date(),
  startTime: '17:00:00',
  endTime: '18:00:00',
  customerType: CustomerTypeEnum.SINGLE_CUSTOMER,
}
