import React from 'react'
import { RemoveCircleRounded } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper } from '@mui/material'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import TextInputField from '../../components/inputs/text-input-field'
import { SubjectsArray } from '../../search_tutors/components/search-tutors-card'
import SelectInputFieldCheckbox from '../../components/inputs/select-input-field-checkbox'
import { GradeLevelList } from '../../contracts/entities/contract.enums'
import { CreateChildInput } from '../../contracts/entities/child.entity'

interface ChildInfoProps {
  singleChild: CreateChildInput[]
  index: number

  setChildren(val: CreateChildInput[]): void

  handleRemove(val: number): void
}

const CreateContractChildInfo: React.FC<ChildInfoProps> = ({
  singleChild,
  index,
  setChildren,
  handleRemove,
}) => (
  <Paper>
    <Box px={2} py={2} pb={4}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={() => handleRemove(index)}>
          <RemoveCircleRounded color="error" />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box py={1}>
            <SelectInputFieldCheckbox
              id="subjects"
              value={singleChild[index].subjects}
              label="Subjects"
              name="subjects"
              onChange={e => {
                const newObj: CreateChildInput[] = singleChild.map(c => c)
                newObj[index].subjects = e.target.value
                setChildren(newObj)
              }}
              multiple
              menuItems={
                SubjectsArray.map(value => ({
                  name: value.toLowerCase(),
                  value,
                })) as SelectMenuItem[]
              }
            />
          </Box>
          <Box py={1}>
            <SelectInputField
              id="grade-level"
              value={singleChild[index].gradeLevel}
              label="Grade Level"
              name="gradeLevel"
              onChange={e => {
                const newObj: CreateChildInput[] = singleChild.map(c => c)
                newObj[index].gradeLevel = e.target.value
                setChildren(newObj)
              }}
              menuItems={
                GradeLevelList.map(value => ({
                  name: value.toLowerCase(),
                  value,
                })) as SelectMenuItem[]
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box py={1}>
            <TextInputField
              id="rate"
              value={singleChild[index].tutorRate}
              label="Price per Hour"
              name="rate"
              type="number"
              onChange={e => {
                const newObj: CreateChildInput[] = singleChild.map(c => c)
                newObj[index].tutorRate = e.target.value
                setChildren(newObj)
              }}
            />
          </Box>
          <Box py={1}>
            <TextInputField
              id="hrs-per-day"
              value={singleChild[index].hrsDay}
              label="Hours per day"
              name="hrsDay"
              type="number"
              onChange={e => {
                const newObj: CreateChildInput[] = singleChild.map(c => c)
                newObj[index].hrsDay = e.target.value
                setChildren(newObj)
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Paper>
)

export default CreateContractChildInfo
