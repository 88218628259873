import { User, userStringFull } from '../../users/entities/user.entity'
import { ServiceTypeEnum, TutorDepartment, WorkStatusEnum } from './tutor.enums'
import { UserGenderEnum, UserStatusEnum } from '../../users/entities/user.enums'
import { LearningDaysEnum } from '../../contracts/entities/contract.enums'
import { locationStringFull, UserLocation } from '../../../common/location/location.entity'
import { UpdateHistory } from '../../contracts/entities/contract.entity'

export interface BankInfo {
  bankName?: string
  accountNumber?: string
}

export interface RatingData {
  _1: number
  _2: number
  _3: number
  _4: number
  _5: number
}

export interface Tutor {
  id: string
  email: string
  fullName: string
  phoneNumber: string
  profilePic: string
  gender: UserGenderEnum
  dateOfBirth: Date
  status: UserStatusEnum
  statusHistory?: UpdateHistory[]
  education: string
  department: TutorDepartment
  degreeType?: string
  g12Result: number
  university: string
  workStatus: WorkStatusEnum
  pitch: string
  bankInfo?: BankInfo
  serviceTypes: ServiceTypeEnum[]
  subjects: string[]
  gradeLevels: string[]
  workingDays: LearningDaysEnum[]
  yourLocation: UserLocation
  locationDetails: UserLocation[]
  clientsWorkedWith: number
  currentClients: number
  assignedClients: number
  totalHoursWorked: number
  totalAmountEarned: number
  rating: number
  ratingCount: number
  ratingData?: RatingData
  userId: string
  user?: User
  createdAt: Date
  updatedAt: Date
}

export const tutorStringFull = `
  id
  fullName
  phoneNumber
  email
  profilePic
  gender
  status
  education
  g12Result
  university
  workStatus
  pitch
  userId
  department
  degreeType
  assignedClients
  bankInfo{
     bankName
     accountNumber
  }
  serviceTypes
  subjects
  gradeLevels
  workingDays
  yourLocation{
   ${locationStringFull}
  }
  locationDetails{
    ${locationStringFull}
  }
  clientsWorkedWith
  currentClients
  totalHoursWorked
  totalAmountEarned
  rating
  ratingCount
  ratingData{
    _1
    _2
    _3
    _4
    _5
  }
  dateOfBirth
  user{
    ${userStringFull}
  }
  createdAt
  updatedAt
`

export const tutorStringMini = `
  id
  email
  fullName
  gender
  userId
  phoneNumber
  rating
  ratingCount
`
