import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import SelectInputField, {
  SelectMenuItem,
} from '../../app/components/inputs/select-input-field'
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'

const valueFormatter = (value: number) => `${value}`

const Chart = ({
  data,
  defaultStatuses,
  getData,
}: {
  data: any
  defaultStatuses: string[]
  getData(groupBy: string, statuses: string[], startDate: Date, endDate: Date): void
}) => {
  const [filter, setFilter] = useState({
    groupBy: 'years',
    statuses: defaultStatuses,
    startDate: new Date(`${new Date().getFullYear() - 1}-01-01T00:00:00.000Z`),
    endDate: new Date(`${new Date().getFullYear()}-12-01T00:00:00.000Z`),
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  const [dateValue, setDateValue] = React.useState<DateRange<Dayjs>>([
    dayjs(`${new Date().getFullYear() - 1}-01-01T00:00:00.000Z`),
    dayjs(`${new Date().getFullYear()}-12-01T00:00:00.000Z`),
  ])

  useEffect(() => {
    getData(filter.groupBy, filter.statuses, filter.startDate, filter.endDate)
  }, [filter])

  useEffect(() => {
    setFilter(prevState => ({
      ...prevState,
      statuses: defaultStatuses,
    }))
  }, [defaultStatuses])

  useEffect(() => {
    if (dateValue && dateValue.length === 2 && dateValue[0] && dateValue[1])
      setFilter(prevState => ({
        ...prevState,
        startDate: dateValue[0]?.toDate()!,
        endDate: dateValue[1]?.toDate()!,
      }))
  }, [dateValue])

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectInputField
            id="groupBy"
            value={filter.groupBy}
            label="Group By"
            name="groupBy"
            onChange={handleChange}
            menuItems={
              ['days', 'months', 'years'].map(value => ({
                name: value.toLowerCase(),
                value,
              })) as SelectMenuItem[]
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: 'Registration date from', end: 'to' }}
          >
            <DateRangePicker
              label="Date"
              value={dateValue}
              onChange={newValue => {
                setDateValue(newValue)
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Box my={2} />
      {data ? (
        <BarChart
          xAxis={[{ scaleType: 'band', dataKey: 'groupName' }]}
          series={defaultStatuses.map(s => ({ dataKey: s, label: s, valueFormatter }))}
          dataset={data}
          height={400}
        />
      ) : (
        <Box display="flex" justifyContent="center">
          <Typography>No Data</Typography>
        </Box>
      )}
    </Box>
  )
}

export default Chart
