import { Alert, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Chart from './chart'
import { usePaymentsData } from '../hooks/use-payments-data.hook'
import { PaymentStatusEnum } from '../../app/payments/entities/payment.enums'
import { SelectMenuItem } from '../../app/components/inputs/select-input-field'
import SelectInputFieldCheckbox from '../../app/components/inputs/select-input-field-checkbox'
import { setDefaultValues } from '../../functions/filter-objects'

const PaymentsData = () => {
  const { paymentsData, data, error } = usePaymentsData()

  const [statuses, setStatuses] = useState([
    PaymentStatusEnum.ACTIVE,
    PaymentStatusEnum.VERIFIED,
  ])
  const [response, setResponse] = useState<null | JSON[]>(null)

  useEffect(() => {
    if (data) {
      setResponse(setDefaultValues(data.advancedPaymentsAnalytics, statuses))
    }
  }, [data])

  return (
    <Box px={3} py={5} my={2}>
      <Typography variant="h5">Payments</Typography>
      {error && <Alert severity="error">{error.message}</Alert>}

      <Box my={5}>
        <SelectInputFieldCheckbox
          id="statuses"
          value={statuses}
          label="Statuses"
          name="statuses"
          onChange={e => {
            setStatuses(e.target.value)
          }}
          multiple
          menuItems={
            Object.entries(PaymentStatusEnum).map(([key, value]) => ({
              name: key,
              value,
            })) as SelectMenuItem[]
          }
        />
      </Box>

      <Chart data={response} getData={paymentsData} defaultStatuses={statuses} />
    </Box>
  )
}

export default PaymentsData
