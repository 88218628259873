import React from 'react'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import { ProspectTutor } from '../entities/prospect-tutor.entity'

interface UserDetailDialogProps {
  open: boolean
  prospectTutor: ProspectTutor

  handleClose(open: boolean): void
}

const TutorProspectDetailDialog: React.FC<UserDetailDialogProps> = ({
  open,
  prospectTutor,
  handleClose,
}) => {
  return (
    <Dialog open={open}>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>User Info</Typography>
            <IconButton onClick={() => handleClose(false)}>
              <GridCloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" noWrap fontSize={18}>
              Name:
              {`${prospectTutor?.fullName}`}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">Phone Number</Typography>
            <Box mx={1}>:</Box>
            <Typography>{prospectTutor?.phoneNumber}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold">Email</Typography>
            <Box mx={1}>:</Box>
            <Typography>{prospectTutor?.email}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Gender
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{prospectTutor?.gender}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Cgpa
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{prospectTutor?.cgpa}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Location
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{prospectTutor?.location?.city}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              University
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{prospectTutor?.university}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Major
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{prospectTutor?.major}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Stream
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{prospectTutor?.stream}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Euee Result
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>{prospectTutor?.eueeResult}</Typography>
          </Box>
          <Box display="flex">
            <Typography fontWeight="bold" noWrap>
              Graduation Date
            </Typography>
            <Typography mx={1}>:</Typography>
            <Typography>
              {new Date(prospectTutor?.graduationDate).toISOString().split('T')[0]}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  )
}

export default TutorProspectDetailDialog
