import React from 'react'
import { Box } from '@mui/material'
import SelectInputField, { SelectMenuItem } from '../inputs/select-input-field'
import TextInputField from '../inputs/text-input-field'
import { UserLocationInput } from '../../../common/location/location.entity'
import GooglePlaces from './google_places'

const cities: string[] = [
  '',
  'addis ababa',
  'afar',
  'amhara',
  'benishangul-gumuz',
  'central ethiopia regional state',
  'dire dawa',
  'gambela',
  'harari',
  'oromia',
  'sidama',
  'somali',
  'south ethiopia regional state',
  'south west ethiopia peoples',
  'tigray region',
]

const countries: string[] = ['', 'ethiopia', 'usa', 'canada', 'uae', 'saudi', 'other']

interface CountryCityProps {
  required?: boolean

  userLocation: UserLocationInput | null | undefined

  setUserLocation(val: UserLocationInput | null | ((val: UserLocationInput) => void)): void
}

const PlaceDetailSelector: React.FC<CountryCityProps> = ({
  userLocation,
  setUserLocation,
  required,
}) => {
  return (
    <>
      <Box py={1}>
        <GooglePlaces
          setUserLocation={setUserLocation}
          userLocation={userLocation}
          required={required}
        />
      </Box>
      <Box py={1}>
        <SelectInputField
          id="country"
          value={userLocation ? userLocation.country : 'ethiopia'}
          label="Country"
          name="country"
          required={required}
          onChange={e => setUserLocation(prev => ({ ...prev, country: e.target.value }))}
          menuItems={
            countries.map(value => ({
              name: value.toLowerCase(),
              value,
            })) as SelectMenuItem[]
          }
        />
      </Box>
      <Box py={1}>
        {userLocation === null ||
        (userLocation && userLocation?.country?.toLowerCase() === 'ethiopia') ? (
          <SelectInputField
            id="city"
            value={userLocation ? userLocation?.city : 'addis ababa'}
            label="City"
            name="city"
            required={required}
            onChange={e => setUserLocation(prev => ({ ...prev, city: e.target.value }))}
            menuItems={
              cities.map(value => ({
                name: value.toLowerCase(),
                value,
              })) as SelectMenuItem[]
            }
          />
        ) : (
          <TextInputField
            id="city"
            name="city"
            label="City"
            required={required}
            value={userLocation ? userLocation?.city : 'addis ababa'}
            onChange={e => setUserLocation(prev => ({ ...prev, city: e.target.value }))}
          />
        )}
      </Box>
    </>
  )
}

export default PlaceDetailSelector
