import { gql, useMutation } from '@apollo/client'
import { Tutor } from '../entities/tutor.entity'
import { UpdateTutorInput } from '../dto/update-tutor.inputs'

const UPDATE_TUTOR = gql`
  mutation UpdateTutor($updateTutorInput: UpdateTutorInput!) {
    updateTutor(updateTutorInput: $updateTutorInput) {
      id
    }
  }
`

export function useUpdateTutor() {
  const [updateTutorGql, { loading, data, error }] = useMutation<
    { updateTutor: Tutor },
    { updateTutorInput: UpdateTutorInput }
  >(UPDATE_TUTOR)

  function updateTutor(updateTutorInput: UpdateTutorInput) {
    return updateTutorGql({
      variables: { updateTutorInput },
    })
  }

  return { updateTutor, loading, data, error }
}

export const updateTutorInitialState = (tutor: Tutor): UpdateTutorInput => ({
  tutorId: tutor.id,
  firstName: tutor.fullName.split(' ')[0],
  middleName: tutor.fullName.split(' ')[1],
  lastName: tutor.fullName.split(' ')[2],
  phoneNumber: tutor.phoneNumber,
  email: tutor.email,
  profilePic: tutor.profilePic,
  education: tutor.education,
  g12Result: tutor.g12Result,
  university: tutor.university,
  // currentJobStatus: tutor.currentJobStatus,
  pitch: tutor.pitch,
  accountNumber: tutor.bankInfo?.accountNumber,
  bankName: tutor.bankInfo?.bankName,
  serviceTypes: tutor.serviceTypes,
  subjects: tutor.subjects.map(s => s.toLowerCase()),
  gradeLevels: tutor.gradeLevels,
  workingDays: tutor.workingDays,
  // yourLocation: tutor.yourLocation,
  // locationDetails: tutor.locationDetails,
  // lngLats: tutor.lngLats,
  // locations: tutor.locations,
  status: tutor.status,
})
