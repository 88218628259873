import React, { useState } from 'react'
import { Formik } from 'formik'
import { Add } from '@mui/icons-material'
import { Alert, Box, Button, Grid, Paper } from '@mui/material'
import CreateContractChildInfo from './components/create-contract-child-info'
import CreateContractCustomerInfo from './components/create-contract-customer-info'
import CreateContractInfo from './components/create-contract-info'
import { CircularIndeterminate } from '../components/preloader/preloader'
import AlertDialog from '../components/dialog/alert-dialog'
import {
  createContractInitialState,
  newChild,
  useCreateContract,
} from './hooks/use-create-contract.hook'
import { useNavigate } from 'react-router-dom'
import { CreateChildInput } from '../contracts/entities/child.entity'
import { CreateContractInput } from './dto/create-contract-input'
import { CustomerTypeEnum } from '../customers/entity/customer.enums'
import { ContractCreationEnum, ContractTypeEnum } from '../contracts/entities/contract.enums'
import { UserLocationInput } from '../../common/location/location.entity'

const CreateContractPage: React.FC = () => {
  const navigate = useNavigate()
  const { createContract, loading, error } = useCreateContract()

  const [numberOfChildren, setNumberOfChildren] = useState<number>(1)
  const [children, setChildren] = useState<CreateChildInput[]>(
    createContractInitialState.children,
  )
  const [userLocation, setUserLocation] = useState<UserLocationInput>({
    city: 'Addis Ababa',
    coordinates: [],
    country: 'Ethiopia',
    description: '',
    district: '',
    placeId: '',
    streetName: '',
    subCity: '',
    zipCode: '1000',
  })
  const [additionalPhoneNumbers, setAdditionalPhoneNumbers] = useState<string[]>([])

  const [openCancel, setOpenCancel] = useState<boolean>(false)
  const [phoneError, setPhoneError] = useState<string[]>([])
  const handleConfirmCancel = () => {
    setNumberOfChildren(1)
    setChildren(createContractInitialState.children)
    setOpenCancel(false)
  }

  const handleConfirmSave = (values: CreateContractInput) => {
    createContract({
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
      additionalPhoneNumbers: additionalPhoneNumbers,
      customerGender: values.customerGender,
      email: undefined,
      customerType: values.customerType,
      contractType:
        values.customerType === CustomerTypeEnum.SINGLE_CUSTOMER
          ? ContractTypeEnum.COMMISSION
          : ContractTypeEnum.FIXED,
      creation: ContractCreationEnum.NEW,
      description: values.description,
      hrsDay: parseInt(values.hrsDay.toString(), 10),
      location: userLocation,
      learningDays: values.learningDays,
      children: children.map((c, index) => ({
        ...c,
        studentName: `Child ${index + 1}`,
        description: `${values.fullName} wants you to tutor their ${
          children.length > 1 ? 'children' : 'child'
        }. They live around ${
          userLocation ? userLocation?.description.split(',')[0].trim() : '...'
        }. ${values.description}`,
        learningDays: values.learningDays,
        hrsDay: parseInt(c.hrsDay.toString(), 10),
        tutorRate: parseInt(c.tutorRate.toString(), 10),
      })),
      numberOfChildren,
      serviceType: values.serviceType,
      startDate: values.startDate,
      endDate: undefined,
      startTime: values.startTime,
      endTime: values.endTime,
      subjects: children.flatMap(c => c.subjects),
      gradeLevels: children.flatMap(c => c.gradeLevel),
      tutorGender: values.tutorGender,
      customerId: undefined,
      tutorId: undefined,
    })
      .then(res => {
        navigate(`/contract/${res?.data?.createContract.id}`)
      })
      .catch(e => e)
  }

  const handleAddChild = () => {
    setChildren(prevState => [...prevState, { ...newChild }])
    setNumberOfChildren(prevState => prevState + 1)
  }

  const handleRemoveChild = (index: number) => {
    if (numberOfChildren > 1) {
      setChildren(prevState => {
        prevState.splice(index, 1)
        return prevState
      })
      setNumberOfChildren(prevState => prevState - 1)
    }
  }

  return (
    <>
      {loading && <CircularIndeterminate />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {phoneError.map((error, index) => (
        <Alert key={index} severity="error">
          {error}
        </Alert>
      ))}
      <AlertDialog
        open={openCancel}
        dialogButton="Confirm"
        dialogTitle="Confirm Cancel"
        dialogContent="Are you sure you want to cancel?"
        handleConfirm={handleConfirmCancel}
        handleClose={() => setOpenCancel(false)}
      />

      <Box my={1} />

      <Formik
        initialValues={createContractInitialState}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          handleConfirmSave(values as CreateContractInput)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Paper>
                  <Box px={2} py={1}>
                    <CreateContractCustomerInfo
                      fullName={values.fullName}
                      phoneNumber={values.phoneNumber}
                      additionalPhoneNumbers={additionalPhoneNumbers}
                      setAdditionalPhoneNumbers={setAdditionalPhoneNumbers}
                      customerGender={values.customerGender}
                      parentRate={values.parentRate}
                      customerType={values.customerType}
                      setUserLocation={setUserLocation}
                      userLocation={userLocation}
                      handleChange={handleChange}
                    />
                    <CreateContractInfo
                      serviceType={values.serviceType}
                      description={values.description}
                      startTime={values.startTime}
                      endTime={values.endTime}
                      startDate={values.startDate}
                      tutorGender={values.tutorGender}
                      learningDays={values.learningDays}
                      handleChange={handleChange}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                {Array.from(Array(numberOfChildren).keys()).map(i => (
                  <Box pb={1} key={i}>
                    <CreateContractChildInfo
                      index={i}
                      singleChild={children}
                      setChildren={setChildren}
                      handleRemove={handleRemoveChild}
                    />
                  </Box>
                ))}

                <Box py={2} display="flex" justifyContent="flex-end">
                  <Button startIcon={<Add />} onClick={handleAddChild} color="success">
                    Add Child
                  </Button>
                </Box>

                {error && <Alert severity="error">{error.message}</Alert>}
                <Box my={2} display="flex" justifyContent="space-between">
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => setOpenCancel(true)}
                  >
                    Cancel
                  </Button>
                  <Box px={2} />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default CreateContractPage
