import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Container, Paper, TextField, Typography } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { CircularIndeterminate } from '../components/preloader/preloader'
import { useLogin } from './hooks/use-login.hook'
import { useAuth } from '../../context/auth_context'

const Login: React.FC = () => {
  const navigate = useNavigate()

  const auth = useAuth()

  const [loginForm, setLoginForm] = useState({
    phoneNumber: '',
    password: '',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginForm(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  // @ts-ignore
  const location = useLocation<{ from: { pathname: string } }>()

  const { from } = location.state || { from: { pathname: '/' } }

  const { login, loading, error } = useLogin()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    login(loginForm).then(res => {
      navigate(from)

      res.data &&
        auth?.setAuthState({
          accessToken: res.data?.login.accessToken,
          refreshToken: res.data?.login.refreshToken,
          role: res.data?.login.user.role,
          user: res.data?.login.user,
        })
    })
  }

  return (
    <Container maxWidth="sm">
      {loading && <CircularIndeterminate />}
      <Box py={3}>
        <form onSubmit={handleSubmit}>
          <Paper>
            <Box py={3} px={2}>
              <Box display="flex" justifyContent="center">
                <Avatar className="avatar" component="div">
                  <LockOutlinedIcon />
                </Avatar>
              </Box>
              <Box my={1} />
              <Box display="flex" justifyContent="center">
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
              </Box>
              <Box my={2} />
              <TextField
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                type="tel"
                autoComplete="phoneNumber"
                autoFocus
                onChange={handleChange}
              />
              <Box my={2} />
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />

              {error && <Typography component="p">{error.message}</Typography>}

              <Box my={2} />

              <Button type="submit" fullWidth variant="contained" color="primary">
                Login
              </Button>
            </Box>
          </Paper>
        </form>
      </Box>
    </Container>
  )
}

export default Login
