import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

interface StatusToggleButtonProps {
  options: string[]
  selected: string | any

  clicked(val: string): void
}

const colorPalate: { [key: string]: string } = {
  NONE: 'grey',
  NOT_CONFIRMED: 'grey',
  PENDING: 'lightblue',
  STARTED: 'green',
  ASSIGNED: 'blue',
  CONFIRMED: 'blue',
  ACCEPTED: 'green',
  ACTIVE: 'green',
  APPROVED: 'green',
  VERIFIED: 'darkgrey',
  REQUESTED: 'orange',
  PAUSED: 'orange',
  FINISHED: 'blue',
  CANCELED: 'pink',
  REJECTED: 'red',
  BLOCKED: 'red',
  HAS_ERROR: 'red',
  PARENT_PAID: 'blue',
  TUTOR_PAID: 'blue',
}

const StatusToggleButton: React.FC<StatusToggleButtonProps> = ({
  options,
  clicked,
  selected,
}) => {
  const [open, setOpen] = useState(false)
  // const [selectedName, setSelectedName] = useState<string>("")
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(options.indexOf(selected))
  const [color, setColor] = useState(colorPalate[selected])

  const handleClick = () => {
    clicked(options[selectedIndex])
    setColor(colorPalate[options[selectedIndex]])
  }

  useEffect(() => {
    // setSelectedName(options[selectedIndex])
    setColor(colorPalate[selected])
    setSelectedIndex(options.indexOf(selected))
  }, [selected])

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    option: string,
  ) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <Box>
      <ButtonGroup size="small" variant="outlined" ref={anchorRef} aria-label="split button">
        <Button style={{ color, borderColor: color }} size="small" onClick={handleClick}>
          {options[selectedIndex]}
        </Button>
        <Button
          style={{ color, borderColor: color }}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index, option)}
                      // disabled={() => option == 'PENDING' ? true : false}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default StatusToggleButton
