import gql from 'graphql-tag'
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { httpLink } from './client'

const REFRESH_TOKEN_MUTATION = gql`
  mutation {
    refreshToken {
      accessToken
      refreshToken
      user {
        role
      }
    }
  }
`

export const refreshTokens = async () => {
  const auth =
    localStorage.getItem('authState') && JSON.parse(localStorage.getItem('authState') || '')
  const token = auth?.refreshToken
  if (!token) {
    return null
  }

  const client = new ApolloClient({
    link: ApolloLink.from([httpLink]),
    cache: new InMemoryCache(),
  })

  try {
    const { data, errors } = await client.mutate({
      mutation: REFRESH_TOKEN_MUTATION,
      fetchPolicy: 'network-only',
      context: {
        headers: {
          refreshToken: token,
        },
      },
    })

    console.log(errors)

    if (data.refreshToken) {
      const { accessToken, refreshToken } = data.refreshToken
      localStorage.setItem(
        'authState',
        JSON.stringify({
          accessToken,
          refreshToken,
          role: data.refreshToken.user.role,
          user: data.refreshToken.user,
        }),
      )
      return accessToken
    }
  } catch (e) {
    if ((e as any).message === 'Session Expired!') {
      localStorage.removeItem('authState')
    }
    throw new Error('No refresh token found.')
  }
  return null
}
