export const changeTimeToDate = (date: Date, time: string, defaultTime: string) => {
  if (time.length <= 8) {
    let split = time.split(':')
    if (split.length > 1) {
      return new Date(
        `${new Date(date).toISOString().split('T')[0]}T${split[0]}:${split[1]}:00.000Z`,
      )
    }
  }
  return new Date(`${new Date(date).toISOString().split('T')[0]}T${defaultTime}:00.000Z`)
}

export const changeDateToTime = (time: Date, defaultTime: string) => {
  if (time.toString().length <= 11) return time
  let split = time.toString().split('T')
  if (split.length == 2) {
    const t = split[1].split(':')
    if (t.length > 1) return `${t[0]}:${t[1]}`
  }
  return defaultTime
}
