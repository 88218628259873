export enum UserStatusEnum {
  NONE = 'None',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  PENDING = 'PENDING',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
}

export enum UserGenderEnum {
  NONE = 'None',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  BOTH = 'BOTH',
}

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  NORMAL = 'NORMAL',
  TUTOR = 'TUTOR',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  TUTOR_SERVICE = 'TUTOR_SERVICE',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  RECRUITER = 'RECRUITER',
  DELETED = 'DELETED',
}

export enum UserRoleEnumSearch {
  ADMIN = 'ADMIN',
  NORMAL = 'NORMAL',
  TUTOR = 'TUTOR',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  TUTOR_SERVICE = 'TUTOR_SERVICE',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  RECRUITER = 'RECRUITER',
  DELETED = 'DELETED',
}

export enum UserSourceEnum {
  WEBSITE = 'WEBSITE',
  PHONE_CALL = 'PHONE_CALL',
}
