import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Card, CardContent, Grid } from '@mui/material'
import TextInputField from '../../components/inputs/text-input-field'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import { UserGenderEnum, UserRoleEnum } from '../entities/user.enums'
import AlertDialog from '../../components/dialog/alert-dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { useCreateUser } from '../hooks/use-create-user.hook'

const CreateUser = ({ refetch }: { refetch(): void }) => {
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [openSave, setOpenSave] = React.useState<boolean>(false)

  const [createUserForm, setCreateUserForm] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    role: UserRoleEnum.NORMAL,
    gender: UserGenderEnum.FEMALE,
    email: '',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateUserForm(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const { createUser, loading, data, error } = useCreateUser()

  const handleOpenSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOpenSave(true)
  }

  const handleCloseSave = () => {
    setOpenSave(false)
  }

  const handleConfirmSave = () => {
    createUser({
      fullName: `${createUserForm.firstName} ${createUserForm.middleName} ${createUserForm.lastName}`,
      phoneNumber: `+251${createUserForm.phoneNumber.slice(-9)}`,
      password: `0${createUserForm.phoneNumber.slice(-9)}`,
      role: createUserForm.role,
      gender: createUserForm.gender,
      email: createUserForm.email,
    })
      .then()
      .catch()
    handleCloseSave()
  }

  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [data])

  return (
    <Card>
      {loading && <CircularIndeterminate />}

      <AlertDialog
        open={openSave}
        dialogButton="Save"
        dialogTitle="Confirm save"
        dialogContent="Are you sure you want to save?"
        handleConfirm={handleConfirmSave}
        handleClose={handleCloseSave}
      />

      <CardContent>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={() => setIsClicked(!isClicked)}>
            Create User
          </Button>
        </Box>

        {isClicked && (
          <form onSubmit={handleOpenSave}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInputField
                  id="first-name"
                  name="firstName"
                  label="First Name"
                  value={createUserForm.firstName}
                  onChange={handleChange}
                />

                <TextInputField
                  id="middle-name"
                  label="Middle Name"
                  name="middleName"
                  value={createUserForm.middleName}
                  onChange={handleChange}
                />

                <TextInputField
                  id="last-name"
                  label="Last Name"
                  name="lastName"
                  value={createUserForm.lastName}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextInputField
                  id="phone-number"
                  name="phoneNumber"
                  label="Phone Number"
                  value={createUserForm.phoneNumber}
                  onChange={handleChange}
                />

                <TextInputField
                  id="email"
                  name="email"
                  label="Email"
                  required={false}
                  value={createUserForm.email}
                  onChange={handleChange}
                />

                <SelectInputField
                  id="role"
                  value={createUserForm.role}
                  label="Role"
                  name="role"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(UserRoleEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />

                <SelectInputField
                  id="gender"
                  value={createUserForm.gender}
                  label="Gender"
                  name="gender"
                  onChange={handleChange}
                  menuItems={
                    Object.entries(UserGenderEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Grid>
            </Grid>
            <Box my={2} display="flex" justifyContent="space-between">
              <Button type="button" fullWidth variant="contained" color="error">
                Cancel
              </Button>
              <Box px={2} />
              <Button type="submit" fullWidth variant="contained" color="primary">
                Save
              </Button>
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}
          </form>
        )}
      </CardContent>
    </Card>
  )
}
export default CreateUser
