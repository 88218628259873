import * as React from 'react'
import { DefaultizedPieValueType } from '@mui/x-charts'
import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart'
import { Box } from '@mui/material'

interface DataProps {
  name: string
  value: number
  percentage: string
}

const PieChartComponent = ({ data }: { data: DataProps[] }) => {
  const sizing = {
    width: 400,
    height: 400,
  }
  const TOTAL = data.map(item => item.value).reduce((a, b) => a + b, 0)

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL
    return `${(percent * 100).toFixed(0)}%`
  }

  return (
    <Box display="flex">
      <PieChart
        margin={{ top: 100, bottom: 10, left: 10, right: 100 }}
        series={[
          {
            outerRadius: 150,
            data,
            arcLabel: getArcLabel,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white',
            fontSize: 14,
          },
        }}
        {...sizing}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'left' },
          },
        }}
      />
    </Box>
  )
}

export default PieChartComponent
