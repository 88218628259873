import { gql, useMutation } from '@apollo/client'

const EXTEND_DEADLINE = gql`
  mutation SendExtendDeadlineEmail($prospectId: String!) {
    sendExtendDeadlineEmail(prospectId: $prospectId)
  }
`

export function useSendExtendDeadlineEmail() {
  const [sendExtendDeadlineEmailGql, { loading, data, error }] = useMutation<
    { sendExtendDeadlineEmail: string },
    { prospectId: string }
  >(EXTEND_DEADLINE)

  function sendExtendDeadlineEmail(prospectId: string) {
    return sendExtendDeadlineEmailGql({
      variables: { prospectId },
    })
  }

  return { sendExtendDeadlineEmail, loading, data, error }
}
