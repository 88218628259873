import { DailyLogStatusEnum } from './payment.enums'

export interface DailyLog {
  id: string
  startTime: Date
  endTime: Date
  duration: number
  status: DailyLogStatusEnum
  description: string
  date: Date
  childId: string
  tutorPayment: number
}

export interface CreateDailyLogInput {
  childId: string
  startTime: Date
  endTime: Date
  description: string
  date: Date
}

export interface CreatePaymentDailyLogsInput {
  contractId: string
  dailyLogs?: CreateDailyLogInput[]
}

export interface UpdateDailyLogInputAdmin {
  id: string
  childId: string
  startTime?: Date
  endTime?: Date
  description?: string
  date?: Date
  delete?: true
  status?: DailyLogStatusEnum
}

export interface UpdatePaymentDailyLogsInputAdmin {
  paymentId: string
  dailyLogs?: UpdateDailyLogInputAdmin[]
}

export const DailyLogStringFull = `
  childId
  date
  description
  duration
  endTime
  id
  startTime
  status
  tutorPayment
`
