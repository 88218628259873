import { gql, useMutation } from '@apollo/client'
import { Contract } from '../../contracts/entities/contract.entity'
import { UpdateChildInput } from '../dto/update-child.input'
import { Child, UpdateChildInputAdmin } from '../../contracts/entities/child.entity'

const UPDATE_CHILD = gql`
  mutation UpdateChildrenAdmin(
    $updateContractChildInputAdmin: UpdateContractChildInputAdmin!
  ) {
    updateChildrenAdmin(updateContractChildInputAdmin: $updateContractChildInputAdmin) {
      id
    }
  }
`

export interface UpdateContractChildInputAdmin {
  contractId: string
  children?: UpdateChildInputAdmin[]
}

export function useUpdateChild() {
  const [updateChildGql, { loading, data, error }] = useMutation<
    { updateChildrenAdmin: Contract },
    { updateContractChildInputAdmin: UpdateContractChildInputAdmin }
  >(UPDATE_CHILD)

  function updateChild(contractId: string, updateChildInputAdmin: UpdateChildInputAdmin) {
    return updateChildGql({
      variables: {
        updateContractChildInputAdmin: {
          contractId,
          children: [updateChildInputAdmin],
        },
      },
    })
  }

  return { updateChild, loading, data, error }
}

export function updateChildInitialState(child: Child): UpdateChildInput {
  return {
    contractId: child.id,
    childId: child.id,
    studentName: child.studentName ? child.studentName : '',
    description: child.description ? child.description : '',
    hrsDay: child.hrsDay,
    gradeLevel: child.gradeLevel,
    tutorRate: child.tutorRate,
    subjects: child.subjects,
    learningDays: child.learningDays,
  }
}
