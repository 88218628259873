import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Card, Grid, IconButton, Rating, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { CircularIndeterminate } from '../components/preloader/preloader'
import { useFindReviews } from './hooks/use-find-reviews.hook'
import { useUpdateReviews } from './hooks/use-update-reviews.hook'
import { useDeleteReviews } from './hooks/use-delete-reviews.hook'
import { Formik } from 'formik'
import TextInputField from '../components/inputs/text-input-field'
import Dialog from '@mui/material/Dialog'
import EditIcon from '@mui/icons-material/Edit'
import { TutorRating } from './entities/tutor-rating.entity'
import DeleteIcon from '@mui/icons-material/Delete'

const RatingSection = ({ tutorId, refetch }: { tutorId: string; refetch(): void }) => {
  const {
    findReviews,
    loading: reviewsLoading,
    data: reviewsData,
    error: reviewsError,
  } = useFindReviews()
  const {
    updateReviews,
    loading: reviewsUpdateLoading,
    error: reviewsUpdateError,
  } = useUpdateReviews()
  const {
    deleteReviews,
    loading: reviewsDeleteLoading,
    error: reviewsDeleteError,
  } = useDeleteReviews()

  const [open, setOpen] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [selectedValue, setSelectedValue] = useState<TutorRating>({})

  const handleFetchReviews = () => {
    findReviews(tutorId)
  }

  useEffect(() => {
    handleFetchReviews()
  }, [])

  return (
    <Box>
      {(reviewsLoading || reviewsUpdateLoading || reviewsDeleteLoading) && (
        <CircularIndeterminate />
      )}
      {reviewsError && <Alert severity="error">{reviewsError.message}</Alert>}
      {reviewsUpdateError && <Alert severity="error">{reviewsUpdateError.message}</Alert>}
      {reviewsDeleteError && <Alert severity="error">{reviewsDeleteError.message}</Alert>}

      {reviewsData && (
        <>
          <Box mb={2}>
            <Typography variant="h5">Customer Reviews</Typography>
          </Box>

          {reviewsData.tutorRatings.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography fontWeight="bold" fontSize="medium">
                No Reviews Yet!
              </Typography>
            </Box>
          )}

          <Grid container spacing={2}>
            {reviewsData.tutorRatings.map(rating => (
              <Grid item xs={12} md={4} lg={3}>
                <Card key={rating.id}>
                  <Box p={2}>
                    <Box display="flex" justifyContent="space-between">
                      <NavLink
                        to={`/customer/${rating.customer?.id}`}
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <Typography fontWeight="bold" fontSize="medium">
                          {rating.customer?.fullName}
                        </Typography>
                      </NavLink>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setSelectedValue(rating)
                            setOpen(true)
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedValue(rating)
                            setOpenDeleteConfirmation(true)
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Rating value={rating.rating} max={5} precision={0.5} readOnly />
                      <Typography color="gray">({rating.rating})</Typography>
                    </Box>
                    <Box>
                      <Typography>{rating.review}</Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Dialog open={open} fullWidth>
        <Formik
          initialValues={{
            ...selectedValue,
          }}
          onSubmit={(values: any, { setSubmitting }) => {
            setSubmitting(false)
            updateReviews({
              tutorRatingId: selectedValue.id,
              tutorId: selectedValue.tutorId,
              customerId: selectedValue.customerId,
              review: values.review,
              rating: values.rating,
            }).then(() => {
              setOpen(false)
              handleFetchReviews()
            })
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Box px={2} py={1}>
                <Box py={1}>
                  <TextInputField
                    name="rating"
                    id="rating"
                    value={values.rating}
                    label="Rating"
                    type="number"
                    onChange={handleChange}
                  />
                </Box>

                <Box py={1}>
                  <TextInputField
                    name="review"
                    id="review"
                    value={values.review}
                    label="Review"
                    onChange={handleChange}
                  />
                </Box>
              </Box>

              <Box px={2} my={2} display="flex" justifyContent="space-between">
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Box px={2} />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog open={openDeleteConfirmation}>
        <Box p={2}>
          <Box>
            <Typography fontWeight="bold" fontSize={20}>
              Are you sure you want delete this review?
            </Typography>
          </Box>
          <Box my={2} display="flex" justifyContent="space-between">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setOpenDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Box px={2} />
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={() => {
                setOpenDeleteConfirmation(false)
                deleteReviews(selectedValue.id!).then(() => handleFetchReviews())
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default RatingSection
