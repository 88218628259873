import React from 'react'
import { Alert, Box, Button, Grid } from '@mui/material'
import { LearningDaysEnum } from '../../contracts/entities/contract.enums'
import TextInputField from '../../components/inputs/text-input-field'
import { SelectMenuItem } from '../../components/inputs/select-input-field'
import { Contract } from '../../contracts/entities/contract.entity'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { useCreateChild } from '../hooks/use-create-child.hook'
import SelectInputFieldCheckbox from '../../components/inputs/select-input-field-checkbox'
import { SubjectsArray } from '../../search_tutors/components/search-tutors-card'
import GradeLevelSingleInput from '../../components/inputs/grade-level-single.input'

export interface CreateChildDialogProps {
  contract: Contract
  openCreateChild: boolean

  setOpenCreateChild(val: boolean): void

  refetch(): void
}

const CreateChildDialog: React.FC<CreateChildDialogProps> = ({
  contract,
  openCreateChild,
  setOpenCreateChild,
  refetch,
}) => {
  const { createChild, loading, error } = useCreateChild()

  return (
    <Dialog open={openCreateChild} fullWidth>
      {loading && <CircularIndeterminate />}

      <Formik
        initialValues={{
          learningDays: [],
          gradeLevel: '',
          subjects: [],
          hrsDay: 1,
          tutorRate: 1,
        }}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          createChild({
            contractId: contract.id,
            children: [
              {
                ...values,
                studentName: `Child ${contract.children.length + 1}`,
                description: `${contract.fullName} wants you to tutor their ${
                  contract.children.length + 1 > 1 ? 'children' : 'child'
                }. They live around ${contract.location?.description}. ${values.description}`,
              },
            ],
          })
            .then(() => {
              refetch()
              setOpenCreateChild(false)
            })
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box py={1}>
                <GradeLevelSingleInput value={values.gradeLevel} onChange={handleChange} />
              </Box>

              <Box py={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <TextInputField
                      name="tutorRate"
                      id="rate"
                      value={values.tutorRate}
                      label="Rate"
                      type="number"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInputField
                      name="hrsDay"
                      id="hrsDay"
                      value={values.hrsDay}
                      label="Hours per day"
                      type="number"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box py={1}>
                <SelectInputFieldCheckbox
                  id="learning-days"
                  value={values.learningDays ? values.learningDays : []}
                  name="learningDays"
                  label="Learning Days"
                  onChange={e => {
                    handleChange(e)
                  }}
                  multiple
                  menuItems={
                    Object.entries(LearningDaysEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputFieldCheckbox
                  id="subjects"
                  value={values.subjects ? values.subjects : []}
                  label="Subjects"
                  name="subjects"
                  onChange={e => {
                    handleChange(e)
                  }}
                  multiple
                  menuItems={
                    SubjectsArray.map(value => ({
                      name: value.toLowerCase(),
                      value: value.toLowerCase(),
                    })) as SelectMenuItem[]
                  }
                />
              </Box>
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenCreateChild(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateChildDialog
