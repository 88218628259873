import { gql, useLazyQuery } from '@apollo/client'
import {
  FindPaymentsDateInputs,
  FindPaymentsInput,
  FindPaymentsNumberInputs,
} from '../dto/find-payments.input'
import { Payment, paymentStringFull } from '../../payments/entities/payment.entity'
import { Paginated, PaginationInput } from '../../../common/pagination/pagination'
import { CustomerTypeEnum } from '../../customers/entity/customer.enums'
import { PaymentStatusEnum } from '../../payments/entities/payment.enums'
import { SortPaymentInput } from '../dto/sort-payment.input'

export function useFindPayments() {
  const [findPaymentsGql, { loading, data, error }] = useLazyQuery<
    { payments: Paginated<Payment> },
    {
      findPaymentsInput: FindPaymentsInput
      paginationInput: PaginationInput
      sortPaymentInput: SortPaymentInput
    }
  >(FIND_PAYMENTS)

  function findPayments({
    currentSearchState,
    currentSortState,
    paginationInput,
    dateStates,
    numberStates,
  }: {
    currentSearchState: any
    currentSortState: SortPaymentInput
    paginationInput: PaginationInput
    dateStates?: FindPaymentsDateInputs
    numberStates?: FindPaymentsNumberInputs
  }) {
    return findPaymentsGql({
      variables: {
        paginationInput,
        sortPaymentInput: { ...currentSortState },
        findPaymentsInput: paymentSearchInput({
          currentSearchState,
          dateStates,
          numberStates,
        }),
      },
    })
  }

  return { findPayments, loading, data, error }
}

export const paymentSearchInput = ({
  currentSearchState,
  dateStates,
  numberStates,
}: {
  currentSearchState: any
  dateStates?: FindPaymentsDateInputs
  numberStates?: FindPaymentsNumberInputs
}) => ({
  ...currentSearchState,
  ...dateStates,
  ...numberStates,
})

export function useFindPaymentsCount() {
  const [findPaymentsCountGql, { loading, data, error }] = useLazyQuery<
    { payments: Paginated<Payment> },
    { findPaymentsInput: FindPaymentsInput; paginationInput: PaginationInput }
  >(FIND_PAYMENTS_COUNT, { fetchPolicy: 'cache-first' })

  function findCount() {
    return findPaymentsCountGql({
      variables: {
        findPaymentsInput: {
          customerType: CustomerTypeEnum.SINGLE_CUSTOMER,
        },
        paginationInput: {
          skip: 0,
          limit: 1000000,
        },
      },
    })
  }

  return { findCount, loading, data, error }
}

export const findPaymentsInitialState: FindPaymentsInput = {
  id: undefined,
  month: undefined,
  totalDepositedParent: undefined,
  totalDepositedTutor: undefined,
  totalHours: undefined,
  payableCommission: undefined,
  commission: undefined,
  netCommission: undefined,
  tot: undefined,
  incomeTax: undefined,
  discount: undefined,
  netTutorPayment: undefined,
  paymentLink: undefined,
  transactionId: undefined,
  receiptNumber: undefined,
  bankName: undefined,
  accountNumber: undefined,
  contractId: undefined,
  customerId: undefined,
  status: PaymentStatusEnum.PENDING,
  customerType: undefined,
  startDate: undefined,
  endDate: undefined,
  parentPaymentDate: undefined,
  requestDate: undefined,
  tutorPaidDate: undefined,
  verifiedDate: undefined,
  statusUpdatedAt: undefined,
  createdAt: undefined,
  updatedAt: undefined,
}

export const findInitPaymentSortState: SortPaymentInput = {
  fullName: undefined,
  totalDepositedParent: undefined,
  totalDepositedTutor: undefined,
  totalHours: undefined,
  payableCommission: undefined,
  commission: undefined,
  netCommission: undefined,
  tot: undefined,
  incomeTax: undefined,
  netTutorPayment: undefined,
  createdAt: -1,
}

const FIND_PAYMENTS = gql`
  query Payments(
    $findPaymentsInput: FindPaymentsInput,$sortPaymentInput: SortPaymentInput,$paginationInput: PaginationInput
    ) {
    payments(
      findPaymentsInput: $findPaymentsInput, 
      sortPaymentInput: $sortPaymentInput, 
      paginationInput: $paginationInput
      ) {
     items {
        ${paymentStringFull}
      }
      pageInfo {
        count
        limit
        skip
      }
    }
  }
  
`

const FIND_PAYMENTS_COUNT = gql`
  query Items($findPaymentsInput: FindPaymentsInput, $paginationInput: PaginationInput) {
    payments(findPaymentsInput: $findPaymentsInput, paginationInput: $paginationInput) {
      items {
        id
        status
      }
    }
  }
`
