import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Info } from '@mui/icons-material'
import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import { Note } from '../../notes/entity/notes.entity'

const NoteCard = ({ note }: { note: Note }) => {
  return (
    <Box mb={1} py={1} px={2} sx={{ backgroundColor: 'lightgray', borderRadius: 2 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography pt={1}>{note.description}</Typography>
        <Tooltip
          title={
            <Box>
              <Typography>
                Added on: {new Date(note.createdAt!)?.toISOString().split('T')[0]}
              </Typography>
              <br />
              <Typography>Added by: {note.createdBy?.fullName}</Typography>
            </Box>
          }
        >
          <IconButton>
            <Info />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography fontSize="small">
        (<ReactTimeAgo date={new Date(note.createdAt!)} locale="en-US" />)
      </Typography>
    </Box>
  )
}

export default NoteCard
