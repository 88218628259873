import { gql, useLazyQuery } from '@apollo/client'
import { TutorStat, TutorStatFilters } from '../dto/tutor-stat-filter'

export function useTutorStatData() {
  const [tutorStatDataGql, { loading, data, error }] = useLazyQuery<
    {
      getTutorAnalytics: TutorStat[]
    },
    { getTutorAnalyticsInput: any }
  >(TUTOR_STAT_DATA)

  function tutorStatData(statFilters: TutorStatFilters) {
    return tutorStatDataGql({
      variables: {
        getTutorAnalyticsInput: {
          statFilters,
        },
      },
    })
  }

  return { tutorStatData, loading, data, error }
}

const TUTOR_STAT_DATA = gql`
  query GetTutor($getTutorAnalyticsInput: GetTutorAnalyticsInput!) {
    getTutorAnalytics(getTutorAnalyticsInput: $getTutorAnalyticsInput)
  }
`
