import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { Child } from '../../contracts/entities/child.entity'
import { Contract } from '../../contracts/entities/contract.entity'
import UpdateChildDialog from './update-child-dialog'
import { Add } from '@mui/icons-material'
import AddDailyLogDialog from '../../payments/components/add-daily-log-dialog'
import BirrInfoCard from '../../components/cards/birr-info-card'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { useUpdateChild } from '../hooks/use-update-child.hook'

interface ChildCardProps {
  child: Child
  contract: Contract

  refetch(): void
}

const ChildCard: React.FC<ChildCardProps> = ({ child, contract, refetch }) => {
  const [openChildEdit, setOpenChildEdit] = useState(false)
  const [openDeleteChild, setOpenDeleteChild] = useState(false)
  const [openAddDailyLog, setOpenAddDailyLog] = useState(false)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const {
    updateChild,
    loading: deleteChildLoading,
    error: deleteChildError,
  } = useUpdateChild()

  return (
    <Box mb={2}>
      <UpdateChildDialog
        child={child}
        openChildEdit={openChildEdit}
        setOpenChildEdit={setOpenChildEdit}
        refetch={refetch}
        contract={contract}
      />

      <Dialog open={openDeleteChild} fullWidth>
        {deleteChildLoading ? (
          <CircularIndeterminate />
        ) : (
          <Card>
            <Box p={2}>
              <Typography>Are you sure you want delete child?</Typography>
              <Box display="flex" justifyContent="space-between">
                <Button
                  onClick={() => {
                    updateChild(contract.id, {
                      id: child.id,
                      delete: true,
                    }).then(() => {
                      setOpenDeleteChild(false)
                      refetch()
                    })
                  }}
                >
                  Yes
                </Button>
                <Button onClick={() => setOpenDeleteChild(false)}>No</Button>
              </Box>
            </Box>
          </Card>
        )}
      </Dialog>

      <AddDailyLogDialog
        contract={contract}
        child={child}
        refetch={refetch}
        setOpenAddDailyLog={setOpenAddDailyLog}
        openAddDailyLog={openAddDailyLog}
      />

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid item xs={12} md={6}>
            <Box display="flex">
              <Typography fontWeight="bold">Name</Typography>
              <Box mx={1}>:</Box>
              <Typography>{child.studentName}</Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold">Grade</Typography>
              <Box mx={1}>:</Box>
              <Typography>{child.gradeLevel}</Typography>
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex">
                <Typography fontWeight="bold">Hours per day</Typography>
                <Box mx={1}>:</Box>
                <Typography>{child.hrsDay}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Typography fontWeight="bold">Rate</Typography>
                <Box mx={1}>:</Box>
                <Typography>{child.tutorRate}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Box display="flex">
              <Typography fontWeight="bold">Subjects</Typography>
              <Box mx={1}>:</Box>
              <Typography>
                {child.subjects.map(sub => (
                  <span key={sub}>{sub}, </span>
                ))}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold">Description</Typography>
              <Box mx={0.5}>:</Box>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {child.description}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography fontWeight="bold">Learning Days</Typography>
              <Box mx={1}>:</Box>
              <Typography>
                {child.learningDays.map(days => (
                  <span key={days}>{days}, </span>
                ))}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <BirrInfoCard
                title="Total hours"
                amount={child.totalHours.toString()}
                label="Hrs"
              />
            </Grid>
            <Grid item xs={4}>
              <BirrInfoCard
                title="Unaprroved"
                amount={child.unApprovedHours.toString()}
                label="Hrs"
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="space-between">
            <Box px={2} py={1}>
              <Button
                size="small"
                variant="outlined"
                color="success"
                endIcon={<Add fontSize="small" />}
                onClick={() => setOpenAddDailyLog(true)}
              >
                Add Hours
              </Button>
            </Box>
            <Box px={2} py={1}>
              <Button
                size="small"
                variant="outlined"
                color="warning"
                endIcon={<EditIcon fontSize="small" />}
                onClick={() => setOpenChildEdit(true)}
              >
                Edit
              </Button>
            </Box>
            <Box px={2} py={1}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                endIcon={<DeleteIcon fontSize="small" />}
                onClick={() => setOpenDeleteChild(true)}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default ChildCard
