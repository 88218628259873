import { User } from '../../users/entities/user.entity'

export interface Note {
  id?: string
  _id?: string
  description?: string
  createdById?: string
  createdBy?: User
  createdAt?: Date
  updatedAt?: Date
  delete?: boolean
}

export const noteStringFull = `
  id
  description
  createdById
  updatedAt
  createdAt
  createdBy {
    id
    fullName
  }
`
