import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../context/auth_context'

const UnProtectedRoute: React.FC = () => {
  const auth = useAuth()

  if (auth?.authState) {
    return <Navigate to="/" />
  }
  return <Outlet />
}

export default UnProtectedRoute
