import { gql, useLazyQuery } from '@apollo/client'
import { Customer, customerStringFull } from '../entity/customer.entity'
import { FindCustomerInput } from '../dto/customer-find.input'
import { UserStatusEnum } from '../../users/entities/user.enums'
import { CustomerTypeEnum } from '../entity/customer.enums'

export function useFindCustomer() {
  const [findCustomerGql, { data, loading, error }] = useLazyQuery<
    { customer: Customer },
    { customerId: string }
  >(GET_CUSTOMER_DETAIL, { fetchPolicy: 'no-cache' })

  function findCustomer(customerId: string) {
    return findCustomerGql({
      variables: { customerId },
    })
  }

  return { findCustomer, loading, error, data }
}

export const findCustomersInitialState: FindCustomerInput = {
  status: UserStatusEnum.ACTIVE,
  fullName: undefined,
  phoneNumber: undefined,
  email: undefined,
  gender: undefined,
  customerType: CustomerTypeEnum.SINGLE_CUSTOMER,
  location: undefined,
}

const GET_CUSTOMER_DETAIL = gql`
  query Customer($customerId: String!) {
    customer(customerId: $customerId) {
      ${customerStringFull}
    }
  }
`
