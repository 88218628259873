import { gql, useLazyQuery } from '@apollo/client'
import { Paginated, PaginationInput } from '../../../common/pagination/pagination'
import { FindProspectsTutorsInput } from '../dto/find-prospects-tutors.input'
import { ProspectTutor, prospectTutorStringFull } from '../entities/prospect-tutor.entity'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import { UserLocationInput } from '../../../common/location/location.entity'
import { UserGenderEnum } from '../../users/entities/user.enums'
import { TutorApplicationStatusEnum } from '../entities/tutor_prospects.enum'
import { SortTutorProspectInput } from '../dto/sort-prospect.input'

export function useFindTutorProspects() {
  const [findTutorProspectsGql, { loading, data, error }] = useLazyQuery<
    { tutorProspects: Paginated<ProspectTutor> },
    {
      findTutorProspectInput: FindProspectsTutorsInput
      paginationInput: PaginationInput
      sortTutorProspectInput: SortTutorProspectInput
    }
  >(FIND_PROSPECT_TUTORS, { fetchPolicy: 'no-cache' })

  function findProspectTutors(
    {
      currentSearchState,
      currentSortState,
      paginationInput,
    }: {
      currentSearchState?: any
      currentSortState: SortTutorProspectInput
      paginationInput: PaginationInput
    },
    placeValue?: UserLocationInput | null,
    dateValue?: DateRange<Dayjs>,
    eueeResultValue?: [number | null, number | null],
  ) {
    return findTutorProspectsGql({
      variables: {
        sortTutorProspectInput: { ...currentSortState },
        findTutorProspectInput: ProspectTutorsSearchInput(
          currentSearchState,
          placeValue,
          dateValue,
          eueeResultValue,
        ),
        paginationInput,
      },
    })
  }

  return { findProspectTutors, loading, data, error }
}

export const ProspectTutorsSearchInput = (
  search: any,
  placeValue?: UserLocationInput | null,
  dateValue?: DateRange<Dayjs>,
  eueeResultValue?: [number | null, number | null],
) => ({
  ...search,
  fullName:
    search?.fullName && search?.fullName !== ''
      ? {
          regex: search?.fullName,
        }
      : undefined,
  // applicationStatus: search.status,
  status: undefined,
  phoneNumber: search?.phoneNumber && `+251${search.phoneNumber.slice(-9)}`,
  gender:
    search?.gender && search?.gender === UserGenderEnum.BOTH
      ? undefined
      : search?.gender
      ? search?.gender
      : undefined,
  email: search?.email ? search?.email : undefined,
  // location: placeValue ? placeValue.description.split(',')[0].trim() : undefined,

  eueeResult:
    Array.isArray(eueeResultValue) &&
    eueeResultValue.length === 2 &&
    eueeResultValue[0] !== null &&
    eueeResultValue[1] !== null
      ? {
          gte: eueeResultValue[0],
          lte: eueeResultValue[1],
        }
      : undefined,
  // bankName: search?.bankName ? search?.bankName : undefined,
  // accountNumber: search?.accountNumber ? search?.accountNumber : undefined,
  // receiptNumber: search?.receiptNumber ? search?.receiptNumber : undefined,
  // transactionId: search?.transactionId ? search?.transactionId : undefined,
  // paymentLink: search?.paymentLink ? search?.paymentLink : undefined,
  // verifiedDate: !_.isEqual(verifiedDateValue, [null, null])
  //   ? {
  //       gte:
  //         verifiedDateValue[0] != null
  //           ? new Date(verifiedDateValue[0]?.toDate())
  //           : new Date(+0),
  //       lte:
  //         verifiedDateValue[1] != null ? new Date(verifiedDateValue[1]?.toDate()) : new Date(),
  //     }
  //   : undefined,
  // createdAt: !_.isEqual(dateValue, [null, null])
  //   ? {
  //     gte: dateValue[0] != null ? new Date(dateValue[0]?.toDate()) : new Date(+0),
  //     lte: dateValue[1] != null ? new Date(dateValue[1]?.toDate()) : new Date(),
  //   }
  //   : undefined,
})

export function useFindProspectTutorCount() {
  const [findCountGql, { loading, data, error }] = useLazyQuery<
    { tutorProspects: Paginated<ProspectTutor> },
    { findTutorProspectInput: FindProspectsTutorsInput; paginationInput: PaginationInput }
  >(FIND_PROSPECT_TUTORS_COUNT, { fetchPolicy: 'cache-first' })

  function findCount() {
    return findCountGql({
      variables: {
        findTutorProspectInput: {},
        paginationInput: {
          skip: 0,
          limit: 1000000,
        },
      },
    })
  }

  return { findCount, loading, data, error }
}

export const findProspectTutorsInitialState: FindProspectsTutorsInput = {
  id: undefined,
  fullName: undefined,
  referralSource: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  applicationStatus: TutorApplicationStatusEnum.NOT_TAKEN,
  status: TutorApplicationStatusEnum.NOT_TAKEN,
  email: undefined,
  phoneNumber: undefined,
  gender: undefined,
  location: undefined,
  cgpa: undefined,
  // eueeResult: undefined,
  // university: undefined,
  // stream: undefined,
  // major: undefined,
  // graduationDate: undefined,
  // dateOfBirth: undefined
}
export const findInitProspectSortState: SortTutorProspectInput = {
  fullName: undefined,
  referralSource: undefined,
  cgpa: undefined,
  eueeResult: undefined,
  dateOfBirth: undefined,
  createdAt: -1,
}

const FIND_PROSPECT_TUTORS = gql`
  query Items(
    $paginationInput: PaginationInput
    $findTutorProspectInput: FindTutorProspectInput
  ) {
    tutorProspects(
      paginationInput: $paginationInput
      findTutorProspectInput: $findTutorProspectInput
    ) {
      items {
        ${prospectTutorStringFull}
      }
      pageInfo {
        count
        limit
        skip
      }
    }
  }
`

const FIND_PROSPECT_TUTORS_COUNT = gql`
  query Items(
    $paginationInput: PaginationInput
    $findTutorProspectInput: FindTutorProspectInput
  ) {
    tutorProspects(
      paginationInput: $paginationInput
      findTutorProspectInput: $findTutorProspectInput
    ) {
      items {
        id
        applicationStatus
      }
    }
  }
`
