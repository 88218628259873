import { LearningDaysEnum } from './contract.enums'

export interface Child {
  id: string
  studentName: string
  description: string
  tutorRate: number
  hrsDay: number
  totalHours: number
  unApprovedHours: number
  subjects: string[]
  gradeLevel: string
  learningDays: LearningDaysEnum[]
  createdAt: Date
  updatedAt: Date
}

export interface UpdateChildInputAdmin {
  id?: string
  studentName?: string
  description?: string
  tutorRate?: number
  hrsDay?: number
  subjects?: string[]
  gradeLevel?: string
  learningDays?: LearningDaysEnum[]
  delete?: boolean
}

export interface CreateChildInput {
  studentName: string
  description: string
  tutorRate: number
  hrsDay: number
  subjects: string[]
  gradeLevel: string
  learningDays: LearningDaysEnum[]
}

export interface CreateContractChildInputAdmin {
  contractId: string
  children?: CreateChildInput[]
}

export const childStringFull = `
  id
  description
  gradeLevel
  hrsDay
  learningDays
  unApprovedHours
  totalHours
  tutorRate
  studentName
  subjects
  createdAt
  updatedAt
`
