import React from 'react'
import { Alert, Box, Button } from '@mui/material'
import { GradeLevelList, LearningDaysEnum } from '../../contracts/entities/contract.enums'
import TextInputField from '../../components/inputs/text-input-field'
import SelectInputField, { SelectMenuItem } from '../../components/inputs/select-input-field'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import { CircularIndeterminate } from '../../components/preloader/preloader'
import { Child, UpdateChildInputAdmin } from '../../contracts/entities/child.entity'
import { updateChildInitialState, useUpdateChild } from '../hooks/use-update-child.hook'
import { SubjectsArray } from '../../search_tutors/components/search-tutors-card'
import { Contract } from '../../contracts/entities/contract.entity'
import SelectInputFieldCheckbox from '../../components/inputs/select-input-field-checkbox'

export interface UpdateChildDialogProps {
  child: Child
  contract: Contract
  openChildEdit: boolean

  setOpenChildEdit(val: boolean): void

  refetch(): void
}

const UpdateChildDialog: React.FC<UpdateChildDialogProps> = ({
  child,
  contract,
  openChildEdit,
  setOpenChildEdit,
  refetch,
}) => {
  const { updateChild, loading, data, error } = useUpdateChild()

  return (
    <Dialog open={openChildEdit} fullWidth>
      {loading && <CircularIndeterminate />}
      <Formik
        initialValues={updateChildInitialState(child)}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          updateChild(contract.id, {
            id: child.id,
            studentName: values.studentName,
            description: values.description,
            tutorRate: values.tutorRate,
            hrsDay: values.hrsDay,
            subjects: values.subjects,
            gradeLevel: values.gradeLevel,
            learningDays: values.learningDays,
          } as UpdateChildInputAdmin)
            .then(() => {
              refetch()
              setOpenChildEdit(false)
            })
            .catch(e => e)
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box px={2} py={1}>
              <Box py={1}>
                <TextInputField
                  id="studentName"
                  name="studentName"
                  label="Student Name"
                  value={values.studentName}
                  onChange={handleChange}
                  required={false}
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  id="description"
                  name="description"
                  label="description"
                  value={values.description}
                  onChange={handleChange}
                  required={false}
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  id="rate"
                  name="tutorRate"
                  label="rate"
                  value={values.tutorRate}
                  onChange={handleChange}
                  type="number"
                />
              </Box>

              <Box py={1}>
                <TextInputField
                  id="hrsDay"
                  name="hrsDay"
                  label="hrsDay"
                  value={values.hrsDay}
                  onChange={handleChange}
                  type="number"
                />
              </Box>

              <Box py={1}>
                <SelectInputFieldCheckbox
                  id="subjects"
                  value={values.subjects ? values.subjects : []}
                  label="Subjects"
                  name="subjects"
                  onChange={e => {
                    handleChange(e)
                  }}
                  multiple
                  menuItems={
                    SubjectsArray.map(value => ({
                      name: value.toLowerCase(),
                      value: value.toLowerCase(),
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputField
                  id="gradeLevel"
                  name="gradeLevel"
                  value={values.gradeLevel}
                  label="Grade Level"
                  onChange={handleChange}
                  menuItems={
                    GradeLevelList.map(value => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>

              <Box py={1}>
                <SelectInputFieldCheckbox
                  id="learning-days"
                  value={values.learningDays ? values.learningDays : []}
                  name="learningDays"
                  label="Learning Days"
                  onChange={e => {
                    handleChange(e)
                  }}
                  multiple
                  menuItems={
                    Object.entries(LearningDaysEnum).map(([_, value]) => ({
                      name: value.toLowerCase(),
                      value,
                    })) as SelectMenuItem[]
                  }
                />
              </Box>
            </Box>

            {error && <Alert severity="error">{error.message}</Alert>}

            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpenChildEdit(false)}
              >
                Cancel
              </Button>
              <Box px={2} />
              <Button
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default UpdateChildDialog
