import React from 'react'
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AccountCircle, Logout, Phone } from '@mui/icons-material'
import { useAuth } from '../../context/auth_context'

const drawerWidth = 240

const AppBarCustom = ({ handleDrawerToggle }: { handleDrawerToggle(): void }) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)

  const navigate = useNavigate()

  const auth = useAuth()

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle fontSize="small" />
        </IconButton>
        <Typography>{auth?.authState?.user?.fullName}</Typography>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Phone fontSize="small" />
        </IconButton>
        <Typography>{auth?.authState?.user?.phoneNumber}</Typography>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="small"
          aria-label="logout"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={() => {
            navigate('/logout', { replace: true })
          }}
        >
          <Logout fontSize="small" />
        </IconButton>
        <NavLink to="/logout" style={{ textDecoration: 'none', color: 'black' }}>
          Logout
        </NavLink>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        color="default"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          borderBottom: '2px solid goldenrod',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box mx={1} />
          <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            <Typography fontWeight="bold" fontSize={18}>
              Dashboard
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex' } }}>
            {/*<Box sx={{ display: { xs: 'none', md: 'flex' } }}>*/}

            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  )
}

export default AppBarCustom
