import { gql, useMutation } from '@apollo/client'
import { PaymentStatusEnum } from '../entities/payment.enums'
import { AttendancePaper } from '../entities/payment.entity'
import { Note } from '../../notes/entity/notes.entity'

const UPDATE_PAYMENT_STATUS = gql`
  mutation UpdatePaymentStatusAdmin(
    $updatePaymentStatusInputAdmin: UpdatePaymentStatusInputAdmin!
  ) {
    updatePaymentStatusAdmin(updatePaymentStatusInputAdmin: $updatePaymentStatusInputAdmin) {
      id
    }
  }
`

export interface UpdatePaymentStatusInputAdmin {
  paymentId: string
  verifiedDate?: Date
  cancellationReasons?: Note[]
  numberOfExtendedDate?: number
  status?: PaymentStatusEnum
  parentPaymentDate?: Date
  requestDate?: Date
  tutorPaidDate?: Date
  attendancePapers?: AttendancePaper[]
}

export function useUpdatePaymentStatus() {
  const [updatePaymentStatusGql, { loading, data, error }] = useMutation<
    { updatePaymentStatusAdmin: { id: string } },
    { updatePaymentStatusInputAdmin: UpdatePaymentStatusInputAdmin }
  >(UPDATE_PAYMENT_STATUS)

  function updatePaymentStatus(updatePaymentStatusInputAdmin: UpdatePaymentStatusInputAdmin) {
    return updatePaymentStatusGql({
      variables: { updatePaymentStatusInputAdmin },
    })
  }

  return { updatePaymentStatus, loading, data, error }
}
