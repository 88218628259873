import React from 'react'
import { Badge, Card, Tab, Tabs } from '@mui/material'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function CustomTabView<T>({
  value,
  tabCount,
  entries,
  handleChangeTab,
}: {
  value: number
  tabCount: any
  entries: any
  handleChangeTab(event: React.SyntheticEvent, newValue: number): void
}) {
  return (
    <Card sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {entries.map(([_, val]: any, index: any) => (
          <Tab
            key={index}
            label={
              <Badge
                badgeContent={tabCount[val]}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                color="error"
              >
                {val}
              </Badge>
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </Card>
  )
}

export default CustomTabView
